export enum SoundDefinitions {
    BACKGROUND = "audioGame.mp3",
    LABORATORIO = "laboratorio.mp3",
    PRESENTACION = "presentacion.mp3",
    TICTAC = "tictac.mp3",
    MINISTAR = "exito.mp3",
    ACIERTO = "acierto.mp3",
    ERROR = "error.mp3",
    CLICK = "click.mp3",
    REWARD = "Premio_fx.mp3",
    REWARD_LOCUCION = "APP_01_enhorabuena_OK_ESTANDAR_buena.mp3",
    REWARD_LOCUCION_VAL = "APP_01_enhorabuena_OK_ESTANDAR_buenaVAL.mp3",
    CHEERS = "cheers.mp3",
    AHA = "fx_voz_aha.mp3",
    FLIPCARD = "flipCard.mp3",
    BRILLO = "brillo.mp3",
    MAGIA = "fx_magia.mp3",
    BOING = "fx_boing.mp3",
    TACHAN = "tachan.mp3",
    ARPEGIO = "fx_arpegio.mp3",
    TIMER_OVER = "fx_timer_over.mp3",

    DO = "fx_do.mp3",
    RE = "fx_re.mp3",
    MI = "fx_mi.mp3",
    FA = "fx_fa.mp3",

    SPLASH = "fx_splash.mp3"
}