import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { MovableComponent, tMovingDirection } from '@/modules/base/components/movable/movable.component';
import { FishComponent } from '../fish/fish.component';
import { CustomPoint } from '@/modules/base/models/customPoint';
import { SplashComponent } from '../splash/splash.component';
import { SoundService } from '@/modules/base/services/sound/sound.service';
import { SoundDefinitions } from '@/modules/base/services/sound/SoundDefinitions';

const kTimeMovingDown = 0.1;
const kTimeMovingUp = 0.4;
const koffscreenTop = 690;
const kWidthScreen = 1366;

@Component({
  selector: 'app-pescador',
  templateUrl: './pescador.component.html',
  styleUrls: ['./pescador.component.css']
})
export class PescadorComponent extends MovableComponent implements OnInit {
  @Output() catchedFish = new EventEmitter<FishComponent>();

  private blocked: boolean = false;
  private splashController: SplashComponent;

  constructor(private soundService: SoundService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  CatchFish(fish: FishComponent) {
    if (this.blocked) return;

    this.blocked = true;

    //paramos el pez
    fish.Stop();

    //cogemos el pez
    let targetPosition = fish.GetCurrentPosition();
    let offsetX = 35;

    //Si la x de destino esta en la parte izquierda de la pantalla hemos de invertir el eje X de la cigueña
    // if(this.FaceDirectionForPosition(targetPosition) == tMovingDirection.LEFT)
    // {
    //   this.styleTransform = "scaleX(-1)";
    //   offsetX = 32;
    // }
    // else
    // {
    //   this.styleTransform = "scaleX(1)";
    // }

    //Ajusta del eje X de destino de la cigueña que no es exactamente el punto del pez porque miden diferente
    //targetPosition.SetX(targetPosition.GetX()-offsetX);
    targetPosition.SetX(targetPosition.GetX() + offsetX);

    //Posicionamos en la x sin transicion
    this.MoveToPoint(new CustomPoint(targetPosition.GetX(), this.currentPosition.GetY()), false, 0);

    //Bajamos en el eje Y con transicion
    setTimeout(() => {

      //sonido
      this.soundService.playSoundFromDefinition(SoundDefinitions.BOING);

      //aparece el arpon
      this.MoveToPoint(targetPosition, true, kTimeMovingDown);
    }, 50);

    //splash de agua
    setTimeout(() => {

      //splash de agua
      let leftSplashLeftPosition = targetPosition.GetLeft();
      let offsetXSplash = -30;
      // if(this.FaceDirectionForPosition(targetPosition) == tMovingDirection.RIGHT)
      // {
      //   offsetXSplash = 50;
      // }

      //audio
      this.soundService.playSoundFromDefinition(SoundDefinitions.SPLASH);

      this.splashController.ShowAtLeftPosition(targetPosition.GetLeft() + offsetXSplash);
    }, 100);


    //lo sacamos del agua
    setTimeout(() => {

      //cigueña
      let targetPosition = new CustomPoint(this.currentPosition.GetX(), koffscreenTop);

      //Sacamos la cigueña de la pantalla
      this.MoveToPoint(targetPosition, true, kTimeMovingUp);

      //pez
      targetPosition = new CustomPoint(fish.GetCurrentPosition().GetX(), koffscreenTop);
      fish.SetBottomPosition(targetPosition.GetBottom(), true, kTimeMovingUp);

      //informamos de que hemos capturado un pez
      this.catchedFish.emit(fish);

      //desbloqueamos
      setTimeout(() => {
        this.blocked = false;
      }, kTimeMovingUp * 1000);

    }, 500);
  }

  private FaceDirectionForPosition(position: CustomPoint): tMovingDirection {

    if (position.GetX() < (kWidthScreen / 2)) {
      return tMovingDirection.LEFT;
    }
    else {
      return tMovingDirection.RIGHT;
    }
  }

  public SetSplash(splash: SplashComponent) {
    this.splashController = splash;
  }

}
