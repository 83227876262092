import { Injectable } from '@angular/core';
import { TranslatorService } from '../translator/translator.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  constructor(
    private translatorService: TranslatorService
  ) { }

  public getBaseAssetsPath(): string {
    return "assets";
  }

  public getGameThumbnailPath(gameId: string): string {
    return `assets/games/${gameId}/thumbnail.png`;
  }

  public getGameTradingCardDownloadablePath(gameId: string): string {
    const currentLanguage: string = this.translatorService.currentLanguage;

    return `assets/success/trading-cards/downloadable/${currentLanguage}/${gameId}.pdf`;
  }

  public getGameTradingCardImagePath(gameId: string): string {
    return `assets/success/trading-cards/images/${gameId}.png`;
  }
}
