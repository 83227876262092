import { Injectable } from '@angular/core';

import { tLanguage } from './language';
import data from './i18n';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  public currentLanguage: tLanguage = tLanguage.CA;

  constructor() { }

  public get(key: string): string {
    const paths: string[] = key.split('.');
    let value: any = data[this.currentLanguage];

    for (let i = 0; i < paths.length; i++) {
      const path: string = paths[i];

      if (value.hasOwnProperty(path)) {
        value = value[path];
      } else {
        value = key;
        break;
      }
    }

    return value;
  }

  public getTitleThemeForCurrentLanguage(): string {
    return data[this.currentLanguage].titleTheme;
  }

  public getGameTitle(gameId: string): string {
    return data[this.currentLanguage].games[gameId].title;
  }

  public getGameSubtitle(gameId: string): string {
    return data[this.currentLanguage].games[gameId].subtitle;
  }
}
