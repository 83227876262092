
export enum tPointOrigins
{
    BOTTOM_LEFT = 0,
    BOTTOM_RIGHT = 1,
    TOP_LEFT = 2,
    TOP_RIGHT = 3
}

const kMaxHeight = 768;
const kMaxWidth = 1366;

export class CustomPoint
{
    private _x:number = 0;
    private _y: number = 0;
    private _origin:tPointOrigins = tPointOrigins.BOTTOM_LEFT;

    constructor(x:number, y:number, origin:tPointOrigins = tPointOrigins.BOTTOM_LEFT)
    {
        this._x = x;
        this._y = y;
        this._origin = origin;
    }

    SetX(x:number)
    {
        this._x = x;
    }

    SetY(y:number)
    {
        this._y = y;
    }

    GetX():number
    {
        return this._x;
    }

    GetY():number
    {
        return this._y;
    }

    GetOrigin():tPointOrigins
    {
        return this._origin;
    }

    GetTop(): number
    {
        if(this._origin == tPointOrigins.TOP_LEFT || this._origin == tPointOrigins.TOP_RIGHT)
        {
            return this._y;
        }
        else
        {
            return kMaxHeight - this._y;
        }
    }

    GetBottom()
    {
        if(this._origin == tPointOrigins.BOTTOM_LEFT || this._origin == tPointOrigins.BOTTOM_RIGHT)
        {
            return this._y;
        }
        else
        {
            return kMaxHeight - this._y;
        }
    }

    GetLeft(): number
    {
        if(this._origin == tPointOrigins.TOP_LEFT || this._origin == tPointOrigins.BOTTOM_LEFT)
        {
            return this._x;
        }
        else
        {
            return kMaxWidth - this._x;
        }
    }

    GetRight(): number
    {
        if(this._origin == tPointOrigins.TOP_RIGHT || this._origin == tPointOrigins.BOTTOM_RIGHT)
        {
            return this._x;
        }
        else
        {
            return kMaxWidth - this._x;
        }
    }

}