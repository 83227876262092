import { SoundService } from '@/modules/base/services/sound/sound.service';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomPoint } from '@/modules/base/models/customPoint';
import { MovableComponent, tMovingDirection } from '@/modules/base/components/movable/movable.component';
import { NumberService } from '@/modules/base/services/number/number.service';
import { GamesService } from '@/modules/base/services/games/games.service';

const kOffScreenRight = 1450;
const kOffScreenLeft = -200;
const kTimeMovingLimitsByLevel = [[3, 4], [4, 6], [4, 8]];

@Component({
  selector: 'app-fish',
  templateUrl: './fish.component.html',
  styleUrls: ['./fish.component.css']
})
export class FishComponent extends MovableComponent implements OnInit, AfterViewInit {

  @Input() direction: tMovingDirection = tMovingDirection.RIGHT;
  @Output() clicked = new EventEmitter<FishComponent>();

  private timerMoving: any = null;
  private speed: number;
  private catched: boolean = false;

  constructor(private soundService: SoundService, private numberService: NumberService, private gamesService: GamesService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.speed = this.numberService.getRandomInt(
      kTimeMovingLimitsByLevel[this.gamesService.currentLevel - 1][0],
      kTimeMovingLimitsByLevel[this.gamesService.currentLevel - 1][1]
    );
  }

  ngAfterViewInit() {

    setTimeout(() => {

      if (this.direction == tMovingDirection.LEFT) {
        //Si estamos mirando hacia la derecha hay que rotarlo
        this.styleTransform = "scaleX(-1)";
      }

      this.StartMoving();
    }, 50);
  }

  public StartMoving() {
    this.timerMoving = setInterval(() => this.Update(), 25);
    //console.log("Speed: " + this.speed);
  }

  private Update() {

    //console.log("Tic...");

    //desplazamiento positivo o negativo en funcion de la direccion
    let offset = (this.direction == tMovingDirection.RIGHT) ? this.speed : this.speed * -1;

    //actualizamos position
    this.currentPosition = new CustomPoint(this.currentPosition.GetLeft() + offset, this.currentPosition.GetBottom());

    //actualizamos estilos left
    this.SetLeftPosition(this.currentPosition.GetLeft(), false)

    //Controlamos cambio de direccion
    if (this.currentPosition.GetLeft() >= kOffScreenRight) {
      this.styleTransform = "scaleX(-1)";
      this.direction = tMovingDirection.LEFT;
    }

    if (this.currentPosition.GetLeft() <= kOffScreenLeft) {
      this.styleTransform = "scaleX(1)";
      this.direction = tMovingDirection.RIGHT;
    }
  }

  public Stop() {
    clearInterval(this.timerMoving);
  }

  public SetAsCatched() {
    this.catched = true;
  }

  public IsCatched(): boolean {
    return this.catched;
  }

  public Clicked() {
    this.soundService.playSoundClick();

    //informamos de que hemos tocado el pez
    this.clicked.emit(this);
  }

}
