import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseModule } from './modules/base/base.module';
import { ShapesModule } from './modules/shapes/shapes.module';
import { FishermanModule } from './modules/fisherman/fisherman.module';
import { LabyrinthModule } from './modules/labyrinth/labyrinth.module';
import { HowManyModule } from './modules/how-many/how-many.module';
import { PuzzlesModule } from './modules/puzzles/puzzles.module';
import { HorizontalTravelModule } from './modules/horizontal-travel/horizontal-travel.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BaseModule,
    PuzzlesModule,
    ShapesModule, 
    FishermanModule,
    LabyrinthModule,
    HowManyModule,
    HorizontalTravelModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
