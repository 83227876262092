<div class="container">
    <div class="row">
        <img class="button" [src]="UpButton.GetImagePathForItem" (click)="ClickUpButton()">
    </div>
    <div class="row">
        <img class="button" [src]="LeftButton.GetImagePathForItem" (click)="ClickLeftButton()">
        <img class="button" [src]="DownButton.GetImagePathForItem" (click)="ClickDownButton()">
        <img class="button" [src]="RightButton.GetImagePathForItem" (click)="ClickRightButton()">
    </div>
    
</div>

