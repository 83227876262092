<div class="game-view"></div>

<!-- BACKGROUND  -->
<img style="position: absolute; left:-1px; top: 0px; pointer-events: none" src="./assets/games/04/fondo.png">

<!-- CURTAINS  -->
<div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
  <img src="./assets/presentation/barra_puerta.png">
</div>
<div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
  <img src="./assets/presentation/barra_puerta.png">
</div>

<!-- ARPON -->
<app-pescador #ciguenya [id]="'ciguenya'" [pathToAssets]="'./assets/games/04'" [imgName]="'arpon.png'" [timeMoving]="0"
  [initPoint]="ciguenyaInitPointForTemplate" [offsetX]="0" [offsetY]="0" (catchedFish)="OnCatchedFish($event)">
</app-pescador>

<!-- PECES -->
<app-fish #fishes *ngFor="let fish of arrayFishesForTemplate; index as i" [id]="fish.id"
  [pathToAssets]="fish.pathToAssets" [imgName]="fish.imgName" [timeMoving]="fish.timeMoving"
  [initPoint]="fish.initPoint" [offsetX]="fish.offsetX" [offsetY]="fish.offsetY" [direction]="fish.direction"
  (clicked)="OnFishClicked($event)">
</app-fish>

<!-- ROCAS -->
<div class="containerRocas">
  <img src="./assets/games/04/rocas.png">
</div>

<!-- PLANTAS -->
<div class="containerPlantas">
  <img src="./assets/games/04/plantas_delante.png">
</div>

<!-- AGUA -->
<div class="containerAgua">
  <img src="./assets/games/04/agua_delante.png">
</div>

<!-- SALPICON -->
<app-splash #splash [id]="'splash'" [pathToAssets]="'./assets/games/04'" [imgName]="'salpicon.png'">
</app-splash>

<!-- UPPER BAR -->
<app-top-bar #topBar (clickBackButton)="OnClickedBackButton()" (clickInfoButton)="OnClickedInfoButton()"
  (timerOver)="OnTimerOver()">
</app-top-bar>

<div class="fadeContainer" [ngStyle]="{'opacity': styleOpacityForFadeContainer}"></div>

<!-- SUCCESS SCREEN -->
<app-success-view [gameInfo]="gameInfo"></app-success-view>

<!-- INFO SCREEN -->
<app-info-screen #infoScreen (infoScreenClosed)="OnInfoScreenClosed()" (retryScreenClosed)="OnRetryScreenClosed()">
</app-info-screen>

<!-- TAPA TOP -->
<div class="tapaTop"></div>

<!-- TAPA BOTTOM -->
<div class="tapaBottom"></div>