import { ItemHTML } from './itemHTML';

export class Container extends ItemHTML {
    imgSustitucionName: string;
    solved: boolean;        //indica que ya se ha arrastrado el draggable correcto
    idDraggable: string;    //el id del draggable que va en este container

    constructor(_id: string, _pathToAssets: string, _imgName: string, _imgSustitucionName: string) {
        super(_id, _pathToAssets, _imgName);
        this.solved = false;
        this.imgSustitucionName = _imgSustitucionName;

        this.styleStandard = 'container_standard';
        this.styleFadeIn = 'container_fadeIn';
        this.styleFadeOut = 'container_fadeOut';
        this.styleScaleUp = 'container_scaleUp';
        this.styleScaleDown = 'container_scaleDown';
        this.itemStyle = this.styleStandard;
    }

    public ShowImageSustitucion() {
        this.imgShowingInHTML = this.imgSustitucionName;
    }

    public ShowImageOriginal() {
        this.imgShowingInHTML = this.imgName;
    }
}