<div style="height: 768px; width: 1366px;  background-image: url('./assets/ui/games-background.png')">

  <!-- CURTAINS -->
  <div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>
  <div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>

  <img class="botonPulsado noSelectable" src="./assets/ui/btn_atras_exito.png"
    style="position:absolute; top: 7px;left:180px; cursor: pointer" (click)="goBack()" />
  <img class="botonPulsado noSelectable" src="./assets/ui/btn_ampliar.png"
    style="position:absolute; top: 7px;left:260px; cursor: pointer" (click)="checkScreen()" id="buttonScreen" />
  <img class="botonPulsado noSelectable" src="./assets/ui/btn_borrarlogros.png"
    style="position:absolute; top: 7px;left: 1110px; cursor: pointer" (click)="onResetGameDataButtonClicked()" />
  <p class="borrarLogros">{{GetBorrarLogrosTranslation()}}</p>

  <p class="titulo">{{GetTitle()}}</p>

  <!-- BOTONES JUEGOS -->
  <div class="containerAllThumbs">
    <div class="containerGame" *ngFor="let gameInfo of gamesService.arrayGameInfo; index as i">
      <app-juegos-button #gameButtons [gameInfo]="gameInfo" (clickedButton)="ClickGameButton($event)">
      </app-juegos-button>
    </div>
  </div>

  <!-- ALBUM  PARA DESCARGAR -->
  <div style="left: 869px;top: 265px;position: fixed; cursor: pointer;" (click)="downloadPdf()"
    class="botonPulsado noSelectable">
    <img src="./assets/ui/btn_album.png" />
    <img src="./assets/ui/album_thumbnail.png" style="left: 19px;top: 32px;position: absolute;" />
    <p class="imprimibleJuego">{{GetAlbumTranslation()}}</p>
  </div>

</div>