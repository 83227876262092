import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { tLanguage } from '@/modules/base/services/translator/language';
import { SoundService } from '@/modules/base/services/sound/sound.service';
import { SoundDefinitions } from "@/modules/base/services/sound/SoundDefinitions";
import { TranslatorService } from '@/modules/base/services/translator/translator.service';
import { AssetsService } from '@/modules/base/services/assets/assets.service';
import GameInfo from '@/modules/base/services/games/GameInfo';

@Component({
  selector: 'app-success-view',
  templateUrl: './success-view.component.html',
  styleUrls: ['./success-view.component.css']
})
export class SuccessViewComponent implements OnInit {
  @Input() gameInfo: GameInfo;

  stackImgTimer: any = [];

  constructor(
    private router: Router,
    private soundService: SoundService,
    private translatorService: TranslatorService,
    private assetsService: AssetsService
  ) { }

  ngOnInit() {
    const appendingTo = document.getElementById("preloadFrameExito");

    for (let index = 1; index < 94; index++) {
      var elem = document.createElement('img');
      elem.setAttribute('src', 'assets/success/exito/Capa-' + index + '.png');
      elem.style.position = 'absolute';
      elem.style.top = '413px';
      elem.style.left = '162px';
      elem.classList.add("listDoctoraPresentation");
      appendingTo.appendChild(elem);
    }
  }

  ngOnDestroy(): void {
    if (this.translatorService.currentLanguage == tLanguage.ES) {

      this.soundService.stopSoundFromDefinition(SoundDefinitions.REWARD_LOCUCION);
    }
    else {
      this.soundService.stopSoundFromDefinition(SoundDefinitions.REWARD_LOCUCION_VAL);
    }
    this.stackImgTimer.forEach(element => {
      clearTimeout(element);
    });
  }

  show(nameSticker: string) {
    (document.getElementById('sticker') as HTMLImageElement).src = nameSticker;
    document.getElementById('frameExito').hidden = false;
    this.soundService.playSoundFromDefinition(SoundDefinitions.REWARD);
    (document.getElementById("estrellas_exito") as HTMLImageElement).setAttribute('src', "assets/success/estrella_brillo.gif?" + new Date().getTime()); // añadimos fecha para que vuelva a cargar el gif en el DOM
    (document.getElementById('doctoraWellsPremio') as HTMLImageElement).src = 'assets/success/exito/Capa-1.png';
    this.stackImgTimer = [];
    for (let index = 2; index < 94; index++) {
      this.doSetTimeout(index);
    }
    if (this.translatorService.currentLanguage == tLanguage.ES) {
      this.soundService.playSoundFromDefinition(SoundDefinitions.REWARD_LOCUCION, false, 1);
    }
    else {
      this.soundService.playSoundFromDefinition(SoundDefinitions.REWARD_LOCUCION_VAL, false, 1);
    }
    setTimeout(() => {
      this.soundService.playSoundFromDefinition(SoundDefinitions.CHEERS);
    }, 1500);
  }

  doSetTimeout(index: number) {
    this.stackImgTimer.push('stackOfImages_' + index);
    this.stackImgTimer[index - 2] = setTimeout(function () {
      (document.getElementById('doctoraWellsPremio') as HTMLImageElement).src = 'assets/success/exito/Capa-' + index + '.png';
    }, 70 * index);
  }

  goBack() {
    this.soundService.playSoundClick();
    if (this.translatorService.currentLanguage == tLanguage.ES) {
      this.soundService.stopSoundFromDefinition(SoundDefinitions.REWARD_LOCUCION);
    }
    else {
      this.soundService.stopSoundFromDefinition(SoundDefinitions.REWARD_LOCUCION);
    }
    document.getElementById("doorLeft").style.left = "0px";
    document.getElementById("doorRight").style.right = "0px";

    setTimeout(() => {
      this.router.navigate(['/games']);
    }, 500);
  }

  replay() {
    this.soundService.playSoundClick();
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/' + this.gameInfo.route]);
    }, 500);
  }

  downloadSticker() {
    this.soundService.playSoundClick();

    const downloadablePath: string = this.assetsService.getGameTradingCardDownloadablePath(this.gameInfo.id);
    const win = window.open(downloadablePath, '_blank');

    win.focus();
  }

  public GetDescargarTranslation(): string {
    return this.translatorService.get("download").toUpperCase();
  }
}
