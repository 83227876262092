
export class Sound
{
    private soundFileName:string;
    private loop:boolean;
    private volume:number;

    constructor(_soundFileName:string, _loop:boolean = false, _volume = 1)
    {
        this.soundFileName = _soundFileName;
        this.loop = _loop;
        this.volume = _volume;
    }

    public getSoundFileName()
    {
        return this.soundFileName;
    }

    public hasLoop():boolean
    {
        return this.loop;
    }

    public getSoundVolume()
    {
        return this.volume;
    }
}