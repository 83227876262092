import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HorizontalTravelViewComponent } from './views/horizontal-travel-view/horizontal-travel-view.component';
import { BaseModule } from '../base/base.module';
import { HorizontalTravelScenaryService } from './services/horizontal-travel-scenary.service';
import { HorizontalTravelCameraService } from './services/horizontal-travel-camera.service';
import { HorizontalTravelGoalService } from './services/horizontal-travel-goal.service';

@NgModule({
  declarations: [HorizontalTravelViewComponent],
  imports: [
    CommonModule, 
    BaseModule
  ],
  providers: [
    HorizontalTravelScenaryService,
    HorizontalTravelCameraService,
    HorizontalTravelGoalService
  ],
  exports: [HorizontalTravelViewComponent]
})
export class HorizontalTravelModule { }
