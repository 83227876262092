<div class="game-view">

  <!-- CURTAINS  -->
  <div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>
  <div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>

  <!-- BACKGROUND  -->
  <img style="position: absolute; top: 0px; pointer-events: none" src="./assets/games/05/fondo.png">

  <!-- BUTTONS  -->
  <div *ngIf="showButtons" class="frameObjects" id="frameObjects">
    <span class="numberButtons" (click)="clickButton(1)" id="but1">1</span>
    <span class="numberButtons" (click)="clickButton(2)" id="but2">2</span>
    <span class="numberButtons" (click)="clickButton(3)" id="but3">3</span>
    <span class="numberButtons" (click)="clickButton(4)" id="but4">4</span>
    <span *ngIf="visibilityButtonsSecondLevel" class="numberButtons" (click)="clickButton(5)" id="but5">5</span>
    <span *ngIf="visibilityButtonsSecondLevel" class="numberButtons" (click)="clickButton(6)" id="but6">6</span>
    <span *ngIf="visibilityButtonsThirdLevel" class="numberButtons" (click)="clickButton(7)" id="but7">7</span>
    <span *ngIf="visibilityButtonsThirdLevel" class="numberButtons" (click)="clickButton(8)" id="but8">8</span>
    <span *ngIf="visibilityButtonsThirdLevel" class="numberButtons" (click)="clickButton(9)" id="but9">9</span>
    <span *ngIf="visibilityButtonsThirdLevel" class="numberButtons" (click)="clickButton(10)" id="but10">10</span>
  </div>

  <!-- ENEMIES  -->
  <div class="enemies-container" id="enemies-container">
    <div style="top: 0px; margin-left: 100px" class="enemies-row">
      <div *ngFor="let item of rowOne"><img *ngIf="item.show" class="enemy-item" src="./assets/games/05/tigre_correr.gif">
      </div>
    </div>
    <div style="top: 60px" class="enemies-row">
      <div *ngFor="let item of rowTwo"><img *ngIf="item.show" class="enemy-item" src="./assets/games/05/tigre_correr.gif">
      </div>
    </div>
    <div style="top: 120px; margin-left: 100px" class="enemies-row">
      <div *ngFor="let item of rowThree"><img *ngIf="item.show" class="enemy-item"
          src="./assets/games/05/tigre_correr.gif"></div>
    </div>
  </div>

  <!-- DOCTORA -->
  <div class="doctoraContainer" [ngStyle]="{'right':doctoraRightStyleCurrentValue,
        'top':doctoraTopStyleCurrentValue,
        'transform':doctoraTransformCurrentValue,
        'transition':'right 2s linear'}">
    <img class="doctora" [src]="doctoraImagePath">
  </div>

  <!-- UPPER BAR -->
  <app-top-bar #topBar (clickBackButton)="OnClickedBackButton()" (clickInfoButton)="OnClickedInfoButton()"
    (timerOver)="OnTimerOver()">
  </app-top-bar>

</div>

<!-- SUCCESS SCREEN -->
<app-success-view [gameInfo]="gameInfo"></app-success-view>

<!-- INFO SCREEN -->
<app-info-screen #infoScreen (infoScreenClosed)="OnInfoScreenClosed()" (retryScreenClosed)="OnRetryScreenClosed()">
</app-info-screen>