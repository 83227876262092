import { style } from '@angular/animations';

export class ItemHTML {
  id: string;
  pathToAssets: string;
  imgShowingInHTML: string;   //Esta variable tiene el nombre la imagen que hay que mostrar en todo momento
  imgName: string;            //Nombre de la imagen original del item
  itemStyle: string;
  opacity: number = 1;
  scale: number[] = [1, 1];
  canShow: boolean;

  styleStandard: string = '';
  styleFadeIn: string = '';
  styleFadeOut: string = '';
  styleScaleUp: string = '';
  styleScaleDown: string = '';
  styleHide: string = 'hide';
  styleShow: string = 'show';
  styleHideWithScale: string = 'scale_down';
  styleShowWithScale: string = 'scale_up';
  transform: string = "";
  transition: string = "";

  constructor(_id: string, _pathToAssets: string, _imgName: string) {
    this.id = _id;
    this.pathToAssets = _pathToAssets;
    this.imgName = _imgName;
    this.imgShowingInHTML = _imgName;
  }

  public ShowItemWithOpacity() {
    this.itemStyle = this.styleStandard + ' ' + this.styleShow;
  }

  public HideItemWithOpacity() {
    this.itemStyle = this.styleStandard + ' ' + this.styleHide;
  }

  public ShowItemWithScale() {
    this.itemStyle = this.styleStandard + ' ' + this.styleShowWithScale;
  }

  public HideItemWithScale() {
    this.itemStyle = this.styleStandard + ' ' + this.styleHideWithScale;
  }

  public FadeIn() {
    this.itemStyle = this.styleStandard + ' ' + this.styleFadeIn;
  }

  public FadeOut() {
    this.itemStyle = this.styleStandard + ' ' + this.styleFadeOut;
  }

  protected ScaleUp() {
    this.itemStyle = this.styleStandard + ' ' + this.styleScaleUp;
  }

  protected ScaleDown() {
    this.itemStyle = this.styleStandard + ' ' + this.styleScaleDown;
  }

  public SetTransform(transform: string) {
    this.transform = transform;
  }

  public SetTransition(transition: string) {
    this.transition = transition;
  }

  public SetInitStyle() {
    this.itemStyle = this.styleStandard;
  }
  public GetTransform() {
    return this.transform;
  }

  public GetTransition() {
    return this.transition;
  }

  public get GetImagePathForItem(): string {
    return this.pathToAssets + this.imgShowingInHTML;
  }

  public get GetStyle(): string {
    return this.itemStyle;
  }

  public get GetOpacity(): number {
    return this.opacity;
  }
}