<div style="height: 768px; width: 1366px; position: absolute;  background-image: url('./assets/presentation/fondo2.png')"
  id="framePresentation">

  <!-- CURTAINS -->
  <div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>
  <div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>

  <img class="btnDoctora noSelectable" id="doctoraW" src="./assets/presentation/btn_entrar.png" (click)="startApp()" />

  <img class="botonPulsado noSelectable" src="./assets/ui/ver.png"
    style="position:absolute; top: 7px;left:180px; cursor: pointer" (click)="showPresentation()" />
  <p class="borrarLogros noSelectable" style="left: 250px" (click)="showPresentation()">{{GetVerTranslation()}}</p>

  <img src="./assets/presentation/tv-illustration.png" style="width: 453px; left: 285px;top: 194px;position: fixed;" />
  <img src="./assets/presentation/PANTALLA_MONITOR.gif" style="left: 285px;top: 194px;position: fixed; opacity: 0;"
    id="monitorPresentacion" />
  <img src="./assets/presentation/serpentin.gif" style="left: 208px;top: 193px;position: fixed; width: 6.3%" />

  <img src="./assets/presentation/rueda_maquina_1.png" style="left: 346px;top: 549px;position: fixed;" class="ruedas" />
  <img src="./assets/presentation/rueda_maquina_2.png" style="left: 472px;top: 576px;position: fixed;" class="ruedas2" />

  <img src="./assets/presentation/rueda_fondo_1.png" style="left: 1149px;top: 154px;position: fixed;" class="ruedas" />
  <img src="./assets/presentation/rueda_fondo_2.png" style="left: 1042px;top: 233px;position: fixed;" class="ruedas2" />
  <img src="./assets/presentation/rueda_fondo_3.png" style="left: 1072px;top: 386px;position: fixed;" class="ruedas" />

  <img src="./assets/presentation/base_play.png" style="left: 627px;top: 422px;position: fixed;" />
  <img class="botonPulsado noSelectable" src="./assets/presentation/boton_play.png"
    style="left: 646px;top: 441px;position: absolute; cursor: pointer" (click)="goNextLevel()" />

  <div class="tituloProy">{{GetTitle()}}</div>
  <img id="doctoraWells" src="./assets/presentation/inicio_doctora/init_doc_1.png"
    style="position: absolute; top: 138px; left: 828px">
  <div id="preloadFrame" hidden></div>

  <div id="videoPresentacion" class="videoContainer"
    [style.visibility]="(canShowVideoForTemplate) ? 'visible' : 'hidden'" 
    [style.opacity]="opacityForVideo" >
    <img class="botonPulsado noSelectable" src="./assets/ui/saltar.png"
      style="position:absolute; top: 7px; left: 1110px; cursor: pointer; z-index: 91" (click)="closePres()" />
    <p class="borrarLogros noSelectable" style="z-index: 91; left: 1040px" (click)="closePres()">
      {{GetSaltarTranslation()}}</p>
    <video class="videoPres" id="videoPres" pause auto="false" style="background-color: white">

      <!-- VIDEO PRESENTACIÓN IDIOMA EN CASTELLANO -->
      <source [src]="getPathToVideo()" type="video/mp4">

      Your browser does not support HTML5 video.
    </video>
  </div>

</div>