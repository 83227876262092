import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ItemHTML } from '../../models/itemHTML';
import { SoundService } from '@/modules/base/services/sound/sound.service';
import { SoundDefinitions } from '../../services/sound/SoundDefinitions';

const kWidthTimerBar: number = 230;
const kNumStars = 3;
const kStyleClassStarDeactivated = "estrellaDesactivada";
const kStyleClassStarActivaded = "estrellaActivada";

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {
  @Output() clickBackButton = new EventEmitter();
  @Output() clickInfoButton = new EventEmitter();
  @Output() timerOver = new EventEmitter();

  interval: any;
  currentWidthForTimerBar: number = 0;
  timerEnabled = false;
  timerPaused = false;

  public arrayStarsPlaceholders: ItemHTML[] = [];
  public arrayStarsActivated: ItemHTML[] = [];
  private secondsRemainingInTimer: number = 0;
  private offsetForTimerBarDecrementing: number = 0;

  constructor(private soundService: SoundService) {
    this.CreateStars();
  }

  ngOnInit() { }

  private CreateStars() {
    for (let index = 0; index < kNumStars; index++) {
      this.arrayStarsPlaceholders.push(new ItemHTML("starPlaceholder" + index, "assets/ui/", "estrella_gris.png"));

      let starActivated: ItemHTML = new ItemHTML("starPlaceholder" + index, "assets/ui/", "estrella.png");
      starActivated.itemStyle = kStyleClassStarDeactivated;
      this.arrayStarsActivated.push(starActivated);
    }
  }

  AcivateStar(index: number) {
    if (index <= this.arrayStarsActivated.length && index > 0) {
      this.soundService.playSoundFromDefinition(SoundDefinitions.MINISTAR);
      this.arrayStarsActivated[index - 1].itemStyle = kStyleClassStarActivaded;
    }
  }

  StartTopBarTimer(seconds: number) {
    this.soundService.playSoundFromDefinition(SoundDefinitions.TICTAC, true, 0.5);

    //Sino lo esta ya, mostramos el timer en el template
    if (!this.timerEnabled) {
      this.timerEnabled = true;
    }


    //Calculamos cuanto tenemos que ir decrementando la barra del timer
    //Comprobamos antes si ya esta calculada para no machacarla en caso de que estemos poniendo en marcha
    //el timer despues de una pausa
    if (!this.timerPaused) {
      this.offsetForTimerBarDecrementing = kWidthTimerBar / seconds;
    }

    //contador para el numero de segundos
    let counter: number = seconds;

    //inicializamos la variable con los segundos restantes
    //la usaremos si pausamos el timer para volver a resumirlo desde donde estabamos
    this.secondsRemainingInTimer = seconds;

    //Ponemos en marcha el intervalo cada 1 segundo
    this.interval = setInterval(() => {

      //Disminuimos contador de segundos y de segundos restantes
      counter--;
      this.secondsRemainingInTimer--;

      //vamos actualizando el tamaño de la barra del timer
      //esta variable se lee desde el template para darle tamaño a la barra
      this.currentWidthForTimerBar += this.offsetForTimerBarDecrementing;

      if (counter < 0) { //Tiempo agotado

        //limpiamos intervalo
        clearInterval(this.interval);

        //Disparamos evento
        this.timerOver.emit();

        //Paramos sonido timer
        //Utils.StopSound(SoundDefinitions.Sound.TICTAC);
        this.soundService.stopSoundFromDefinition(SoundDefinitions.TICTAC);

        //this.vuelve = true;
      };

    }, 1000);
  }

  PauseTopBarTimer() {
    if (this.interval != null) {

      this.timerPaused = true;

      //Paramos intervalo
      clearInterval(this.interval);

      //Paramos sonido
      //Utils.StopSound(SoundDefinitions.Sound.TICTAC); 
      this.soundService.stopSoundFromDefinition(SoundDefinitions.TICTAC);

    }
  }

  ResumeTopBarTimer() {
    if ((this.secondsRemainingInTimer) >= 0) {
      //Reanudamos el timer
      this.StartTopBarTimer(this.secondsRemainingInTimer);
      this.timerPaused = false;
    }
  }

  StopTopBarTimer() {
    if (this.timerEnabled) {
      clearInterval(this.interval);

      //paramos sonido
      //Utils.StopSound(SoundDefinitions.Sound.TICTAC);
      this.soundService.stopSoundFromDefinition(SoundDefinitions.TICTAC);
    }
  }

  ClickBack() {
    this.soundService.playSoundClick();
    this.clickBackButton.emit();
  }

  ClickInfo() {
    this.soundService.playSoundClick();
    this.clickInfoButton.emit();
  }
}
