import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomPoint } from '../../models/customPoint';
import { ItemHTMLComponent } from '../item-html/item-html.component'

export enum tMovingDirection {
  UP = 0,
  DOWN = 1,
  LEFT = 2,
  RIGHT = 3
}

@Component({
  selector: 'app-movable',
  templateUrl: './movable.component.html',
  styleUrls: ['./movable.component.css']
})
export class MovableComponent extends ItemHTMLComponent implements OnInit {
  @Input() timeMoving: number;
  @Input() initPoint: CustomPoint;
  @Input() offsetX: number;
  @Input() offsetY: number;
  @Output() moved = new EventEmitter<MovableComponent>();

  protected currentPosition: CustomPoint;
  protected movingDirection: tMovingDirection = tMovingDirection.RIGHT;
  protected facingDirection: tMovingDirection = tMovingDirection.RIGHT;

  constructor() {
    super();
  }

  ngOnInit() {
    this.currentPosition = new CustomPoint(this.initPoint.GetX(), this.initPoint.GetY());
    this.UpdatePosition();
  }

  //Situa el item en la posicion definida por su currentPoint
  protected UpdatePosition() {
    this.styleBottom = this.currentPosition.GetBottom();
    this.styleLeft = this.currentPosition.GetLeft();
  }

  public MoveToDirection(direction: tMovingDirection, timeMoving: number) {
    switch (direction) {
      case tMovingDirection.UP:
        this.currentPosition.SetY(this.currentPosition.GetY() + this.offsetY);
        break;
      case tMovingDirection.DOWN:
        this.currentPosition.SetY(this.currentPosition.GetY() - this.offsetY);
        break;
      case tMovingDirection.RIGHT:
        this.currentPosition.SetX(this.currentPosition.GetX() + this.offsetX);
        break;
      case tMovingDirection.LEFT:
        this.currentPosition.SetX(this.currentPosition.GetX() - this.offsetX);
        break;
      default:
        break;
    }

    this.MoveToCurrentPosition(timeMoving);
  }

  public MoveToPoint(destination: CustomPoint, withTransition: boolean, timeMoving: number) {

    this.currentPosition = destination;

    this.MoveToCurrentPosition(timeMoving);
  }

  private MoveToCurrentPosition(timeMoving: number) {
    //Actualizamos propiedades bottom y left del componente
    this.SetBottomLeftPosition(this.currentPosition.GetBottom(), this.currentPosition.GetLeft(), true, timeMoving)

    //Informamos de que hemos movido y ponemos imgen normal
    setTimeout(() => {

      //evento de que hemos movido
      this.AfterMoved();
    }, this.timeMoving * 1000);
  }

  public GetCurrentPosition(): CustomPoint {
    return this.currentPosition;
  }

  public GetInitPosition(): CustomPoint {
    return this.initPoint;
  }

  protected AfterMoved() {
    this.moved.emit(this);
  }

  public GetFacingDirection(): tMovingDirection {
    return this.facingDirection;
  }

  public SetFacingDirection(direction: tMovingDirection) {
    this.facingDirection = direction;
  }

  public GetMovingDirection(): tMovingDirection {
    return this.movingDirection;
  }

  public SetMovingDirection(direction: tMovingDirection) {
    this.movingDirection = direction;
  }
}