import es from './es.json';
import ca from './ca.json';
import gl from './gl.json';
import eu from './eu.json';
import en from './en.json';

export default {
    es,
    ca,
    gl,
    eu,
    en
}