import { ItemHTML } from './itemHTML';

export class Draggable extends ItemHTML {
    idContainer: string;    //El id del container al que hay que arrastrar este draggable;
    disabled: boolean;      //Indica si el arrastable debe estar desactivado
    canMoveToContainer: boolean = true;     //Indica si podemos mover este arrastable a su container y darlo por correcto
    waitBeforeSetInitialStyle: number = 250;

    constructor(_id: string, _pathToAssets: string, _imgName: string) {
        super(_id, _pathToAssets, _imgName);
        this.disabled = false;

        this.styleStandard = 'draggable_standard';
        this.styleFadeIn = 'draggable_fadeIn';
        this.styleFadeOut = 'draggable_fadeOut';
        this.styleScaleUp = 'draggable_scaleUp';
        this.styleScaleDown = 'draggable_scaleDown';
        this.itemStyle = this.styleStandard;
    }

    /** Nos dice si el container al que hemos arrastrado es el correcto */
    public draggedToRightContainer(idContainerWhereDragged: string): boolean {
        if (!this.canMoveToContainer) {

            //console.log('No podemos arrastrar este draggable a nigun contenedor');
            return false;
        }

        return (idContainerWhereDragged === this.idContainer) ? true : false;

    }

    public FadeIn() {
        super.FadeIn();

        setTimeout(() => {
            this.itemStyle = this.styleStandard;
            //console.log('Estilo despues de FadeIn: ' + this.itemStyle);
        }, this.waitBeforeSetInitialStyle);
    }

    public FadeOut() {
        super.FadeOut();

        setTimeout(() => {
            this.itemStyle = this.styleStandard;
            //console.log('Estilo despues de FadeOut: ' + this.itemStyle);
        }, this.waitBeforeSetInitialStyle);
    }

    public ScaleUp() {
        super.ScaleUp();
    }

    public ScaleDown() {
        super.ScaleDown();
    }

    public ResetStyle() {
        this.SetInitStyle();
    }
}