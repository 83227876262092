import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { SoundService } from '@/modules/base/services/sound/sound.service';
import { TranslatorService } from '@/modules/base/services/translator/translator.service';

@Component({
  selector: 'app-info-screen',
  templateUrl: './info-screen.component.html',
  styleUrls: ['./info-screen.component.css']
})
export class InfoScreenComponent implements OnInit {
  @Output() infoScreenClosed = new EventEmitter();
  @Output() retryScreenClosed = new EventEmitter();
  
  public _showingInfo = false;
  public _showingRetry = false;
  public _title: string = "";
  public _subtitle: string = "";

  constructor(
    private soundService: SoundService,
    private translatorService: TranslatorService
  ) { }

  ngOnInit() { }

  ShowInfoScreen() {
    this._showingInfo = true;
  }

  ShowInfoScreenWithTitleAndSubtitle(title: string, subtitle: string) {

    this._title = title;
    this._subtitle = subtitle;
    this.ShowInfoScreen();
  }

  ShowRetryScreen() {
    this._showingInfo = false;
    this._showingRetry = true;
  }

  Hide() {

    setTimeout(() => {
      //Ocultamos pantalla de info
      if (this._showingInfo) {
        this.infoScreenClosed.emit();
        this._showingInfo = false;
      }

      //Ocultamos pantalla de retry
      if (this._showingRetry) {
        this.retryScreenClosed.emit();
        this._showingRetry = false;
      }


    }, 150);
  }

  IsShowingInfoScreen(): boolean {
    return this._showingInfo;
  }

  IsShowingRetryScreen(): boolean {
    return this._showingRetry;
  }

  ClickInfoScreen() {
    this.soundService.playSoundClick();

    if (this._showingInfo) {
      this.Hide();
    }

  }

  ClickRetryScreen() {
    this.soundService.playSoundClick();

    if (this._showingRetry) {
      this.Hide();
    }

  }

  public getStartText(): string {
    return this.translatorService.get('start').toUpperCase();
  }

  public getRetryText(): string {
    return this.translatorService.get('retry').toUpperCase();
  }
}
