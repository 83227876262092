import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { WalkableCellItemComponent } from '../walkable-cell-item/walkable-cell-item.component';
import { SoundService } from '@/modules/base/services/sound/sound.service';

const kBackgroundColorWhenEnabled = "darkgrey";
const kBackgroundColorWhenDisabled = "";
const kBackgroundColorWhenError = "red";

@Component({
  selector: 'app-walkable-cell-clickable',
  templateUrl: './walkable-cell-clickable.component.html',
  styleUrls: ['./walkable-cell-clickable.component.css']
})
export class WalkableCellClickableComponent extends WalkableCellItemComponent implements OnInit {
  @Output() clicked = new EventEmitter<WalkableCellClickableComponent>();

  private backgroundColor = kBackgroundColorWhenDisabled;

  constructor(private soundPlayer: SoundService) {
    super();
  }

  ngOnInit() {

    super.ngOnInit();
  }


  public Clicked() {
    if (this.IsEnabled()) {
      this.soundPlayer.playSoundClick();

      this.clicked.emit(this);
    }
  }

  public ShowAsEnabled() {
    this.SetOpacity(0.5);

    this.backgroundColor = kBackgroundColorWhenEnabled;
  }

  public ShowAsDisabled() {
    this.SetOpacity(0);

    this.backgroundColor = kBackgroundColorWhenDisabled;
  }

  public ShowAsError() {
    this.SetOpacity(0.8);

    this.backgroundColor = kBackgroundColorWhenError;
  }

  /** Se lee desde el template para asignar la altura del item */
  public GetStyleHeight(): string {
    return this.walkableMatrix.GetCellHeight().toString() + "px";
  }

  /** Se lee desde el template para asignar la anchura del item */
  public GetStyleWidth(): string {
    return this.walkableMatrix.GetCellWidth().toString() + "px";
  }

  /** Se lee desde el template para asignar un color a la celda */
  public GetBackgroundColor(): string {
    return this.backgroundColor;
  }
}
