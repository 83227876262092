import { Component, OnInit, ViewChild, AfterViewInit, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { SuccessViewComponent } from '../../components/menus/success-view/success-view.component';
import { TopBarComponent } from '../../components/top-bar/top-bar.component';
import { InfoScreenComponent } from '../../components/menus/info-screen/info-screen.component';
import GameInfo from '../../services/games/GameInfo';
import { SoundService } from '../../services/sound/sound.service';
import { SoundDefinitions } from "@/modules/base/services/sound/SoundDefinitions";
import { TranslatorService } from '../../services/translator/translator.service';
import { GamesService } from '../../services/games/games.service';
import { AssetsService } from '../../services/assets/assets.service';
import { GameDataService } from '../../services/game-data/game-data.service';

const kStyleLeftForOpenDoor = -73;  //porcentaje
const kStyleLeftForClosedDoor = -23;  //porcentaje

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-base-game-view',
  templateUrl: './base-game-view.component.html',
  styleUrls: ['./base-game-view.component.css']
})
export class BaseGameViewComponent implements OnInit, AfterViewInit {
  @ViewChild(TopBarComponent, { static: false }) protected topBarController: TopBarComponent;
  @ViewChild(InfoScreenComponent, { static: false }) protected infoScreenController: InfoScreenComponent;

  public gameInfo: GameInfo;
  public exito = 0;  //va del 0 al 2
  public infoFirstTimeClosed = true;
  public styleOpacityForFadeContainer = "0";

  protected router: Router;
  protected compExito: SuccessViewComponent;
  protected soundService: SoundService;
  protected translatorService: TranslatorService;
  protected assetsService: AssetsService;
  protected gamesService: GamesService;
  protected gameDataService: GameDataService;

  protected infoTitle: string = "";
  protected infoSubtitle: string = "";
  protected stickerImageName: string = "";

  private styleLeftForDoors: number = kStyleLeftForClosedDoor;

  constructor(
    protected injector: Injector
  ) {
    this.router = injector.get(Router);
    this.compExito = injector.get(SuccessViewComponent);
    this.soundService = injector.get(SoundService);
    this.translatorService = injector.get(TranslatorService);
    this.assetsService = injector.get(AssetsService);
    this.gamesService = injector.get(GamesService);
    this.gameDataService = injector.get(GameDataService);
  }

  ngOnInit() {
    if (this.gamesService.arrayGameInfo == null || this.gamesService.arrayGameInfo.length == 0) {
      this.gamesService.loadGames();
    }

    setTimeout(() => {
      this.OpenCurtains();
      this.soundService.playSoundBackground();
    }, 500);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ShowInfoScreen(this.infoTitle, this.infoSubtitle);
    }, 100);
  }

  ngOnDestroy() { }

  setGameInfo(gameInfo: GameInfo) {
    this.gameInfo = gameInfo;
    this.infoTitle = this.translatorService.getGameTitle(gameInfo.id);
    this.infoSubtitle = this.translatorService.getGameSubtitle(gameInfo.id);
    this.stickerImageName = this.assetsService.getGameTradingCardImagePath(gameInfo.id);
  }

  setupRound() { }

  afterCloseInfo() { }

  afterNextRound() { }

  timeIsOver() { }

  OpenCurtains() {
    this.styleLeftForDoors = kStyleLeftForOpenDoor;
  }

  CloseCurtains() {
    this.styleLeftForDoors = kStyleLeftForClosedDoor;
  }

  ShowInfoScreen(title: string, subtitle: string) {
    this.infoScreenController.ShowInfoScreenWithTitleAndSubtitle(title, subtitle);
  }

  nextRound(delayBeforeShowExito: number = 2500, delayBeforeShowStar = 500, delayBeforeNextRound = 1000) {
    this.exito++;

    //mostramos la estrella
    setTimeout(() => {
      this.topBarController.AcivateStar(this.exito);
    }, delayBeforeShowStar);

    //comprobamos si hemos terminado ya
    if (this.exito === 3) {
      this.soundService.stopSoundFromDefinition(SoundDefinitions.BACKGROUND);

      this.topBarController.StopTopBarTimer();

      setTimeout(() => {
        this.compExito.show(this.stickerImageName);
        this.gameDataService.setItem(this.gameInfo.id + "_" + this.gamesService.currentLevel, "3");
      }, delayBeforeShowExito);
    }
    else {
      setTimeout(() => {
        this.setupRound();
        this.afterNextRound();
      }, delayBeforeNextRound);
    }
  }

  Reload() {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  GoBack() {
    this.soundService.stopSoundFromDefinition(SoundDefinitions.BACKGROUND);

    if (this.topBarController.timerEnabled) {
      this.topBarController.StopTopBarTimer();
    }

    this.CloseCurtains();

    setTimeout(() => {
      this.router.navigate(['/games']);
    }, 500);
  }

  OnClickedBackButton() {
    this.GoBack();
  }

  OnClickedInfoButton() {
    this.infoScreenController.ShowInfoScreen();

    if (this.topBarController.timerEnabled) {
      this.topBarController.PauseTopBarTimer();
    }
  }

  OnTimerOver() {
    this.soundService.playSoundFromDefinition(SoundDefinitions.TIMER_OVER)

    this.infoScreenController.ShowRetryScreen();

    this.soundService.stopSoundFromDefinition(SoundDefinitions.BACKGROUND);

    this.timeIsOver()
  }

  OnInfoScreenClosed() {
    if (this.infoFirstTimeClosed) {
      this.infoFirstTimeClosed = false;
      this.afterCloseInfo();
    }
    else {
      if (this.topBarController.timerEnabled) {
        this.topBarController.ResumeTopBarTimer();
      }
    }
  }

  OnRetryScreenClosed() {
    this.Reload();
  }

  FadeOutScreenContainer() {
    this.styleOpacityForFadeContainer = "0";
  }

  FadeInScreenContainer() {
    this.styleOpacityForFadeContainer = "1";
  }

  public GetStyleLeftForDoor(): string {
    return this.styleLeftForDoors.toString() + "%";
  }
}