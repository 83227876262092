import { Component, Inject, OnInit, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

import { JuegosButtonComponent } from '../../components/juegos-button/juegos-button.component';
import { tLanguage } from '../../services/translator/language';
import { SoundService } from '@/modules/base/services/sound/sound.service';
import { SoundDefinitions } from '@/modules/base/services/sound/SoundDefinitions';
import { TranslatorService } from '../../services/translator/translator.service';
import { GamesService } from '../../services/games/games.service';
import { GameDataService } from '../../services/game-data/game-data.service';

const kStyleLeftForOpenDoor = -73;  //porcentaje
const kStyleLeftForClosedDoor = -23;  //porcentaje

@Component({
  selector: 'app-games-view',
  templateUrl: './games-view.component.html',
  styleUrls: ['./games-view.component.css']
})
export class GamesViewComponent implements OnInit, AfterViewInit {
  @ViewChildren('gameButtons') arrayGameButtonControllers: QueryList<JuegosButtonComponent>;

  elem;
  isFullscreen = false;

  private title: string = "";
  private styleLeftForDoors: number = kStyleLeftForClosedDoor;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private _router: Router,
    private soundService: SoundService,
    private translatorService: TranslatorService,
    public gamesService: GamesService,
    private gameDataService: GameDataService
  ) { }

  ngOnInit() {
    //comprobamos si ya esta generada la info de los juegos y sino la generamos
    if (this.gamesService.arrayGameInfo == null || this.gamesService.arrayGameInfo.length == 0) {
      this.gamesService.loadGames();
    }

    this.title = this.translatorService.getTitleThemeForCurrentLanguage();

    setTimeout(() => {
      this.elem = document.documentElement;
      this.OpenCurtains();
    }, 500);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.CheckMedals();
    }, 1000);
  }

  GetTitle(): string {
    return this.title;
  }

  CheckMedals() {
    let itemStored = null;
    let delay: number = 0;

    //Recorremos array de componentes para los botones de juegos
    this.arrayGameButtonControllers.forEach(iteratingButtonController => {
      itemStored = this.gameDataService.getItem(iteratingButtonController.gameInfo.id + "_" + this.gamesService.currentLevel);

      if (itemStored != null && itemStored == 3) {
        setTimeout(() => {
          iteratingButtonController.ShowMedal();
          
          this.soundService.playSoundFromDefinition(SoundDefinitions.BOING);
        }, delay);

        delay += 150;
      }
    });
  }

  downloadPdf() {
    this.soundService.playSoundClick();

    if (this.translatorService.currentLanguage == tLanguage.ES) {
      var win = window.open("assets/success/album/album_general_es.pdf", '_blank');
    }
    else {
      var win = window.open("assets/success/album/album_general_ca.pdf", '_blank');
    }
    win.focus();
  }

  ClickGameButton(gameRoute: string) {
    this.CloseCurtains();

    setTimeout(() => {
      this._router.navigate(['/' + gameRoute]);
    }, 1000);
  }

  onResetGameDataButtonClicked() {
    this.soundService.playSoundClick();

    this.gameDataService.reset();

    this.arrayGameButtonControllers.forEach(iteratingButtonController => {
      iteratingButtonController.HideMedal();
    });
  }

  goBack() {
    this.soundService.playSoundClick();

    this.CloseCurtains();

    setTimeout(() => {
      this._router.navigate(['/presentation']);
    }, 1000);
  }

  checkScreen() {
    this.soundService.playSoundClick();

    if (this.isFullscreen) {
      this.closeFullscreen();
    } else {
      this.fullscreen();
    }
    this.isFullscreen = !this.isFullscreen;
  }

  fullscreen() {
    (document.getElementById("buttonScreen") as HTMLImageElement).src = "assets/ui/btn_reducir.png";
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen() {
    (document.getElementById("buttonScreen") as HTMLImageElement).src = "assets/ui/btn_ampliar.png";
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  OpenCurtains() {
    this.styleLeftForDoors = kStyleLeftForOpenDoor;
  }

  CloseCurtains() {
    this.styleLeftForDoors = kStyleLeftForClosedDoor;
  }

  GetStyleLeftForDoor(): string {
    return this.styleLeftForDoors.toString() + "%";
  }

  public GetBorrarLogrosTranslation(): string {
    return this.translatorService.get('deleteAchievements').toUpperCase();
  }

  public GetAlbumTranslation(): string {
    return this.translatorService.get("album").toUpperCase();
  }
}
