import { Component, OnInit, Input, HostListener, OnDestroy } from '@angular/core';

import { WalkableCellMatrix, WalkableCell, tWalkableCellType } from '../../walkable-cell-matrix';
import { WalkableCellItemComponent } from '../walkable-cell-item/walkable-cell-item.component';
import { CustomPoint } from '@/modules/base/models/customPoint';
import { NumberService } from '@/modules/base/services/number/number.service';

@Component({
  selector: 'app-walkable-cell-disapearing-item',
  templateUrl: './walkable-cell-disapearing-item.component.html',
  styleUrls: ['./walkable-cell-disapearing-item.component.css']
})
export class WalkableCellDisapearingItemComponent extends WalkableCellItemComponent implements OnInit, OnDestroy {

  @Input() cellType: tWalkableCellType;
  @Input() showFromStart: boolean = false;
  @Input() timeShowingInterval: number[] = [];
  @Input() timeWatingToShowInterval: number[] = [];

  private timerShowing = null;
  private timerWaitingToShow = null;

  constructor(private numberService: NumberService) {
    super();

    //De inicio no mostramos el item
    this.SetScale(0, 0);
    this.enabled = false;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /** Muestra el item en una nueva celda */
  ShowAtRandomCell() {
    if (!this.enabled) {
      return;
    }

    //Buscamos una celda disponible para mostrarnos
    this.SetCurrentCell();

    //nos mostramos
    this.ShowImageWithScaleEffect(this.imgName, this.scaleShowing);

    //Sonido. Diferente si somos enemigo o si somos collectable
    if (this.cellType == tWalkableCellType.ENEMY || this.cellType == tWalkableCellType.ENEMY2) {
      //Utils.PlaySound(SoundDefinitions.Sound.AHA);
    }
    else {
      //Utils.PlaySound(SoundDefinitions.Sound.APARICION1);
    }

    //ponemos en marcha el intervalo para ocultarnos
    this.timerShowing = setTimeout(() => {

      this.Hide();
    }, this.GetRandomTimeShowing());
  }

  /** Oculta el item y libera la celda */
  Hide() {
    if (!this.enabled) {
      return;
    }

    //nos ocultamos
    this.HideWithScaleEffect(0.1);

    //liberamos celda
    this.walkableCell.SetType(tWalkableCellType.WALKABLE);

    //ponemos en marcha intervalo para volver a mostrarnos
    this.timerWaitingToShow = setTimeout(() => {

      this.ShowAtRandomCell();
    }, this.GetRandomTimeWaitingToShow());
  }

  /** Busca una celda libre aleatoria que ocupar */
  SetCurrentCell() {
    //Dimensiones de la matriz de celdas
    let rows = this.walkableMatrix.GetCellsMatrix().length;
    let columns = this.walkableMatrix.GetCellsMatrix()[0].length;

    //matriz de celdas
    let cellMatrix = this.walkableMatrix.GetCellsMatrix();

    //celda aleatoria
    let randomRow = this.numberService.getRandomInt(0, rows - 1);
    let randomCol = this.numberService.getRandomInt(0, columns - 1);
    this.walkableCell = cellMatrix[randomRow][randomCol];

    //console.log("Celda del player: Row: " + this.walkableMatrix.GetPlayerCell().GetPositionInMatrix().row + ", col: " + this.walkableMatrix.GetPlayerCell().GetPositionInMatrix().column);

    //Buscamos una celda que sea walkable y que no sea:
    //-Enemigo
    //-Collectable
    //-Player
    while (this.walkableCell.GetType() != tWalkableCellType.WALKABLE ||
      this.walkableCell.GetType() == tWalkableCellType.ENEMY ||
      this.walkableCell.GetType() == tWalkableCellType.COLLECTABLE ||
      this.walkableCell == this.walkableMatrix.GetPlayerCell()) {

      randomRow = this.numberService.getRandomInt(0, rows - 1);
      randomCol = this.numberService.getRandomInt(0, columns - 1);
      this.walkableCell = cellMatrix[randomRow][randomCol];
    }

    //Posicion del item
    const initPoint: CustomPoint = new CustomPoint(
      this.walkableMatrix.GetCellWidth() * this.walkableCell.GetPositionInMatrix().column,
      this.walkableMatrix.GetCellHeight() * (this.walkableMatrix.GetNumRows() - 1 - this.walkableCell.GetPositionInMatrix().row)
    );

    this.styleBottom = initPoint.GetBottom();
    this.styleLeft = initPoint.GetLeft();

    //Asignamos esta celda para el item
    this.walkableCell.SetType(this.cellType);
  }

  private GetRandomTimeWaitingToShow(): number {
    return this.numberService.getRandomInt(this.timeWatingToShowInterval[0], this.timeWatingToShowInterval[1]) * 1000;
  }

  private GetRandomTimeShowing(): number {
    return this.numberService.getRandomInt(this.timeShowingInterval[0], this.timeShowingInterval[1]) * 1000;
  }

  public SetTimeShowing(time: number[]) {
    this.timeShowingInterval = time;
  }

  public SetTimeWaitingToShow(time: number[]) {
    this.timeWatingToShowInterval = time;
  }

  public GetCurrentCell(): WalkableCell {
    return this.walkableCell;
  }

  public GetCellType(): tWalkableCellType {
    return this.cellType;
  }

  public EnableAfterDelay(delay: number) {
    setTimeout(() => {
      this.Enable();
    }, 1000 * delay);
  }

  public Enable() {
    if (this.enabled) {
      return;
    }

    this.enabled = true;

    //marcamos celda
    if (this.walkableCell != null) {
      this.walkableCell.SetType(tWalkableCellType.WALKABLE);
    }

    this.ShowAtRandomCell();
  }

  public Disable() {
    if (!this.enabled) {
      return;
    }

    this.enabled = false;

    if (this.timerShowing) {
      clearTimeout(this.timerShowing);
    }

    if (this.timerWaitingToShow) {
      clearTimeout(this.timerWaitingToShow);
    }

    //desmarcamos celda
    if (this.walkableCell != null) {
      this.walkableCell.SetType(tWalkableCellType.WALKABLE);
    }

    //Lo desasignamos de la celda
    this.walkableCell = null;

    this.SetScale(0, 0);
  }

  public ClearTimeOuts() {
    clearTimeout(this.timerWaitingToShow);
    clearTimeout(this.timerShowing);
  }

  /** Metodo que se llama cuando abandonamos la pagina */
  @HostListener('unloaded')
  ngOnDestroy() {
    //Paramos los intervalos de mostrar y ocultar el componente
    this.ClearTimeOuts();
  }
}