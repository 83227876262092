export class PuzzleData {
    private id: string;
    private idContainer: string;
    private idPlaceholder: string;
    private imgPathForDraggable: string;
    private imgPathForPlaceholder: string;
    private addedToPuzzle: boolean;
    private topContainer: number;
    private leftContainer: number;
    private widthContaner: number;
    private heightContainer: number;
    private customClass: string;

    constructor(_id: string, _imgPathForDraggable: string, _imgPathForPlaceholder: string, _topContainer: number, _leftContainer: number, _widthContaner: number, _heightContainer: number, currentLvl: number, _idContainer: string) {
        this.id = _id;
        this.idContainer = _idContainer;
        this.idPlaceholder = _id + '_PLACEHOLDER';
        this.imgPathForDraggable = _imgPathForDraggable;
        this.imgPathForPlaceholder = _imgPathForPlaceholder;
        this.topContainer = _topContainer;
        this.leftContainer = _leftContainer;
        this.widthContaner = _widthContaner;
        this.heightContainer = _heightContainer;
        this.customClass = 'puzzleClassicItemDraggable' + currentLvl;
        this.addedToPuzzle = false;
    }

    addToPuzzle() {
        this.addedToPuzzle = true;
    }

    public get getID(): string {
        return this.id;
    }

    public get getIDContainer(): string {
        return this.idContainer;
    }

    public get getIDPlaceholder(): string {
        return this.idPlaceholder;
    }

    public get getImgPathForDraggable(): string {
        return this.imgPathForDraggable;
    }

    public get getImgPathForPlaceholder(): string {
        return this.imgPathForPlaceholder;
    }

    public get getTopContainer(): number {
        return this.topContainer;
    }

    public get getLeftContainer(): number {
        return this.leftContainer;
    }

    public get getWidthContaner(): number {
        return this.widthContaner;
    }

    public get getHeightContainer(): number {
        return this.heightContainer;
    }

    public get isAddedToPuzzle(): boolean {
        return this.addedToPuzzle;
    }

    public get getCustomClass(): string {
        return this.customClass;
    }
}
