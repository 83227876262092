import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

import { ItemHTMLComponent } from '@/modules/base/components/item-html/item-html.component';
import { WalkableCellMatrix, WalkableCell } from '../../walkable-cell-matrix';
import { CustomPoint } from '@/modules/base/models/customPoint';

@Component({
  selector: 'app-walkable-cell-item',
  templateUrl: './walkable-cell-item.component.html',
  styleUrls: ['./walkable-cell-item.component.css']
})
export class WalkableCellItemComponent extends ItemHTMLComponent implements OnInit, AfterViewInit {

  @Input() walkableMatrix:WalkableCellMatrix;
  @Input() walkableCell:WalkableCell;

  protected enabled:boolean = true;

  constructor() { 
    super();
  }

  ngOnInit() {

    super.ngOnInit();
  }

  ngAfterViewInit()
  {
    setTimeout(() => {
      this.InitCellPosition();
    }, 50);
  }

  InitCellPosition()
  {

    if(this.walkableCell == null)
    {
      return;
    }

    //Calculo de la posicion inicial a partir de la celda que ocupa en la matriz
    let initPoint:CustomPoint = new CustomPoint(this.walkableMatrix.GetCellWidth() * this.walkableCell.GetPositionInMatrix().column, this.walkableMatrix.GetCellHeight() * (this.walkableMatrix.GetNumRows() - 1 - this.walkableCell.GetPositionInMatrix().row));

    this.styleBottom = initPoint.GetBottom();
    this.styleLeft = initPoint.GetLeft();
  }

  public SetEnabled(value:boolean)
  {
    this.enabled = value;
  }

  public IsEnabled():boolean
  {
    
    return this.enabled;
  }

}
