<div class="top-bar-component">

    <!-- BUTTONS -->
    <div class="buttons-container">
        <img class="button" src="./assets/ui/btn_atras_exito.png" alt="atras" (click)="ClickBack()">
        <img class="button" src="./assets/ui/btn_info.png" (click)="ClickInfo()">
    </div>

    <!-- STARS -->
    <img style="position: absolute;top: 10px;left: 603px;" src="./assets/ui/pastilla_estrellas.png">
    <div class="stars-container">
        <div class="star-container" *ngFor="let estrellaPlaceHolder of arrayStarsPlaceholders">
            <img class="star-image" [src]='estrellaPlaceHolder.GetImagePathForItem'>
        </div>
    </div>
    <div class="stars-container">
        <div class="star-container" *ngFor="let estrellaActivada of arrayStarsActivated">
            <img [ngClass]='estrellaActivada.GetStyle' [src]='estrellaActivada.GetImagePathForItem'>
        </div>
    </div>

    <img class="star" src="./assets/ui/estrella.png" style="position: absolute;top: 18px;left: 618px;"
        id="estrella_1" />
    <img class="star" src="./assets/ui/estrella.png" style="position: absolute;top: 18px;left: 667px;"
        id="estrella_2" />
    <img class="star" src="./assets/ui/estrella.png" style="position: absolute;top: 18px;left: 715px;"
        id="estrella_3" />

    <!-- TIMER -->
    <div *ngIf="timerEnabled">
        <img style="position: absolute;top: 23px;left: 1054px;" src="./assets/ui/relojito_tiempo.png">
        <img style="position: absolute;top: 20px;left: 1100px;" src="./assets/ui/barra_tiempo_vacia.png">
        <img class="timerBar" [ngStyle]="{'clip':'rect(0px, ' + (230 - currentWidthForTimerBar) + 'px, 42px, 0px)'}"
            src="./assets/ui/barra_tiempo.png">
    </div>
</div>