import { Component, Injector, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { Draggable } from '@/modules/base/models/draggable';
import { SuccessViewComponent } from '@/modules/base/components/menus/success-view/success-view.component';
import { SoundDefinitions } from "@/modules/base/services/sound/SoundDefinitions";
import { BaseGameViewComponent } from '@/modules/base/views/base-game-view/base-game-view.component';
import { Container } from '@/modules/base/models/container';
import { ArrayService } from '@/modules/base/services/array/array.service';

const kDraggableSufix = "_mini";
const kContainerSufix = "_silueta";
const kSolvedSufix = "_grande"
const kAllSombrasIds: string[] = ["chica", "chico", "hoz", "lanza", "nena", "nene", "pesca", "silex", "trigo"];
const kNumDraggablesByLevel: number[][] = [[null], [2, 2, 3], [2, 3, 4], [3, 4, 5]];
const kNumContainersByLevel: number[][] = [null, [2, 2, 3], [2, 3, 4], [3, 4, 5]];

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-shapes-view',
  templateUrl: './shapes-view.component.html',
  styleUrls: ['./shapes-view.component.css']
})
export class ShapesViewComponent extends BaseGameViewComponent implements OnInit {
  public arrayContainersForTemplate: Container[] = [];
  public arrayDraggablesForTemplate: Draggable[] = [];
  private allSombrasContainers: Container[] = [];
  private allSombrassDraggables: Draggable[] = [];
  private containersAvailableForLevel: Container[] = [];
  private draggablesAvailableForLevel: Draggable[] = [];

  constructor(
    injector: Injector,
    private arrayService: ArrayService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setGameInfo(this.gamesService.getGameInfo("02"));

    this.CreateDraggablesAndContainers();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit()
  }

  afterCloseInfo() {
    this.Start();
  }

  /** Crea todos los objetos que vamos a usar en el juego */
  CreateDraggablesAndContainers() {
    let container = null;
    let draggable = null;
    let containerId: string = '';
    let draggableId: string = '';
    let pathToAssets = 'assets/games/02/';
    let imgContainer: string = '';
    let imgDraggable: string = '';
    let imgContainerSolved: string = '';
    let idContainer: string = '';
    let idDraggable: string = '';

    //Recorremos todos los ids y vamos creando objetos containers y draggables
    kAllSombrasIds.forEach(id => {
      imgDraggable = id + kDraggableSufix + ".png";
      imgContainer = id + kContainerSufix + ".png";
      imgContainerSolved = id + kSolvedSufix + ".png";
      idContainer = id + '_container';
      idDraggable = id + '_draggable';

      //generamos un objeto container
      container = new Container(idContainer, pathToAssets, imgContainer, imgContainerSolved);

      //seteamos el draggable que ira en el container
      container.idDraggable = idDraggable;

      //generamos objeto draggable
      draggable = new Draggable(idDraggable, pathToAssets, imgDraggable);

      //seteamos el container al que tiene que ir el draggable
      draggable.idContainer = idContainer;

      //agregamos a los arrays
      this.allSombrasContainers.push(container);
      this.allSombrassDraggables.push(draggable);

      //test
      // console.log('Hemos agregado un container: ' + container.id);
      // console.log('Hemos agregado un draggable: ' + container.idDraggable);

    });
  }

  /** Selecciona containers aleatorios para esta ronda */
  SetContainersForCurrentRound() {
    let numContainersToGet = kNumContainersByLevel[this.gamesService.currentLevel][this.exito];

    this.arrayService.shuffle(this.allSombrasContainers);
    for (let i = 0; i < numContainersToGet; i++) {
      this.containersAvailableForLevel.push(this.allSombrasContainers[i]);
    }

    //Copiamos el array al array al que se accede desde el template
    this.arrayContainersForTemplate = Object.assign([], this.containersAvailableForLevel);
  }

  /** Muestra los containers en pantalla con efecto */
  ShowContainersWithEffect() {
    for (let i = 0; i < this.arrayContainersForTemplate.length; i++) {

      this.arrayContainersForTemplate[i].SetTransition("transform 0.3s linear");
      this.arrayContainersForTemplate[i].SetTransform("scale(1)");
    }
  }

  /** Muestra los draggables en pantalla con efecto */
  ShowDraggablesWithEffect() {
    //Copiamos el array al array de draggables accesible desde el template
    this.arrayDraggablesForTemplate = Object.assign([], this.draggablesAvailableForLevel);

    //Hacemos aparecer los draggables con un efecto escalado
    let delay = 0;
    let increment = 150;
    for (let i = 0; i < this.arrayDraggablesForTemplate.length; i++) {

      setTimeout(() => {
        this.arrayDraggablesForTemplate[i].SetTransition("transform 0.3s cubic-bezier(0.25, 0.25, 0.5, 1.9)");

        //escala
        this.arrayDraggablesForTemplate[i].SetTransform("scale(1)");

        this.soundService.playSoundFromFileName(SoundDefinitions.FLIPCARD);
      }, delay);

      delay += increment;
    }
  }

  /** Selecciona draggables para resolver el container pasado */
  SetDraggablesForContainer(container: Container) {
    this.ResetDraggables();

    //draggable correcto
    let rightDraggable = this.GetDraggableById(container.idDraggable);
    this.draggablesAvailableForLevel.push(rightDraggable);

    //Resto de draggables seleccionados al azar
    let numDraggablesToGet = kNumDraggablesByLevel[this.gamesService.currentLevel][this.exito] - 1; //resto 1 porque el draggable correcto ya esta añadido al array

    //Barajamos el array de todos los draggables disponibles y vamos sacando tantos items como necesitemos
    this.arrayService.shuffle(this.allSombrassDraggables);

    //Recorremos array de draggables hasta que encontremos tantos como necesitamos
    let counterDraggablesFound = 0;
    for (let i = 0; i < this.allSombrassDraggables.length; i++) {

      if (this.IsDraggableEligible(this.allSombrassDraggables[i])) {
        this.draggablesAvailableForLevel.push(this.allSombrassDraggables[i]);
        counterDraggablesFound++;

        if (counterDraggablesFound >= numDraggablesToGet) {
          break;
        }
      }
    }

    //Barajamos array para que el correcto no sea siempre el primero de la lista
    this.arrayService.shuffle(this.draggablesAvailableForLevel);
  }

  /** Comprueba si un draggable es valido para ser elegido */
  IsDraggableEligible(draggable: Draggable): boolean {

    let result = true;
    //Si el draggable ya existe no lo podemos volver a elegir
    if (this.draggablesAvailableForLevel.indexOf(draggable) != -1) {
      result = false;
    }

    //Si es un draggable que resuelve alguno de los containers no se puede elegir
    for (let i = 0; i < this.containersAvailableForLevel.length; i++) {
      if (this.containersAvailableForLevel[i].idDraggable == draggable.id) {
        result = false;
        break;
      }
    }

    return result;
  }

  /** Selecciona un container entre los disponibles para la ronda que no este ya resuelto  */
  GetContainerUnsolved(): Container {
    this.arrayService.shuffle(this.containersAvailableForLevel);
    for (let i = 0; i < this.containersAvailableForLevel.length; i++) {

      if (!this.containersAvailableForLevel[i].solved) {
        return this.containersAvailableForLevel[i];
      }
    }

    console.log("[ERROR]: No hemos encontrado ningun container que este sin resolver.");
    return null;
  }

  Start() {
    this.setupRound();
  }

  /** Devuelve el draggable con el id pasado */
  GetDraggableById(id: string): Draggable {
    let draggable: Draggable = null;
    this.allSombrassDraggables.forEach(iteratingDraggable => {

      if (iteratingDraggable.id === id) {

        draggable = iteratingDraggable;
      }
    });

    return draggable;
  }

  /** Devuelve el Container con el id pasado */
  GetContainerById(id: string): Container {
    let container: Container = null;
    this.containersAvailableForLevel.forEach(iteratingContainer => {

      if (iteratingContainer.id === id) {

        container = iteratingContainer;
      }
    });

    return container;
  }

  /** Prepara la siguiente ronda */
  setupRound() {
    this.ResetContainers();
    this.ResetDraggables();

    //Damos tiempo para hacer desaparecer los containers y los draggables
    setTimeout(() => {
      //Seleccionamos sombras aleatorias
      this.SetContainersForCurrentRound();

      //Mostramos los containers
      setTimeout(() => {
        this.ShowContainersWithEffect();
      }, 100);

      //Seleccionamos los draggables para resolver uno de los containers
      let containerToSolve = this.GetContainerUnsolved();
      this.SetDraggablesForContainer(containerToSolve);

      //mostramos los draggables con efecto
      this.ShowDraggablesWithEffect();

    }, 500);
  }

  /** Resetea los containers.
   * - Los pone como no resueltos
   * - Vuelve a poner la imagen original en lugar de la resuelta
   */
  ResetContainers() {
    this.arrayContainersForTemplate.forEach(iteratingContainer => {
      iteratingContainer.SetTransition("transform 0.15s linear");
      iteratingContainer.SetTransform("scale(0)");
    });

    //Containers como no resueltos y con imagen original
    this.containersAvailableForLevel.forEach(iteratingContainer => {
      iteratingContainer.solved = false;
      iteratingContainer.ShowImageOriginal();
    });

    //vaciamos array de containers para esta ronda
    this.containersAvailableForLevel.splice(0, this.containersAvailableForLevel.length);
  }

  /** Resetea los draggables.
   * - Los pone otra vez como activados
   */
  ResetDraggables() {
    this.arrayDraggablesForTemplate.forEach(iteratingDraggable => {
      iteratingDraggable.SetTransform("scale(0)");
      iteratingDraggable.SetTransition("transform 0.15s linear");
    });

    //Draggables activos
    this.draggablesAvailableForLevel.forEach(iteratingDraggable => {
      iteratingDraggable.disabled = false;
    });

    //vaciamos array de containers para esta ronda
    this.draggablesAvailableForLevel.splice(0, this.draggablesAvailableForLevel.length);
  }

  /** Comprueba si hemos completado una ronda con exito */
  checkEndGame(): boolean {
    let result = true;

    this.containersAvailableForLevel.forEach(iteratingContainer => {
      if (!iteratingContainer.solved) {
        result = false;
      }
    });

    return result;
  }

  /** Acciones cuando movemos arrastramos a un contenedor correcto */
  MovedToRightContainer(draggable: Draggable, idContainer: string) {
    //mostramos imagen coloreada
    let sombra = this.GetContainerById(idContainer);
    sombra.ShowImageSustitucion();

    //marcamos el container como completado
    sombra.solved = true;

    //desactivamos el arrastable
    draggable.disabled = true;

    //comprobamos si hemos terminado el juego
    if (this.checkEndGame()) {
      this.nextRound();
    }
    else {
      //Refrescamos los draggables
      let nextContainerToSolve = this.GetContainerUnsolved();
      this.SetDraggablesForContainer(nextContainerToSolve);

      //Mostramos los nuevos draggables
      setTimeout(() => {
        this.ShowDraggablesWithEffect();
      }, 500);
    }
  }

  /** Evento que se dispara cuando arrastramos un item */
  Drop(event: CdkDragDrop<Draggable>) {
    if (event.isPointerOverContainer && event.previousContainer.id != event.container.id) {
      this.soundService.playSoundAcierto();
      this.MovedToRightContainer(event.previousContainer.data, event.container.id);

    } else {
      this.soundService.playSoundError();
    }
  }
}