import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { ItemHTMLComponent } from '../item-html/item-html.component';
import { AssetsService } from '@/modules/base/services/assets/assets.service';
import GameInfo from '@/modules/base/services/games/GameInfo';
import { SoundService } from '@/modules/base/services/sound/sound.service';
import { TranslatorService } from '@/modules/base/services/translator/translator.service';
import { SoundDefinitions } from "@/modules/base/services/sound/SoundDefinitions";

@Component({
  selector: 'app-juegos-button',
  templateUrl: './juegos-button.component.html',
  styleUrls: ['./juegos-button.component.css']
})
export class JuegosButtonComponent extends ItemHTMLComponent implements OnInit {
  @Input() gameInfo: GameInfo;
  @Output() clickedButton = new EventEmitter<string>();

  private enabled: boolean = true;
  private styleScaleForMedal: number = 0;

  constructor(
    private translatorService: TranslatorService,
    private assetsService: AssetsService,
    private soundService: SoundService
  ) {
    super();
  }

  ngOnInit() { }

  public ShowMedal() {
    this.styleScaleForMedal = 1;
  }

  public HideMedal() {
    this.styleScaleForMedal = 0;
  }

  GetStyleTransformForMedal(): string {
    return "scale(" + this.styleScaleForMedal.toString() + ")";
  }

  ClickButton() {
    this.soundService.playSoundFromDefinition(SoundDefinitions.CLICK);

    if (!this.enabled) {
      return;
    }

    this.enabled = false;

    this.clickedButton.emit(this.gameInfo.route);
  }

  getTitle(): string {
    return this.translatorService.getGameTitle(this.gameInfo.id);
  }

  getThumbnailPath(): string {
    return this.assetsService.getGameThumbnailPath(this.gameInfo.id);
  }
}
