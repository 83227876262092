import { ChangeDetectorRef, Component, ElementRef, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { SuccessViewComponent } from '@/modules/base/components/menus/success-view/success-view.component';
import { BaseGameViewComponent } from '@/modules/base/views/base-game-view/base-game-view.component';
import { HorizontalTravelScenaryService } from '../../services/horizontal-travel-scenary.service';
import { CellPosition } from '../../models/interfaces';
import { ItemHTMLComponent } from '@/modules/base/components/item-html/item-html.component';
import { PlayerState, ScenaryCellImage, ScenaryCellType } from '../../models/enums';
import { HorizontalTravelCameraService } from '../../services/horizontal-travel-camera.service';
import { HorizontalTravelGoalService } from '../../services/horizontal-travel-goal.service';
import { SoundDefinitions } from '@/modules/base/services/sound/SoundDefinitions';

const ASSETS_FOLDER: string = "./assets/games/06";
const PLAYER_WIDTH: number = 182;

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-horizontal-travel-view',
  templateUrl: './horizontal-travel-view.component.html',
  styleUrls: ['./horizontal-travel-view.component.css']
})
export class HorizontalTravelViewComponent extends BaseGameViewComponent implements OnInit {
  @ViewChild('scene', { static: false }) protected scene: ElementRef;
  @ViewChild('player', { static: false }) protected player: ItemHTMLComponent;
  @ViewChild('lightMask', { static: false }) protected lightMask: ItemHTMLComponent;
  @ViewChildren('scenaryRows') scenaryRows: QueryList<ElementRef>;

  public currentScenaryGoalColumn: number;
  public currentScenaryMatrix: string[][];
  private cellSize: number;

  public isPlayerMoving: boolean;
  private playerInitialPosition: CellPosition;
  private playerPosition: CellPosition;
  private playerState: PlayerState = PlayerState.Standing;
  private isPlayerOnGoal: boolean;

  constructor(
    injector: Injector,
    public cameraService: HorizontalTravelCameraService,
    private scenaryService: HorizontalTravelScenaryService,
    private goalService: HorizontalTravelGoalService,
    private cdRef: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setGameInfo(this.gamesService.getGameInfo("06"));

    this.exito = 0;

    this.setCurrentScenary();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.player.SetWidth(PLAYER_WIDTH);

    this.cdRef.detectChanges();

    setTimeout(() => {
      this.setupRound();
    }, 250);
  }

  afterCloseInfo() { }

  setupRound() {
    this.setCurrentScenary();
    this.resetScenary();
    this.resetPlayer();
  }

  resetScenary() {
    const cell: HTMLElement = this.getScenaryRowCells(0)[0];

    this.cellSize = cell.clientWidth;
  }

  resetPlayer() {
    this.playerInitialPosition = this.getPlayerInitialPosition();

    this.setPlayerInitialPosition(this.playerInitialPosition);

    this.isPlayerOnGoal = false;
  }

  setPlayerInitialPosition(cellPosition: CellPosition) {
    const LIGHT_BOTTOM_OFFSET: number = 120;
    const LIGHT_LEFT_OFFSET: number = 75;
    const bottom: number = (cellPosition.row * -this.cellSize) + this.cellSize;
    const left: number = cellPosition.column * this.cellSize - PLAYER_WIDTH * 0.25;

    this.playerPosition = cellPosition;
    this.player.SetBottomPosition(bottom);
    this.player.SetLeftPosition(left);

    this.lightMask.SetBottomPosition(bottom + 1536 * 0.5 - LIGHT_BOTTOM_OFFSET);
    this.lightMask.SetLeftPosition(left - 2732 * 0.5 + LIGHT_LEFT_OFFSET);

    this.cameraService.update(this.playerPosition, this.currentScenaryMatrix, this.cellSize, false);

    this.cdRef.detectChanges();
  }

  setPlayerPosition(cellPosition: CellPosition, movementSeconds: number) {
    const LIGHT_BOTTOM_OFFSET: number = 120;
    const LIGHT_LEFT_OFFSET: number = 75;
    const bottom: number = (cellPosition.row * -this.cellSize) + this.cellSize;
    const left: number = cellPosition.column * this.cellSize - PLAYER_WIDTH * 0.25;

    this.playerPosition = cellPosition;
    this.player.SetBottomPosition(bottom, true, movementSeconds);
    this.player.SetLeftPosition(left, true, movementSeconds);
    this.isPlayerMoving = true;

    setTimeout(() => {
      this.isPlayerMoving = false;
    }, movementSeconds * 1000);

    this.lightMask.SetBottomPosition(bottom + 1536 * 0.5 - LIGHT_BOTTOM_OFFSET, true, movementSeconds);
    this.lightMask.SetLeftPosition(left - 2732 * 0.5 + LIGHT_LEFT_OFFSET, true, movementSeconds);

    this.cameraService.update(this.playerPosition, this.currentScenaryMatrix, this.cellSize);

    this.cdRef.detectChanges();
  }

  get playerImageFilename(): string {
    let filename: string = 'idle.gif';

    switch (this.playerState) {
      case PlayerState.Standing: {
        filename = this.isPlayerMoving ? 'walk.gif' : 'idle.gif';
        break;
      }
      case PlayerState.OnTheStairs: {
        filename = this.isPlayerMoving ? 'stairs-moving.gif' : 'stairs-idle.gif';
        break;
      }
      case PlayerState.Crouching: {
        filename = this.isPlayerMoving ? 'crouch-moving.gif' : 'crouch-idle.gif';
        break;
      }
      case PlayerState.Creeping: {
        filename = this.isPlayerMoving ? 'creep-moving.gif' : 'creep-idle.png';
        break;
      }
      case PlayerState.Jumping: {
        filename = 'jump.png';
        break;
      }
      case PlayerState.Winning: {
        filename = "win.gif";
        break;
      }
    }

    return filename;
  }

  getPlayerInitialPosition(): CellPosition {
    return this.scenaryService.getPlayerInitialPosition(
      this.gamesService.currentLevel,
      this.exito
    );
  }

  getScenaryRows(): HTMLElement[][] {
    return this.scenaryRows.map((row) => {
      return Array.from(row.nativeElement.children)
    })
  }

  getScenaryRowCells(index: number): HTMLElement[] {
    return this.getScenaryRows()[index];
  }

  getActionButtonImagePath(actionId: string): string {
    return `${ASSETS_FOLDER}/buttons/${actionId}-button.png`;
  }

  getCellImagePath(cellType: string): string {
    let cellName: string = ScenaryCellImage[this.getEnumKeyByEnumValue(ScenaryCellType, cellType)];

    return `./assets/games/06/cells/${cellName}.png`;
  }

  private getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : '';
  }

  setCurrentScenary(): void {
    this.currentScenaryGoalColumn = this.scenaryService.getScenaryGoalColumn(this.gamesService.currentLevel - 1, this.exito);
    this.currentScenaryMatrix = this.scenaryService.getScenaryMatrix(this.gamesService.currentLevel - 1, this.exito);
  }

  onActionButtonClicked(actionId: string): void {
    if (!this.isPlayerMoving && !this.isPlayerOnGoal) {
      switch (actionId) {
        case "walk": {
          if (this.scenaryService.playerCanWalk(this.playerPosition, this.currentScenaryMatrix)) {
            const nextPosition: CellPosition = {
              row: this.playerPosition.row,
              column: this.playerPosition.column + 1
            };

            this.playerState = PlayerState.Standing;

            this.setPlayerPosition(nextPosition, 0.5);
          } else {
            this.soundService.playSoundError();
          }

          break;
        }
        case "stairs": {
          const movementSeconds: number = 1;

          if (this.scenaryService.playerCanGoDownStairs(this.playerPosition, this.currentScenaryMatrix)) {
            const nextPosition: CellPosition = {
              row: this.playerPosition.row + 1,
              column: this.playerPosition.column
            };

            this.playerState = PlayerState.OnTheStairs;

            this.setPlayerPosition(nextPosition, movementSeconds);

            setTimeout(() => {
              if (!this.scenaryService.playerCanGoDownStairs(this.playerPosition, this.currentScenaryMatrix)) {
                this.playerState = PlayerState.Standing;
              }
            }, movementSeconds * 1000);
          } else if (this.scenaryService.playerCanGoUpStairs(this.playerPosition, this.currentScenaryMatrix)) {
            const nextPosition: CellPosition = {
              row: this.playerPosition.row - 1,
              column: this.playerPosition.column
            };

            this.playerState = PlayerState.OnTheStairs;

            this.setPlayerPosition(nextPosition, movementSeconds);

            setTimeout(() => {
              if (!this.scenaryService.playerCanGoUpStairs(this.playerPosition, this.currentScenaryMatrix)) {
                this.playerState = PlayerState.Standing;
              }
            }, movementSeconds * 1000);
          }
          else {
            this.soundService.playSoundError();
          }

          break;
        }
        case "crouch": {
          if (this.scenaryService.playerCanCrouch(this.playerPosition, this.currentScenaryMatrix)) {
            const nextPosition: CellPosition = {
              row: this.playerPosition.row,
              column: this.playerPosition.column + 1
            };
            const movementSeconds: number = 0.75;

            this.playerState = PlayerState.Crouching;

            this.setPlayerPosition(nextPosition, movementSeconds);

            setTimeout(() => {
              if (this.scenaryService.playerCanBeStand(this.playerPosition, this.currentScenaryMatrix)) {
                this.playerState = PlayerState.Standing;
              }
            }, movementSeconds * 1000);
          } else {
            this.soundService.playSoundError();
          }

          break;
        }
        case "creep": {
          if (this.scenaryService.playerCanCreep(this.playerPosition, this.currentScenaryMatrix)) {
            const nextPosition: CellPosition = {
              row: this.playerPosition.row,
              column: this.playerPosition.column + 1
            };
            const movementSeconds: number = 1;

            this.playerState = PlayerState.Creeping;

            this.setPlayerPosition(nextPosition, movementSeconds);

            setTimeout(() => {
              if (this.scenaryService.playerCanWalk(this.playerPosition, this.currentScenaryMatrix)) {
                this.playerState = PlayerState.Standing;
              } else if (this.scenaryService.playerCanCrouch(this.playerPosition, this.currentScenaryMatrix)) {
                this.playerState = PlayerState.Crouching;
              }
            }, movementSeconds * 1000);
          } else {
            this.soundService.playSoundError();
          }

          break;
        }
        case "jump": {
          if (this.scenaryService.playerCanJump(this.playerPosition, this.currentScenaryMatrix)) {
            const nextPosition: CellPosition = {
              row: this.playerPosition.row - 1,
              column: this.playerPosition.column + 1
            };
            const movementSeconds: number = 0.25;

            this.playerState = PlayerState.Jumping;

            this.setPlayerPosition(nextPosition, movementSeconds);

            setTimeout(() => {
              this.playerState = PlayerState.Standing;
            }, movementSeconds * 1000);
          } else {
            this.soundService.playSoundError();
          }

          break;
        }
      }

      if (this.goalService.isPlayerInGoal(this.playerPosition, this.currentScenaryGoalColumn)) {
        this.isPlayerOnGoal = true;

        setTimeout(() => {
          this.soundService.playSoundAcierto();
          this.playerState = PlayerState.Winning;
        }, 500);

        setTimeout(() => {
          this.soundService.playSoundFromDefinition(SoundDefinitions.TACHAN);
        }, 750);

        setTimeout(() => {
          this.playerState = PlayerState.Standing;
          this.nextRound();
        }, 2000);
      }

      this.soundService.playSoundClick();
    }
  }
}