<div class="game-view"></div>

<!-- CURTAINS  -->
<div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
  <img src="./assets/presentation/barra_puerta.png">
</div>
<div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
  <img src="./assets/presentation/barra_puerta.png">
</div>

<!-- UPPER BAR -->
<app-top-bar #topBar (clickBackButton)="OnClickedBackButton()" (clickInfoButton)="OnClickedInfoButton()"
  (timerOver)="OnTimerOver()">
</app-top-bar>

<!-- SCENE -->
<div #scene class="scene-container" [ngStyle]="{'transition': cameraService.getTransitionStyle(), 'left': cameraService.getHorizontalOffsetStyle()}">
  <!-- PLAYER -->
  <app-item-html #player [id]="player" class="player" [pathToAssets]="'./assets/games/06/player'" [imgName]="playerImageFilename">
  </app-item-html>

  <!-- LIGHT MASK -->
  <app-item-html #lightMask id="light-mask" class="light-mask" [pathToAssets]="'./assets/games/06'" [imgName]="'light-mask.png'">
  </app-item-html>

  <!-- SCENARY -->
  <div class="scenary-container">
    <div #scenaryRows *ngFor="let row of currentScenaryMatrix" class="scenary-row">
      <img *ngFor="let cell of row" [src]="getCellImagePath(cell)" class="scenary-cell" />
    </div>
  </div>
</div>

<!-- ACTIONS -->
<div class="actions-container">
  <img (click)="onActionButtonClicked(action.id)" *ngFor="let action of gameInfo.parameters.actions"
    [src]="getActionButtonImagePath(action.id)" class="action-button" [ngStyle]="{'opacity': isPlayerMoving ? 0.5 : 1}"/>
</div>

<!-- SUCCESS SCREEN -->
<app-success-view [gameInfo]="gameInfo"></app-success-view>

<!-- INFO SCREEN -->
<app-info-screen #infoScreen (infoScreenClosed)="OnInfoScreenClosed()" (retryScreenClosed)="OnRetryScreenClosed()">
</app-info-screen>