<div class="game-view">

  <!-- CURTAINS  -->
  <div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>
  <div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>

  <!-- BACKGROUND  -->
  <div class="background-image-container">
    <img class="background-image" [src]='getBackgroundPuzleClasico' />
  </div>
  <div *ngFor="let placeholder of puzzlePieces">
    <img class="puzzleClassicItemPlaceholder" *ngIf="placeholder.isAddedToPuzzle" [id]='placeholder.getIDPlaceholder'
      [src]='placeholder.getImgPathForPlaceholder' [style.top.px]='placeholder.getTopContainer'
      [style.left.px]='placeholder.getLeftContainer'>
  </div>

  <!-- CONTAINER  -->
  <div class="puzzleClassicItemContainer" cdkDropList (cdkDropListDropped)="drop($event)"
    *ngFor="let container of puzzlePieces" [id]='container.getIDContainer' [style.top.px]='container.getTopContainer'
    [style.left.px]='container.getLeftContainer' [style.width.px]='container.getWidthContaner'
    [style.height.px]='container.getHeightContainer'></div>

  <!-- DRAGGABLE  -->
  <div class="puzzleClassicFrameObjects">
    <div class="puzzle-piece-draggable" *ngFor="let draggable of puzzlePiecesShuffled" cdkDropList
      [cdkDropListConnectedTo]="draggable.getIDContainer" (cdkDropListDropped)="drop($event)"
      [cdkDropListData]="draggable">
      <img class="puzzleClassicItemDraggable1" [id]='draggable.getID' [src]='draggable.getImgPathForDraggable' cdkDrag
        [cdkDragDisabled]="draggable.isAddedToPuzzle">
    </div>
  </div>

  <!-- GIF -->
  <img class="animated-image" *ngIf="showGif" [src]='imgAnimatedToShow'>
</div>

<!-- UPPER BAR -->
<app-top-bar #topBar (clickBackButton)="OnClickedBackButton()" (clickInfoButton)="OnClickedInfoButton()"
  (timerOver)="OnTimerOver()">
</app-top-bar>

<div class="fadeContainer" [ngStyle]="{'opacity': styleOpacityForFadeContainer}"></div>

<!-- SUCCESS SCREEN -->
<app-success-view [gameInfo]="gameInfo"></app-success-view>

<!-- INFO SCREEN -->
<app-info-screen #infoScreen (infoScreenClosed)="OnInfoScreenClosed()" (retryScreenClosed)="OnRetryScreenClosed()">
</app-info-screen>