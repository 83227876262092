import { Component, OnInit, Input } from '@angular/core';

export enum tEaseType {
  LINEAL = "linear",
  EASE_IN = "ease-in",
  EASE_OUT = "ease-out",
  EASE_IN_BACK = "cubic-bezier(0.6, -0.28, 0.74, 0.05)",
  EASE_OUT_BACK = "cubic-bezier(0.18, 0.89, 0.32, 1.28)"
}

@Component({
  selector: 'app-item-html',
  templateUrl: './item-html.component.html',
  styleUrls: ['./item-html.component.css']
})
export class ItemHTMLComponent implements OnInit {
  @Input() id: string = "";
  @Input() pathToAssets: string = "";
  @Input() imgName: string = "";

  protected styleWidth: number = 0;
  protected styleLeft: number = 0;
  protected styleRight: number = 0;
  protected styleTop: number = 0;
  protected styleBottom: number = 0;
  protected styleTransition: string = "";
  protected styleOpacity: number = 1;
  protected styleTransform: string = "";
  protected className: string = "";
  protected arrayClassNames: string[] = [];
  protected scaleShowing = 1; //escala a la que mostramos el item

  constructor() { }

  ngOnInit() { }

  public SetImageName(_imgName: string) {
    this.imgName = _imgName;
  }

  public SetWidth(value: number) {
    this.styleWidth = value;
  }

  public SetOpacity(opacity: number, withTransition: boolean = false, timeTransition: number = 0) {
    if (withTransition) {
      this.styleTransition = "all " + timeTransition + "s linear";
    }
    else {
      this.styleTransition = "";
    }

    this.styleOpacity = opacity;
  }

  public SetRotation(degrees: number, withTransition: boolean = false, timeTransition: number = 0) {
    if (withTransition) {
      this.styleTransition = "transform " + timeTransition + "s ease-out";
    }
    else {
      this.styleTransition = "";
    }

    this.styleTransform = "rotate(" + degrees + "deg)";
  }

  public SetTopLeftPosition(top: number, left: number, withTransition: boolean = false, timeTransition: number = 0, ease: tEaseType = tEaseType.LINEAL) {
    if (withTransition) {
      this.styleTransition = "all " + timeTransition + "s " + ease;
    }
    else {
      this.styleTransition = "";
    }

    this.styleTop = top;
    this.styleLeft = left;
  }

  public SetBottomLeftPosition(bottom: number, left: number, withTransition: boolean = false, timeTransition: number = 0, ease: tEaseType = tEaseType.LINEAL) {
    if (withTransition) {
      this.styleTransition = "bottom " + timeTransition + "s " + ease + ", left " + timeTransition + "s " + ease;
    }
    else {
      this.styleTransition = "";
    }

    this.styleBottom = bottom;
    this.styleLeft = left;
  }

  public SetLeftPosition(left: number, withTransition: boolean = false, timeTransition: number = 0, ease: tEaseType = tEaseType.LINEAL) {
    this.styleTransition = withTransition ? timeTransition + "s " + ease : "";
    this.styleLeft = left;
  }

  public SetBottomPosition(bottom: number, withTransition: boolean = false, timeTransition: number = 0, ease: tEaseType = tEaseType.LINEAL) {
    this.styleTransition = withTransition ? timeTransition + "s " + ease : "";
    this.styleBottom = bottom;
  }

  public SetTopPosition(top: number, withTransition: boolean = false, timeTransition: number = 0, ease: tEaseType = tEaseType.LINEAL) {
    if (withTransition) {
      this.styleTransition = "top " + timeTransition + "s " + ease;
    }
    else {
      this.styleTransition = "";
    }

    this.styleTop = top;
  }

  public ScaleToEffect(scaleTo: number, scaleBack: number = 1, timeScaleTo: number = 0.15, timeScaleBack = 0.05) {
    this.styleTransition = "transform " + timeScaleTo + "s cubic-bezier(0.25, 0.25, 0.5, 1.9)";
    this.styleTransform = "scale(" + scaleTo + ")";

    setTimeout(() => {
      this.styleTransition = "transform " + timeScaleBack + "s linear";
      this.styleTransform = "scale(" + scaleBack + ")";
    }, timeScaleTo * 1000);

  }

  public SetScale(scaleX: number, scaleY: number) {
    this.styleTransform = "scale(" + scaleX + "," + scaleY + ")";
  }

  public ShowImageWithScaleEffect(imageName: string, scale = 1) {
    this.styleTransform = "scale(0)";
    this.imgName = imageName;
    this.styleTransition = "transform 0.3s cubic-bezier(0.25, 0.25, 0.5, 1.9)";
    setTimeout(() => {
      this.styleTransform = "scale(" + scale + ")";
    }, 100);

  }

  public HideWithScaleEffect(time: number = 0.1) {
    this.styleTransition = "transform " + time.toString() + "s linear";
    setTimeout(() => {
      this.styleTransform = "scale(0)";
    }, 100);
  }

  public ShowWithScaleEffect(time: number = 0.3, scale = 1) {
    this.styleTransform = "scale(0)";
    this.styleTransition = "transform " + time + "s cubic-bezier(0.25, 0.25, 0.5, 1.9)";
    setTimeout(() => {
      this.styleTransform = "scale(" + scale + ")";
    }, 50);
  }

  GetClassName(): string {
    return this.className;
  }

  GetStyleWidth(): string {
    return this.styleWidth ? `${this.styleWidth}px` : 'auto';
  }

  GetStyleLeft(): string {
    return this.styleLeft.toString() + "px";
  }

  GetStyleRight(): string {
    return this.styleRight.toString() + "px";
  }

  GetStyleTop(): string {
    return this.styleTop.toString() + "px";
  }

  GetStyleBottom(): string {
    return this.styleBottom.toString() + "px";
  }

  GetStyleTransition(): string {
    return this.styleTransition;
  }

  GetStyleOpacity(): string {
    return this.styleOpacity.toString();
  }

  GetStyleTransform(): string {
    return this.styleTransform;
  }

  GetPathToImage(): string {
    return this.pathToAssets + "/" + this.imgName;
  }
}
