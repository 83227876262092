import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NumberService {

  constructor() { }

  public getRandomFloat(max: number): number {
    const value = Math.floor(Math.random() * max);
    return value;
  }

  public getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public splitNumberInChars(n: number): number[] {
    const parts: number[] = [];
    const sNumber: string = n.toString();

    for (let i = 0; i < sNumber.length; i++) {
      parts.push(+sNumber.charAt(i));
    }

    return parts;
  }
}
