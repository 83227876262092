import { Injectable } from '@angular/core';

import GameInfo from './GameInfo';
import GAMES from './games.json';

@Injectable({
  providedIn: 'root'
})
export class GamesService {
  public arrayGameInfo: GameInfo[] = [];
  public currentLevel: number = 1;

  public loadGames() {
    this.arrayGameInfo = GAMES;
  }

  public getGameInfo(gameId: string): GameInfo {
    return this.arrayGameInfo.find(g => g.id === gameId);
  }
}
