import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PresentationViewComponent } from './modules/base/views/presentation-view/presentation-view.component';
import { GamesViewComponent } from './modules/base/views/games-view/games-view.component';
import { PuzzlesViewComponent } from './modules/puzzles/views/puzzles-view/puzzles-view.component';
import { ShapesViewComponent } from './modules/shapes/views/shapes-view/shapes-view.component';
import { FishermanViewComponent } from './modules/fisherman/views/fisherman-view/fisherman-view.component';
import { LabyrinthViewComponent } from './modules/labyrinth/views/labyrinth-view/labyrinth-view.component';
import { HowManyViewComponent } from './modules/how-many/views/how-many-view/how-many-view.component';
import { HorizontalTravelViewComponent } from './modules/horizontal-travel/views/horizontal-travel-view/horizontal-travel-view.component';

const routes: Routes = [
  { path: '', redirectTo: '/presentation', pathMatch: 'full' },
  { path: 'presentation', component: PresentationViewComponent },
  { path: 'games', component: GamesViewComponent },
  { path: 'puzzles', component: PuzzlesViewComponent },
  { path: 'shapes', component: ShapesViewComponent },
  { path: 'labyrinth', component: LabyrinthViewComponent },
  { path: 'fisherman', component: FishermanViewComponent },
  { path: 'how-many', component: HowManyViewComponent },
  { path: 'horizontal-travel', component: HorizontalTravelViewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
