export enum PlayerState {
    Standing,
    OnTheStairs,
    Crouching,
    Creeping,
    Jumping,
    Winning
}

export enum ScenaryCellType {
    Free = "00",
    Wall = "01",
    WallTop = "02",
    WallRight = "03",
    WallBottom = "04",
    WallLeft = "05",
    WallOuterCornerLeftTop = "06",
    WallOuterCornerLeftBottom = "07",
    WallOuterCornerRightTop = "08",
    WallOuterCornerRightBottom = "09",
    WallInnerCornerLeftTop = "10",
    WallInnerCornerLeftBottom = "11",
    WallInnerCornerRightTop = "12",
    WallInnerCornerRightBottom = "13",
    WallDoubleSideTop = "14",
    WallDoubleSideBottom = "15",
    WallRightAndLeftInnerCorner = "16",
    WallLeftAndRightInnerCorner = "17",
    StairsDown = "18",
    StairsUp = "19",
    Spikes = "20",
    Goal1 = "90",
    Goal2 = "91",
    Goal3 = "92",
    Goal4 = "93",
    Goal5 = "94",
    Goal6 = "95",
    Goal7 = "96",
    Goal8 = "97",
    Goal9 = "98"
}

export enum ScenaryCellImage {
    Free = "free",
    Wall = "wall",
    WallTop = "wall-top",
    WallRight = "wall-right",
    WallBottom = "wall-bottom",
    WallLeft = "wall-left",
    WallOuterCornerLeftTop = "wall-outer-corner-left-top",
    WallOuterCornerLeftBottom = "wall-outer-corner-left-bottom",
    WallOuterCornerRightTop = "wall-outer-corner-right-top",
    WallOuterCornerRightBottom = "wall-outer-corner-right-bottom",
    WallInnerCornerLeftTop = "wall-inner-corner-left-top",
    WallInnerCornerLeftBottom = "wall-inner-corner-left-bottom",
    WallInnerCornerRightTop = "wall-inner-corner-right-top",
    WallInnerCornerRightBottom = "wall-inner-corner-right-bottom",
    WallDoubleSideTop = "wall-double-side-top",
    WallDoubleSideBottom = "wall-double-side-bottom",
    WallRightAndLeftInnerCorner = "wall-right-and-left-inner-corner",
    WallLeftAndRightInnerCorner = "wall-left-and-right-inner-corner",
    StairsDown = "stairs",
    StairsUp = "stairs",
    Spikes = "spikes",
    Goal1 = "goal1",
    Goal2 = "goal2",
    Goal3 = "goal3",
    Goal4 = "goal4",
    Goal5 = "goal5",
    Goal6 = "goal6",
    Goal7 = "goal7",
    Goal8 = "goal8",
    Goal9 = "goal9"
}