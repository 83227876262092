import { CustomPoint, tPointOrigins } from "@/modules/base/models/customPoint";
import { tMovingDirection } from "@/modules/base/components/movable/movable.component";

export class FishInfo {
    id = "";
    pathToAssets = "";
    imgName = "";
    timeMoving = 0;
    initPoint = new CustomPoint(0, 0, tPointOrigins.BOTTOM_LEFT);
    offsetX = 0;
    offsetY = 0;
    direction = tMovingDirection.RIGHT;
}