import { Component, Injector, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { CustomPoint, tPointOrigins } from '@/modules/base/models/customPoint';
import { FishComponent } from '../../components/fish/fish.component';
import { FishInfo } from '../../components/fish/fish-info';
import { PescadorComponent } from '../../components/pescador/pescador.component';
import { SplashComponent } from '../../components/splash/splash.component';
import { tMovingDirection } from '@/modules/base/components/movable/movable.component';
import { BaseGameViewComponent } from '@/modules/base/views/base-game-view/base-game-view.component';
import { SuccessViewComponent } from '@/modules/base/components/menus/success-view/success-view.component';
import { NumberService } from '@/modules/base/services/number/number.service';

const kPathToAssets = "assets/games/04"
const kNumFishesByLevel = [null, 4, 6, 8];
const kImgNameAvailablesForFish = ["pescadito_azul.png", "pescadito_verde.png", "pescadito_rosa.png"]
const kRandomInitPositionXValues = [100, 1000];
const kRandomInitPositionYValues = [100, 300];
const kOffScreenRight = 1450;
const kOffScreenLeft = -200;
const kTimerByLevel: number[] = [null, 250, 250, 250];

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-fisherman-view',
  templateUrl: './fisherman-view.component.html',
  styleUrls: ['./fisherman-view.component.css']
})
export class FishermanViewComponent extends BaseGameViewComponent implements OnInit {
  @ViewChildren(FishComponent) arrayFishControllers: QueryList<FishComponent>;
  @ViewChild(PescadorComponent) private pescadorController: PescadorComponent;
  @ViewChild(SplashComponent) private splashController: SplashComponent;

  arrayFishesForTemplate: FishInfo[] = [];
  ciguenyaInitPointForTemplate: CustomPoint = new CustomPoint(700, 690);

  private numCatchedFishes = 0;

  constructor(
    injector: Injector,
    private numberService: NumberService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setGameInfo(this.gamesService.getGameInfo("04"));

    this.GenerateFishes();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit()

    //asignamos referencia al splash de agua a la cigueña
    setTimeout(() => {
      this.pescadorController.SetSplash(this.splashController);
    }, 50);
  }

  afterCloseInfo() {
    this.topBarController.StartTopBarTimer(kTimerByLevel[this.gamesService.currentLevel]);
  }

  setupRound() {
    this.arrayFishesForTemplate = [];

    //volvemos a generarlos
    this.GenerateFishes();

    //reset de contador
    this.numCatchedFishes = 0;
  }

  //Crea la info de los objetos peces que se generaran desde el template
  GenerateFishes() {
    let arrayBottomPositions: number[] = [];

    for (let i = 0; i < kNumFishesByLevel[this.gamesService.currentLevel]; i++) {

      let fish = new FishInfo();
      fish.id = "fish_" + i;
      fish.pathToAssets = kPathToAssets;
      fish.imgName = kImgNameAvailablesForFish[this.numberService.getRandomInt(0, kImgNameAvailablesForFish.length - 1)];

      //direccion de movimiento
      fish.direction = (this.numberService.getRandomInt(0, 1) == 0 ? tMovingDirection.LEFT : tMovingDirection.RIGHT);

      //generamos los peces fuera de la pantalla
      let leftPosition = (fish.direction == tMovingDirection.LEFT) ? kOffScreenRight : kOffScreenLeft;
      let bottomPosition = this.numberService.getRandomInt(kRandomInitPositionYValues[0], kRandomInitPositionYValues[1]);
      fish.initPoint = new CustomPoint(leftPosition, bottomPosition, tPointOrigins.BOTTOM_LEFT);

      //añadimos al array de posiciones usadas
      arrayBottomPositions.push(bottomPosition);

      //añadimos al array de peces que se leeran desde el template
      this.arrayFishesForTemplate.push(fish);
    }
  }

  OnFishClicked(fish: FishComponent) {
    this.pescadorController.CatchFish(fish);
  }

  OnCatchedFish(fish: FishComponent) {
    fish.SetAsCatched();

    //actualizamos contador
    this.numCatchedFishes++;

    //comprobamos fin de ronda
    if (this.numCatchedFishes == kNumFishesByLevel[this.gamesService.currentLevel]) {
      this.nextRound(2000, 1000, 2000);
    }
  }
}