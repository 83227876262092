import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

import { ItemHTML } from '../../models/itemHTML';
import { SoundService } from '@/modules/base/services/sound/sound.service';
import { SoundDefinitions } from "@/modules/base/services/sound/SoundDefinitions";

export enum tNavButton {
  UP = 0,
  DOWN = 1,
  LEFT = 2,
  RIGHT = 3
}

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  DOWN_ARROW = 40
}

@Component({
  selector: 'app-nav-buttons',
  templateUrl: './nav-buttons.component.html',
  styleUrls: ['./nav-buttons.component.css']
})
export class NavButtonsComponent implements OnInit {
  @Input() pathToAssets: string;
  @Input() UpButtonImageName: string;
  @Input() DownButtonImageName: string;
  @Input() LeftButtonImageName: string;
  @Input() RightButtonImageName: string;
  @Output() navButtonPressed = new EventEmitter<tNavButton>();

  /** Eventos de teclado */
  @HostListener('window:keyup', ['$event'])
  KeyUpEvent(event: KeyboardEvent) {

    //console.log(event);

    switch (event.keyCode) {
      case KEY_CODE.RIGHT_ARROW:
        this.ClickRightButton();
        break;
      case KEY_CODE.LEFT_ARROW:
        this.ClickLeftButton();
        break;
      case KEY_CODE.UP_ARROW:
        this.ClickUpButton();
        break;
      case KEY_CODE.DOWN_ARROW:
        this.ClickDownButton();
        break;
    }
  }

  public UpButton: ItemHTML;
  public DownButton: ItemHTML;
  public LeftButton: ItemHTML;
  public RightButton: ItemHTML;

  constructor(private soundService: SoundService) { }

  ngOnInit() {

    //Creamos los botones
    this.UpButton = new ItemHTML("NavButtonUp", this.pathToAssets, this.UpButtonImageName);
    this.DownButton = new ItemHTML("NavButtonDown", this.pathToAssets, this.DownButtonImageName);
    this.LeftButton = new ItemHTML("NavButtonLeft", this.pathToAssets, this.LeftButtonImageName);
    this.RightButton = new ItemHTML("NavButtonRight", this.pathToAssets, this.RightButtonImageName);
  }

  ClickUpButton() {

    this.soundService.playSoundFromDefinition(SoundDefinitions.CLICK);
    this.navButtonPressed.emit(tNavButton.UP);

  }

  ClickDownButton() {

    this.soundService.playSoundFromDefinition(SoundDefinitions.CLICK)
    this.navButtonPressed.emit(tNavButton.DOWN);
  }

  ClickLeftButton() {

    this.soundService.playSoundFromDefinition(SoundDefinitions.CLICK)
    this.navButtonPressed.emit(tNavButton.LEFT);
  }

  ClickRightButton() {

    this.soundService.playSoundFromDefinition(SoundDefinitions.CLICK)
    this.navButtonPressed.emit(tNavButton.RIGHT);
  }

}
