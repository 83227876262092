import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseModule } from '../base/base.module';
import { LabyrinthViewComponent } from './views/labyrinth-view/labyrinth-view.component';
import { EnemyComponent } from './components/enemy/enemy.component';
import { PlayerComponent } from './components/player/player.component';
import { WalkableCellClickableComponent } from './components/walkable-cell-clickable/walkable-cell-clickable.component';
import { WalkableCellDisapearingItemComponent } from './components/walkable-cell-disapearing-item/walkable-cell-disapearing-item.component';
import { WalkableCellItemComponent } from './components/walkable-cell-item/walkable-cell-item.component';
import { LabyrinthService } from './services/labyrinth.service';

@NgModule({
  declarations: [
    LabyrinthViewComponent, 
    EnemyComponent, 
    PlayerComponent, 
    WalkableCellClickableComponent, 
    WalkableCellDisapearingItemComponent, 
    WalkableCellItemComponent
  ],
  imports: [
    CommonModule,
    BaseModule
  ],
  providers: [LabyrinthService],
  exports: [LabyrinthViewComponent]
})
export class LabyrinthModule { }
