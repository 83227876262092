export class WalkableCellMatrix {

    private matrix:WalkableCell[][] = [];
    private cellHeight:number;
    private cellWidth:number;
    private playerCell:WalkableCell;
   
    constructor(_matrix:tWalkableCellType[][])
    {
        for (let x = 0; x < _matrix.length; x++) {
            
            this.matrix[x] = [];
            
            for (let y = 0; y < _matrix[x].length; y++) {
                
                this.matrix[x][y]= new WalkableCell(_matrix[x][y], new WalkableCellPosition(x, y));
            }
        }

        //TEST
        // for (let x = 0; x < this.matrix.length; x++) {
            
        //     for (let y = 0; y < this.matrix[x].length; y++) {
                
        //         // console.log("matrix[" + x + ", " + y + "]: " + this.matrix[x][y].GetType() );
        //         // console.log("cell X: " + this.matrix[x][y].GetPositionInMatrix().row);
        //         // console.log("cell Y: " + this.matrix[x][y].GetPositionInMatrix().column);
        //     }
        // }
    }

    public GetCellsMatrix():WalkableCell[][]
    {
        return this.matrix;
    }

    public SetCellHeight(height:number)
    {
        this.cellHeight = height;
    }

    public GetCellHeight():number
    {
        return this.cellHeight;
    }

    public SetCellWidth(width:number)
    {
        this.cellWidth = width;
    }

    public GetCellWidth():number
    {
        return this.cellWidth;
    }

    public GetNumRows():number
    {
        return this.matrix.length;
    }

    public GetNumColumns():number
    {
        return this.matrix[0].length;
    }

    public SetPlayerCell(cell:WalkableCell)
    {
        this.playerCell = cell;
    }

    public GetPlayerCell():WalkableCell
    {
        return this.playerCell;
    }

    GetCellByPosition(position: WalkableCellPosition): WalkableCell
    {
        let cellResult: WalkableCell = null;
        
        if(this.matrix.length > position.row && position.row >= 0)
        {
            if(this.matrix[position.row].length > position.column && position.column >= 0)
            {
                cellResult = this.matrix[position.row][position.column];
            }
        }

        return cellResult;
    }
}

export class WalkableCell
{
    private type:tWalkableCellType = tWalkableCellType.NON_WALKABLE;
    private position:WalkableCellPosition = new WalkableCellPosition(0,0);
    
    constructor(_type:tWalkableCellType, _position:WalkableCellPosition)
    {
        this.type = _type;
        this.position = _position;
    }

    public GetType():tWalkableCellType
    {
        return this.type;
    }

    public GetPositionInMatrix():WalkableCellPosition
    {
        return this.position;
    }

    public IsWalkable():boolean
    {
        
        return (this.type != tWalkableCellType.NON_WALKABLE && this.type != tWalkableCellType.OTHERS_NON_WALKABLE && this.type != tWalkableCellType.EXIT_NON_WALKABLE);
    }

    public IsExit():boolean
    {
        return this.type == tWalkableCellType.EXIT;
    }

    public IsCollectable():boolean
    {
        return this.type == tWalkableCellType.COLLECTABLE;
    }

    public IsEnemy():boolean
    {
        return this.type == tWalkableCellType.ENEMY || this.type == tWalkableCellType.ENEMY2;
    }

    public SetType(_type:tWalkableCellType)
    {
        this.type = _type;
    }
}

export enum tWalkableCellType
{
    NON_WALKABLE = 0,
    WALKABLE = 1,
    PLAYER = 2,
    EXIT = 3,
    COLLECTABLE = 4,
    ENEMY = 5,
    ENEMY2 = 6,
    EXIT_NON_WALKABLE = 7,
    OTHERS_WALKABLE = 8,
    OTHERS_NON_WALKABLE = 9
}

export class WalkableCellPosition
{
    row:number;
    column:number;

    constructor(_row:number, _column:number)
    {
        this.row = _row;
        this.column = _column;
    }
}

