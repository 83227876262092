import { Injectable } from '@angular/core';
import { Sound } from './sound';
import { SoundDefinitions } from "@/modules/base/services/sound/SoundDefinitions";

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private audiosURL = "./assets/sounds/";
  private audiosPlaying: HTMLAudioElement[] = [];

  constructor() { }

  playSoundFromFileName(fileName: string, loop: boolean = false, volume = 1) {
    let sound = new Sound(fileName, loop, volume);
    this.play(sound);
  }

  playSoundFromDefinition(soundDefinition: SoundDefinitions, loop = false, volume = 1) {
    let sound = new Sound(soundDefinition, loop, volume);

    this.play(sound);
  }

  private play(sound: Sound) {
    let audio = new Audio();
    audio.src = this.audiosURL + sound.getSoundFileName();
    audio.load();
    audio.loop = sound.hasLoop();
    audio.volume = sound.getSoundVolume();
    audio.id = sound.getSoundFileName();
    audio.play();

    this.audiosPlaying.push(audio);

    //escuchamos fin de audio
    audio.addEventListener('ended', (event) => {

      //sacamos el audio de la lista de audios
      this.audiosPlaying.splice(this.audiosPlaying.indexOf(audio), 1);

      audio.remove;
    });

  }

  stopSoundFromDefinition(soundDefinition: SoundDefinitions) {
    let audioToStop = null;
    this.audiosPlaying.forEach(audio => {
      if (audio.id == soundDefinition) {
        this.stop(audio);
        audioToStop = audio;
      }
    });

    if (audioToStop) {
      this.removeAudio(audioToStop);
    }
  }

  stopSoundFromFileName(fileName: string) {
    let audioToStop = null;
    this.audiosPlaying.forEach(audio => {
      if (audio.id == fileName) {
        this.stop(audio);
        audioToStop = audio;
      }
    });

    if (audioToStop) {
      this.removeAudio(audioToStop);
    }
  }

  stopAllSounds() {
    this.audiosPlaying.forEach(audio => {
      this.stop(audio);
      audio.remove;
    });

    this.audiosPlaying.splice(0, this.audiosPlaying.length);
  }

  private removeAudio(audio: HTMLAudioElement) {
    this.audiosPlaying.splice(this.audiosPlaying.indexOf(audio), 1);
    audio.remove;
  }

  private stop(audio: HTMLAudioElement) {
    audio.pause();
    audio.currentTime = 0;
  }

  playSoundBackground() {
    this.playSoundFromDefinition(SoundDefinitions.BACKGROUND, true, 0.4);
  }

  playSoundClick() {
    this.playSoundFromDefinition(SoundDefinitions.CLICK);
  }

  playSoundAcierto() {
    this.playSoundFromDefinition(SoundDefinitions.ACIERTO);
  }

  playSoundError() {
    this.playSoundFromDefinition(SoundDefinitions.ERROR);
  }
}
