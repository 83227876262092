import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseGameViewComponent } from './views/base-game-view/base-game-view.component';
import { InfoScreenComponent } from './components/menus/info-screen/info-screen.component';
import { ItemHTMLComponent } from './components/item-html/item-html.component';
import { GamesViewComponent } from './views/games-view/games-view.component';
import { MovableComponent } from './components/movable/movable.component';
import { SuccessViewComponent } from './components/menus/success-view/success-view.component';
import { PresentationViewComponent } from './views/presentation-view/presentation-view.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { JuegosButtonComponent } from './components/juegos-button/juegos-button.component';
import { NavButtonsComponent } from './components/nav-buttons/nav-buttons.component';

@NgModule({
  declarations: [
    BaseGameViewComponent,
    InfoScreenComponent,
    ItemHTMLComponent,
    GamesViewComponent,
    MovableComponent,
    SuccessViewComponent,
    PresentationViewComponent,
    TopBarComponent,
    JuegosButtonComponent,
    NavButtonsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BaseGameViewComponent,
    PresentationViewComponent,
    GamesViewComponent,
    TopBarComponent,
    SuccessViewComponent,
    InfoScreenComponent,
    MovableComponent,
    ItemHTMLComponent,
    NavButtonsComponent
  ]
})

export class BaseModule { }
