import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ItemHTMLComponent } from '@/modules/base/components/item-html/item-html.component';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css']
})
export class SplashComponent extends ItemHTMLComponent implements OnInit, AfterViewInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.SetOpacity(0);
      this.styleBottom = 445;
    }, 10);
  }

  ShowAtLeftPosition(left: number) {
    this.SetBottomLeftPosition(this.styleBottom, left);

    this.SetOpacity(1);

    setTimeout(() => {
      this.SetOpacity(0);
    }, 0.4 * 1000);
  }
}