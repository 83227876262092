import { ItemHTML } from "@/modules/base/models/itemHTML";
import { WalkableCellPosition, WalkableCell } from "./walkable-cell-matrix";

export class WalkableCellItem extends ItemHTML
{   

    cell: WalkableCell;

    constructor(_id: string, _pathToAssets: string, _imgName: string, _walkableCell: WalkableCell)
    {
        super(_id, _pathToAssets, _imgName);

        this.cell = _walkableCell;
    }

    public IsItemAtPosition(_cellPosition:WalkableCellPosition):boolean
    {

        return(this.cell.GetPositionInMatrix().row == _cellPosition.row && this.cell.GetPositionInMatrix().column == _cellPosition.column);
    }
}
