import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FishermanViewComponent } from './views/fisherman-view/fisherman-view.component';
import { FishComponent } from './components/fish/fish.component';
import { PescadorComponent } from './components/pescador/pescador.component';
import { SplashComponent } from './components/splash/splash.component';
import { BaseModule } from '../base/base.module';

@NgModule({
  declarations: [
    FishermanViewComponent, 
    FishComponent, 
    PescadorComponent, 
    SplashComponent],
  imports: [
    CommonModule, 
    BaseModule
  ],
  exports : [FishermanViewComponent]
})
export class FishermanModule { }