import { WalkableCellMatrix, tWalkableCellType, WalkableCell } from "./walkable-cell-matrix";

export class WalkableCellDisapearingItemInfo {
    public id: string = "";
    public pathToAssets: string = "";
    public imgName: string = "";
    public walkableCellMatrix: WalkableCellMatrix = null;
    public walkableCell: WalkableCell;
    public cellType: tWalkableCellType;
    public showFromStart: boolean = false;
    public timeShowingInterval: number[] = [];
    public timeWaitingToShowInterval: number[] = [];
}
