<div class="game-view"></div>
   
<!-- BACKGROUND  -->
<img style ="position: absolute; top: 0px; pointer-events: none" src="./assets/games/02/fondo.png">

<!-- CURTAINS  -->
<div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
  <img src="./assets/presentation/barra_puerta.png">
</div>
<div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
  <img src="./assets/presentation/barra_puerta.png">
</div>

<!-- CONTAINERS -->
<div class="frameContainers">
    <div class="container_placeholder" 
        *ngFor="let container of arrayContainersForTemplate; index as i"
        [ngStyle]="{'transform': container.GetTransform(), 'transition': container.GetTransition()}">
        <img class="container_standard" cdkDropList (cdkDropListDropped)="Drop($event)" [id]='container.id' [src]='container.GetImagePathForItem'> 
    </div>
</div>

<!-- DRAGGABLES  -->
<div id="arrastables" class="frameDraggables">
  <div class="draggable_placeholder" 
    *ngFor="let draggable of arrayDraggablesForTemplate"
    [ngStyle]="{'transform': draggable.GetTransform(), 'transition': draggable.GetTransition()}"
    cdkDropList
    [cdkDropListData]="draggable"
    [cdkDropListConnectedTo]="draggable.idContainer"
    (cdkDropListDropped)="Drop($event)"
    [id]='draggable.id'>
    <img class="draggable_standard" [id]='draggable.id' [src]='draggable.GetImagePathForItem' cdkDrag [cdkDragDisabled]="draggable.disabled">
  </div>
</div>

<!-- UPPER BAR --> 
<app-top-bar #topBar
  (clickBackButton)="OnClickedBackButton()" 
  (clickInfoButton)="OnClickedInfoButton()"
  (timerOver)="OnTimerOver()">
</app-top-bar>

<!-- SUCCESS SCREEN --> 
<app-success-view [gameInfo]="gameInfo"></app-success-view>

<!-- INFO SCREEN --> 
<app-info-screen #infoScreen 
    (infoScreenClosed)="OnInfoScreenClosed()"
    (retryScreenClosed)="OnRetryScreenClosed()">
</app-info-screen>
  

  
  


