<div id="frameExito"
  style="background-color:rgba(255,255,255); top:0px; width: 100%; height:100%; position: absolute; z-index: 99; overflow: hidden"
  hidden>

  <!-- CURTAINS  -->
  <div class="doorLeft" id="doorLeft"><img style="float: right" src="./assets/presentation/barra_puerta.png" /></div>
  <div class="doorRight" id="doorRight"><img src="./assets/presentation/barra_puerta.png" /></div>

  <img style="position: absolute" src="./assets/success/fondo_exito_4.png">
  <img class="botonPulsado noSelectable" src="./assets/ui/btn_atras_exito.png"
    style="position:absolute; top: 17px;left:18px; cursor: pointer" (click)="goBack()" />
  <img class="botonPulsado noSelectable" src="./assets/ui/btn_repetir_exito.png"
    style="position:absolute; top: 17px;left: 97px; cursor: pointer" (click)="replay()" />
  <img src="" style="position:absolute;top: 110px;left: 505px; width: 308px; height: 327px" id="sticker" />
  <div class="btnDownloadSticker noSelectable" (click)="downloadSticker()">{{GetDescargarTranslation()}}</div>
  <img id="doctoraWellsPremio" src="./assets/success/exito/Capa-1.png"
    style="position: absolute; top: 413px; left: 162px">
  <img src="./assets/success/estrella_brillo.gif" style="position:absolute;top: 533px;left: 433px"
    id="estrellas_exito" />
  <div id="preloadFrameExito" hidden></div>
</div>