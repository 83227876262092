import { Component, OnInit, AfterViewInit, Injector } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import { PuzzleData } from '../../models/PuzzleData';
import { BaseGameViewComponent } from '@/modules/base/views/base-game-view/base-game-view.component';
import { SuccessViewComponent } from '@/modules/base/components/menus/success-view/success-view.component';
import { ArrayService } from '@/modules/base/services/array/array.service';
import { SoundDefinitions } from '@/modules/base/services/sound/SoundDefinitions';

const PIECES_FOLDER_PATH = './assets/games/01/pieces';
const ANIMATED_FOLDER_PATH = './assets/games/01/animated';
const BACKGROUNDS_FOLDER_PATH = './assets/games/01/backgrounds';

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-puzzles-view',
  templateUrl: './puzzles-view.component.html',
  styleUrls: ['./puzzles-view.component.css']
})
export class PuzzlesViewComponent extends BaseGameViewComponent implements OnInit, AfterViewInit {
  public styleOpacityForFadeContainer: string = "0%";
  public puzzlePieces: PuzzleData[] = [];
  public puzzlePiecesShuffled: PuzzleData[] = [];
  public idContainers = [[['contenedor11', 'contenedor12', 'contenedor13', 'contenedor14'], ['contenedor21', 'contenedor21', 'contenedor23', 'contenedor23'], ['contenedor31', 'contenedor32', 'contenedor33', 'contenedor34']],
  [['contenedor11', 'contenedor12', 'contenedor13', 'contenedor14', 'contenedor15'], ['contenedor21', 'contenedor22', 'contenedor23', 'contenedor24', 'contenedor25'], ['contenedor31', 'contenedor32', 'contenedor33', 'contenedor34', 'contenedor35']],
  [['contenedor11', 'contenedor12', 'contenedor13', 'contenedor14', 'contenedor15', 'contenedor16'], ['contenedor21', 'contenedor22', 'contenedor23', 'contenedor24', 'contenedor25', 'contenedor26'], ['contenedor31', 'contenedor32', 'contenedor33', 'contenedor34', 'contenedor35', 'contenedor36']]];
  public arrayTop = [[[95, 95, 319, 319], [95, 95, 95, 95], [95, 95, 319, 319]],
  [[95, 95, 95, 394, 394], [95, 95, 319, 319, 206], [95, 95, 318, 205, 318]],
  [[95, 95, 95, 319, 319, 319], [95, 95, 95, 395, 244, 244], [95, 95, 319, 319, 207, 207]]];
  public arrayLeft = [[[320, 684, 320, 684], [320, 320, 682, 682], [320, 502, 320, 502]],
  [[320, 562, 805, 321, 684], [319, 682, 319, 682, 501], [320, 683, 320, 502, 683]],
  [[320, 562, 804, 320, 562, 804], [320, 502, 684, 320, 684, 866], [320, 683, 320, 683, 864, 320]]];
  public arrayWidth = [[[364, 364, 364, 364], [368, 0, 368, 0], [364, 364, 364, 364]],
  [[242, 242, 242, 363, 363], [364, 364, 364, 364, 369], [363, 363, 363, 363, 364]],
  [[242, 242, 242, 242, 242, 242], [182, 182, 363, 363, 182, 182], [363, 363, 363, 363, 182, 182]]];
  public arrayHeight = [[[225, 225, 225, 225], [458, 0, 458, 0], [225, 225, 225, 225]],
  [[298, 298, 298, 149, 149], [224, 224, 224, 224, 232], [224, 224, 224, 225, 224]],
  [[224, 224, 224, 224, 224, 224], [280, 280, 168, 168, 280, 280], [224, 224, 224, 224, 225, 225]]];
  public showGif = false;
  public imgAnimatedToShow: string;
  public placedPieces: number;

  constructor(injector: Injector, private arrayService: ArrayService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setGameInfo(this.gamesService.getGameInfo("01"));

    this.setupRound();

    this.setPathForAnimatedImageName();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  afterCloseInfo() { }

  get getBackgroundPuzleClasico(): string {
    return BACKGROUNDS_FOLDER_PATH  + '/' + (this.exito + 1) + '_fondo_puzle.png';
  }

  setPathForAnimatedImageName() {
    this.imgAnimatedToShow = `${ANIMATED_FOLDER_PATH}/prehistoria_puzles_anim${this.exito + 1}.gif`;
  }

  createPuzzlePieces() {
    this.puzzlePieces = [];
    this.puzzlePiecesShuffled = [];

    for (let index = 0; index < this.gameInfo.parameters.piecesByLevel[this.gamesService.currentLevel - 1]; index++) {
      let newPiece = new PuzzleData(
        'id_pc_piece_' + index,
        `${PIECES_FOLDER_PATH}/${this.gamesService.currentLevel}` + '/' + (this.exito + 1) + '_pieza' + (index + 1) + '.png',
        `${PIECES_FOLDER_PATH}/${this.gamesService.currentLevel}` + '/' + (this.exito + 1) + '_pieza' + (index + 1) + '_S.png',
        this.arrayTop[this.gamesService.currentLevel - 1][this.exito][index], this.arrayLeft[this.gamesService.currentLevel - 1][this.exito][index],
        this.arrayWidth[this.gamesService.currentLevel - 1][this.exito][index],
        this.arrayHeight[this.gamesService.currentLevel - 1][this.exito][index],
        this.gamesService.currentLevel, this.idContainers[this.gamesService.currentLevel - 1][this.exito][index]
      );

      this.puzzlePieces.push(newPiece);
    }

    this.puzzlePieces.forEach(element => {
      this.puzzlePiecesShuffled.push(element)
    });
    this.puzzlePiecesShuffled = this.arrayService.shuffle(this.puzzlePiecesShuffled);
  }

  setupRound() {
    this.showGif = false;
    this.placedPieces = 0;

    this.setPathForAnimatedImageName();

    this.createPuzzlePieces();
  }

  drop(event: CdkDragDrop<PuzzleData>) {
    if (event.isPointerOverContainer && event.previousContainer.id != event.container.id) {
      this.soundService.playSoundAcierto();
      event.previousContainer.data.addToPuzzle();
      this.placedPieces++;
      if (this.placedPieces === this.puzzlePieces.length) {
        this.showGif = true;
        this.soundService.playSoundFromDefinition(SoundDefinitions.CHEERS);

        this.nextRound(2500, 500, 2500);
      }
    } else {
      this.soundService.playSoundError();
    }
  }
}