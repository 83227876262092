import { NumberService } from '@/modules/base/services/number/number.service';
import { Component, OnInit } from '@angular/core';
import { WalkableCellDisapearingItemComponent } from '../walkable-cell-disapearing-item/walkable-cell-disapearing-item.component';

const kScale = 0.5;
@Component({
  selector: 'app-enemy',
  templateUrl: './enemy.component.html',
  styleUrls: ['./enemy.component.css']
})
export class EnemyComponent extends WalkableCellDisapearingItemComponent implements OnInit {
  constructor(numberService: NumberService) {
    super(numberService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /** Override del metodo padre para corregir la posicion del enemigo. Es un gif grande y por tanto descuadra con el resto de items de las celdas */
  SetCurrentCell() {
    super.SetCurrentCell();
  }
}
