import { Injectable } from '@angular/core';

@Injectable()
export class LabyrinthService {
  public arrayImgNameBackgroundsForRound: string[] = ["laberinto_1.png", "laberinto_2.png", "laberinto_3.png"];

  private walkableMatrix: number[][][] = [
    [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 2, 0, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1],
      [0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1],
      [0, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 0],
      [0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 9, 7, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ],

    [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 2, 0, 0, 1, 0, 1, 0, 1, 1, 1, 0, 1, 1],
      [0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 1, 0],
      [0, 0, 0, 1, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
      [0, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0],
      [0, 1, 0, 1, 0, 0, 0, 0, 1, 1, 1, 9, 7, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ],

    [
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 2, 0, 1, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0],
      [0, 1, 0, 1, 1, 1, 0, 1, 1, 1, 1, 0, 1, 1],
      [0, 1, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0],
      [0, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 0, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0],
      [0, 1, 1, 1, 1, 0, 1, 1, 1, 0, 1, 9, 7, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    ]
  ];

  public getWalkableMatrixForRound(round: number): number[][] {
    return this.walkableMatrix[round];
  }
}