import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { MovableComponent, tMovingDirection } from '@/modules/base/components/movable/movable.component';
import { WalkableCellPosition, WalkableCellMatrix, WalkableCell, tWalkableCellType } from '../../walkable-cell-matrix';
import { CustomPoint } from '@/modules/base/models/customPoint';
import { AStarFinder } from "astar-typescript";

export enum tPlayerStatus {
  STANDING = 0,
  MOVING = 1
}

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent extends MovableComponent implements OnInit {
  @Input() characterId: string;
  @Input() walkableMatrix: WalkableCellMatrix;
  @Output() reachedGoal = new EventEmitter<PlayerComponent>();
  @Output() reachedCollectable = new EventEmitter<WalkableCell>();
  @Output() reachedEnemy = new EventEmitter<WalkableCell>();
  @Output() reachedDestination = new EventEmitter<WalkableCell>();

  private currentCellPosition: WalkableCellPosition;
  private currentCell: WalkableCell;
  private disabled: boolean = false;
  private withTorch: boolean = false;
  public styleScale: string = "";
  private timerForShowingStandingImage;
  private pathFindingMatrix: number[][] = [];
  private pathFinding: AStarFinder;
  private arrayCellsToTarget: WalkableCell[] = [];
  private status: tPlayerStatus = tPlayerStatus.STANDING;
  private cellOrigin: WalkableCell;  //Cuando estamos moviendo, celda desde la que partimos
  private cellTarget: WalkableCell;  //cuando estamos moviendo, celda a la que vamos
  private cellTypeBeforePlayerArrived: tWalkableCellType; //El tipo de celda en el que estamos

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    //Matrix para algoritmo pathfinding
    this.GenerateMatrixForPathFinding();

    //Calulamos cuadricula de la matriz donde esta el atleta a partir de la posicion que ocupa en pantalla
    this.InitCellPosition();
  }

  InitCellPosition() {
    //Calculo de la fila
    let row = (this.walkableMatrix.GetNumRows() - 1) - Math.floor(this.currentPosition.GetY() / this.walkableMatrix.GetCellHeight());

    //calculo de la columna
    let column = Math.floor(this.currentPosition.GetX() / this.walkableMatrix.GetCellWidth());

    //inicializamos celda en la la matriz
    this.currentCellPosition = new WalkableCellPosition(row, column);
    this.currentCell = this.walkableMatrix.GetCellByPosition(this.currentCellPosition);

    //Marcamos celda del player en la matriz
    this.walkableMatrix.SetPlayerCell(this.currentCell);

    this.ShowAsStanding();
  }

  //Genera una matriz a partir de las celdas actuales para el algoritmo de pathfinding
  GenerateMatrixForPathFinding() {
    const cellMatrix = this.walkableMatrix.GetCellsMatrix();
    let iteratingCell: WalkableCell = null;

    //Recorremos la matriz y generamos una nueva matriz que necesita el algoritmo de pathfinding
    //Esta matriz tiene 0 en las celdas que son pisables y 1 en las que no 
    for (let row = 0; row < cellMatrix.length; row++) {

      this.pathFindingMatrix[row] = [];

      for (let column = 0; column < cellMatrix[row].length; column++) {

        iteratingCell = cellMatrix[row][column];

        this.pathFindingMatrix[row].push(iteratingCell.IsWalkable() ? 0 : 1);
      }
    }
  }

  public RegenerateMatrixForPathFinding(walkableCellMatrix: WalkableCellMatrix) {
    this.walkableMatrix = walkableCellMatrix;

    this.GenerateMatrixForPathFinding();
  }

  public SetInitPosition(_initPosition: CustomPoint) {
    this.initPoint = _initPosition;

    this.ResetPosition();
  }

  public ResetPosition() {
    this.styleTransition = "";
    this.currentPosition = new CustomPoint(this.initPoint.GetX(), this.initPoint.GetY());

    this.UpdatePosition();

    //Calulamos cuadricula de la matriz donde esta el player a partir de la posicion que ocupa en pantalla
    this.InitCellPosition();

    //Mirando hacia la derecha
    this.SetFacingDirection(tMovingDirection.RIGHT);
  }


  public MoveToDirection(direction: tMovingDirection) {
    if (this.disabled) {
      console.log("Disabled!!!");
      return;
    }

    //Averiguamos casilla de destino
    let nextCell = this.walkableMatrix.GetCellByPosition(this.GetNextCellPositionByDirection(direction))

    //Comprobamos si es un enemigo para no dejar movernos
    if (nextCell && nextCell.IsEnemy()) {

      //console.log("Hemos encontrado un enemigo!!!");

      //colocamos al item mirando hacia la direccion en la que vamos a mover
      this.SetFacingDirection(direction);

      //informamos de que hemos intentado mover a una casilla con enemigo
      this.reachedEnemy.emit(this.walkableMatrix.GetCellByPosition(this.GetNextCellPositionByDirection(direction)));

      //desacticamos player
      this.disabled = true;

      //lo mostramos parado
      this.ShowAsStanding();

      //Descartamos el camino
      this.arrayCellsToTarget.splice(0, this.arrayCellsToTarget.length);

      return;
    }

    if (this.CanMoveToDirection(direction)) {

      //colocamos al item mirando hacia la direccion en la que vamos a mover
      this.SetFacingDirection(direction);

      //actualizamos direccion de movimiento y hacia donde miramos
      this.SetFacingDirection(direction);
      this.SetMovingDirection(direction);

      //guardamos celda de origen y celda de destino
      this.UpdateCellPositionByDirection(direction);
      this.cellOrigin = this.currentCell;
      this.cellTarget = nextCell;

      //Guardamos celda de destino del player en la matriz
      this.walkableMatrix.SetPlayerCell(this.cellTarget);

      //Imagen animada
      this.ShowAsRunning();

      //metodo padre
      super.MoveToDirection(direction, this.timeMoving);

      //desactivamos exploradora para impedir dobles pulsaciones y lo volvemos a activar cuando acaba de moverse
      //desactivamos exploradora sino estamos moviendo a lo largo de un camino
      if (this.arrayCellsToTarget == null || this.arrayCellsToTarget.length == 0) {
        this.disabled = true;
      }
    }
    else {
      //console.log("No puedo mover en esa direccion: " + direction.toString());

      //Si estabamos moviendonos por un camino cancelamos el camino
      if (this.arrayCellsToTarget.length > 0) {
        this.arrayCellsToTarget.splice(0, this.arrayCellsToTarget.length);
      }

      //Si estamos en movimiento, nos paramos
      if (this.status == tPlayerStatus.MOVING) {
        this.ShowAsStanding();
      }
    }
  }

  /** Mueve el player a la celda pasada */
  public MoveToCell(cell: WalkableCell) {
    if (this.arrayCellsToTarget == null || this.arrayCellsToTarget.length == 0) {

      //generamos camino de celdas
      this.SetPathToCell(cell);

      //primer movimiento
      this.MoveToNextCellInPath();
    }
    else {
      this.SetPathToCell(cell);
    }
  }

  /** Mueve el player a la siguiente celda disponible en el array de celdas para seguir hasta llegar a destino */
  private MoveToNextCellInPath() {
    const newCellTarget = this.arrayCellsToTarget[0];

    this.MoveToDirection(this.GetDirectionToCell(newCellTarget));
  }

  /** Genera una lista de celdas que son el camino a seguir para llegar a la celda pasada */
  SetPathToCell(targetCell: WalkableCell) {
    //Alimentamos el algoritmo de pathfinder con la matriz
    this.pathFinding = new AStarFinder({
      grid: {
        matrix: this.pathFindingMatrix
      },
      diagonalAllowed: false,
      includeStartNode: false
    });

    //Establecemos puntos de inicio y de destino para el algoritmo de pathfinding
    let startPos = { x: this.currentCell.GetPositionInMatrix().column, y: this.currentCell.GetPositionInMatrix().row };

    //Si estamos en movimiento calculamos el punto de salida a partir del punto al que nos estamos moviendp
    if (this.status == tPlayerStatus.MOVING) {
      startPos = { x: this.cellTarget.GetPositionInMatrix().column, y: this.cellTarget.GetPositionInMatrix().row };
    }
    let goalPos = { x: targetCell.GetPositionInMatrix().column, y: targetCell.GetPositionInMatrix().row };
    let myPathway = this.pathFinding.findPath(startPos, goalPos);

    //Borramos el camino actual
    this.arrayCellsToTarget.splice(0, this.arrayCellsToTarget.length);

    //Generamos un array de celdas a partir de los puntos generados por el algoritmo de pathfinding
    let currentCell: WalkableCell = null;
    for (let i = 0; i < myPathway.length; i++) {

      currentCell = this.walkableMatrix.GetCellByPosition(new WalkableCellPosition(myPathway[i][1], myPathway[i][0]));
      //console.log("Celda para camino: " + currentCell.GetPositionInMatrix().column + ", " + currentCell.GetPositionInMatrix().row);

      //Agregamos celda al nuevo camino
      this.arrayCellsToTarget.push(currentCell);
    }

  }

  /** Devuelve la direccion en la que esta la celda pasadas respecto a la celda actual */
  GetDirectionToCell(targetCell: WalkableCell): tMovingDirection {
    //console.log("Buscado direccion para celda: " + targetCell.GetPositionInMatrix().row);
    let direction = tMovingDirection.RIGHT;

    if (targetCell.GetPositionInMatrix().column < this.currentCell.GetPositionInMatrix().column) {
      direction = tMovingDirection.LEFT;
    }
    if (targetCell.GetPositionInMatrix().column > this.currentCell.GetPositionInMatrix().column) {
      direction = tMovingDirection.RIGHT;
    }

    if (targetCell.GetPositionInMatrix().row < this.currentCell.GetPositionInMatrix().row) {
      direction = tMovingDirection.UP;
    }
    if (targetCell.GetPositionInMatrix().row > this.currentCell.GetPositionInMatrix().row) {
      direction = tMovingDirection.DOWN;
    }

    return direction;
  }


  CanMoveToDirection(direccion: tMovingDirection): boolean {
    //Calculamos celda de destino a partir de la direccion del movimiento
    let targetCellPosition = this.GetNextCellPositionByDirection(direccion);

    //Comprobamos si la celda de destino es pisable
    if (this.walkableMatrix.GetCellByPosition(targetCellPosition) != null) //Existe una celda en esa posicion
    {
      return (this.walkableMatrix.GetCellByPosition(targetCellPosition).IsWalkable());
    }
    else  //Hemos intentado mover fuera de la matrix de celdas
    {
      //console.log("No existe esa celda en la matrix");
      return false;
    }
  }

  MoveToFacingDirection() {
    this.ShowAsRunning();

    //Imagen estatica
    this.timerForShowingStandingImage = setTimeout(() => {

      this.ShowAsStanding();
    }, this.timeMoving * 1000);

    //metodo padre
    super.MoveToDirection(this.facingDirection, this.timeMoving);

  }

  GetNextCellPositionByDirection(direction: tMovingDirection): WalkableCellPosition {
    //Inicializamos la celda de destino a partir de la celda actual del atleta
    let targetCellPosition = this.currentCell.GetPositionInMatrix();;

    //Si estamos en movimiento calculamos a partir de la celda de destino
    if (this.status == tPlayerStatus.MOVING) {
      targetCellPosition = this.cellTarget.GetPositionInMatrix();
    }

    //Calculamos celda de destino a partir de la direccion del movimiento
    switch (direction) {
      case tMovingDirection.UP:
        targetCellPosition = new WalkableCellPosition(targetCellPosition.row - 1, targetCellPosition.column);
        break;
      case tMovingDirection.DOWN:
        targetCellPosition = new WalkableCellPosition(targetCellPosition.row + 1, targetCellPosition.column);
        break;
      case tMovingDirection.LEFT:
        targetCellPosition = new WalkableCellPosition(targetCellPosition.row, targetCellPosition.column - 1);
        break;
      case tMovingDirection.RIGHT:
        targetCellPosition = new WalkableCellPosition(targetCellPosition.row, targetCellPosition.column + 1);
        break;
      default:
        break;
    }

    return targetCellPosition;
  }

  public GetCellPosition(): WalkableCellPosition {
    return this.currentCellPosition;
  }

  public GetCell(): WalkableCell {
    return this.currentCell;
  }

  public IsDisabled(): boolean {
    return this.disabled;
  }

  public Enable() {
    this.disabled = false;
  }

  public Disable() {
    this.disabled = true;
  }

  public ShowAsRunning() {
    this.status = tPlayerStatus.MOVING;
  }

  public ShowAsStanding() {
    this.status = tPlayerStatus.STANDING;
  }

  public getPlayerImage(): string {
    return this.status === tPlayerStatus.STANDING ?
      `${this.pathToAssets}/players/${this.characterId}.png` :
      `${this.pathToAssets}/players/${this.characterId}-walking.gif`
    ;
  }

  UpdateCellPositionByDirection(direction: tMovingDirection) {
    switch (direction) {
      case tMovingDirection.UP:
        this.currentCellPosition.row -= 1;
        break;
      case tMovingDirection.DOWN:
        this.currentCellPosition.row += 1;
        break;
      case tMovingDirection.LEFT:
        this.currentCellPosition.column -= 1;
        break;
      case tMovingDirection.RIGHT:
        this.currentCellPosition.column += 1;
        break;

      default:
        break;
    }
  }

  AfterMoved() {
    this.disabled = false;

    //actualizamos celda actual
    this.currentCell = this.cellTarget;
    this.cellOrigin = this.cellTarget;
    //console.log("Celda actual. Row: " + this.currentCell.GetPositionInMatrix().row + ", " + this.currentCell.GetPositionInMatrix().column);
    //console.log("Tipo de celda: " + this.currentCell.GetType());

    //Comprobamos en que celdas estamos
    //comprobamos si estamos en la casilla de meta
    if (this.currentCell.IsExit()) {

      //Informamos de que hemos llegado a la meta
      this.reachedGoal.emit(this);
    }

    //Comprobamos si estamos en una casilla con llave
    if (this.currentCell.IsCollectable()) {
      this.reachedCollectable.emit(this.walkableMatrix.GetCellByPosition(this.currentCellPosition));
    }

    //Comprobamos si estamos en una casilla con enemigo
    if (this.currentCell.IsEnemy()) {
      this.reachedEnemy.emit(this.walkableMatrix.GetCellByPosition(this.currentCellPosition));
    }

    //Si estamos moviendo a lo largo de un camino comprobamos si nos quedan celdas
    if (this.arrayCellsToTarget != null && this.arrayCellsToTarget.length > 0) {
      //consumimos celda siempre que sea la celda a la que hemos llegado
      if (this.arrayCellsToTarget[0] == this.currentCell) {
        this.arrayCellsToTarget.splice(0, 1);
      }

    }

    //Comprobamos si hemos de parar la animacion
    if (this.arrayCellsToTarget == null || this.arrayCellsToTarget.length == 0) {
      //Paramos animacion
      this.ShowAsStanding();

      //informamos de que hemos llegado al destino
      this.reachedDestination.emit(this.currentCell);
    }
    else {
      this.MoveToNextCellInPath();
    }

    //Informamos de que hemos movido
    this.moved.emit(this);
  }

  SetFacingDirection(direction: tMovingDirection) {
    this.facingDirection = direction;

    switch (direction) {
      case tMovingDirection.LEFT:
        this.styleTransform = "scale(-1,1)";
        break;
      case tMovingDirection.RIGHT:
        this.styleTransform = "scale(1,1)";
        break;
    }
  }
}