import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SoundService } from '../../services/sound/sound.service';
import { SoundDefinitions } from "../../services/sound/SoundDefinitions";
import { tLanguage } from '../../services/translator/language';
import { TranslatorService } from '../../services/translator/translator.service';

const kStyleLeftForOpenDoor = -73;  //porcentaje
const kStyleLeftForClosedDoor = -23;  //porcentaje
const kPathToAssets = "/assets/presentation/intro/";
const kVideoFileName_ES = "es.mp4";
const kVideoFileName_CA = "ca.mp4";

function randomIntFromInterval(min: number, max: number) // min and max included
{
  return Math.floor(Math.random() * (max - min + 1) + min);
}

@Component({
  selector: 'app-presentation-view',
  templateUrl: './presentation-view.component.html',
  styleUrls: ['./presentation-view.component.css']
})
export class PresentationViewComponent implements OnInit {
  showMonitor = true;
  isToggleReady = false;
  doctoraTimer: any;
  stackImgTimer: any = [];
  greetingTimer: any;
  monitorTimer: any;
  canShowVideoForTemplate:boolean = true;
  opacityForVideo:number = 1;

  private title: string = "";
  private styleLeftForDoors: number = kStyleLeftForClosedDoor;

  constructor(
    private _router: Router,
    private soundService: SoundService,
    private translatorService: TranslatorService
  ) { }

  ngOnInit() {
    document.getElementById('videoPres').addEventListener('ended', this.closePres.bind(this), false);
    // var appendingTo = document.getElementById("preloadFrame");

    this.clearLipsing();

    this.title = this.translatorService.getTitleThemeForCurrentLanguage();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.    
    this.soundService.stopSoundFromDefinition(SoundDefinitions.LABORATORIO);
    this.soundService.stopSoundFromDefinition(SoundDefinitions.PRESENTACION);

    // CLEAR TIMERS
    clearTimeout(this.monitorTimer);
    this.clearTimer();
  }

  showVideo(){
    
    var videoElement = <HTMLVideoElement>document.getElementById("videoPres");
    videoElement.currentTime = 0;
    this.canShowVideoForTemplate = true;
    this.soundService.stopSoundFromDefinition(SoundDefinitions.LABORATORIO);
    setTimeout(() => {
      this.opacityForVideo = 1;
      videoElement.play();
    }, 50);
  }

  hideVideo(){

    var videoElement = <HTMLVideoElement>document.getElementById("videoPres");
    videoElement.pause();
    this.opacityForVideo = 0;
    this.soundService.playSoundFromDefinition(SoundDefinitions.LABORATORIO, true);
    setTimeout(() => {
      this.canShowVideoForTemplate = false;
    }, 200);
  }

  clearTimer() {
    clearTimeout(this.doctoraTimer);
    this.stackImgTimer.forEach(element => {
      clearTimeout(element);
    });
    clearTimeout(this.greetingTimer);
    clearTimeout(this.monitorTimer);
  }

  showPresentation() {
    this.soundService.stopSoundFromDefinition(SoundDefinitions.LABORATORIO);
    this.soundService.stopSoundFromDefinition(SoundDefinitions.PRESENTACION);
    this.soundService.playSoundClick();
    this.showVideo();
    //window.location.reload();
  }

  startApp() {
    this.soundService.playSoundClick();

    document.getElementById("doctoraW").hidden = true;

    //Abrimos cortinas
    this.OpenCurtains();

    setTimeout(() => {
      this.showVideo();
      // (document.getElementById("videoPres") as HTMLVideoElement).play();
    }, 1000);
  }

  closePres() {
    
    this.soundService.playSoundClick();

    this.hideVideo();
    // var videoElement = <HTMLVideoElement>document.getElementById("videoPres");
    // videoElement.pause();
    // videoElement.src = null;
    // document.getElementById("videoPresentacion").hidden = true;

    if(this.monitorTimer == null){
      this.playSequenceMonitor();
    }
  }

  doctoraLipsing() {
    (document.getElementById('doctoraWells') as HTMLImageElement).src = 'assets/presentation/inicio_doctora/init_doc_1.png';
    this.stackImgTimer = [];
    for (let index = 2; index < 98; index++) {
      this.stackOfImages(index, 'assets/presentation/inicio_doctora/init_doc_', 63);
    }
    this.soundService.playSoundFromDefinition(SoundDefinitions.PRESENTACION);
  }

  stackOfImages(index: number, pathID: string, time: number) {
    this.stackImgTimer.push('stackOfImages_' + index);
    this.stackImgTimer[index - 2] = setTimeout(function () {
      (document.getElementById('doctoraWells') as HTMLImageElement).src = pathID + index + '.png';
    }, time * index);
  }

  goNextLevel() {
    this.soundService.stopSoundFromDefinition(SoundDefinitions.LABORATORIO);
    this.soundService.stopSoundFromDefinition(SoundDefinitions.PRESENTACION);
    this.soundService.playSoundClick();

    this.clearTimer();

    if (this.isToggleReady) {
      (document.getElementById('doctoraWells') as HTMLImageElement).src = 'assets/presentation/palanca/palanca_1.png';
      this.stackImgTimer = [];
      for (let index = 2; index < 45; index++) {
        this.stackOfImages(index, 'assets/presentation/palanca/palanca_', 40);
      }
      setTimeout(() => {
        this.goJuegos();
      }, 1760);
    } else {
      this.goJuegos();
    }
  }

  goJuegos() {
    this.CloseCurtains();

    setTimeout(() => {
      this._router.navigate(['/games']);
    }, 500);
  }

  playSequenceGreeting() {
    this.greeting().then(() => {
      this.greetingTimer = setTimeout(() => this.playSequenceGreeting(), (randomIntFromInterval(4, 12) * 1000));
    });
  }

  playSequenceMonitor() {
    this.monitor().then(() => {
      this.monitorTimer = setTimeout(() => this.playSequenceMonitor(), (randomIntFromInterval(2, 6) * 1000));
    });
  }

  greeting(): Promise<void> {
    return new Promise(resolve => {
      (document.getElementById('doctoraWells') as HTMLImageElement).src = 'assets/presentation/saludo/saludo_1.png';
      this.stackImgTimer = [];
      for (let index = 2; index < 47; index++) {
        this.stackOfImages(index, 'assets/presentation/saludo/saludo_', 40);
      }
      resolve();
    });
  }

  monitor(): Promise<void> {
    
    return new Promise(resolve => {
      if (this.showMonitor) {
        (document.getElementById("monitorPresentacion") as HTMLImageElement).style.opacity = '0.4';
        this.showMonitor = false;
      } else {
        (document.getElementById("monitorPresentacion") as HTMLImageElement).style.opacity = '0';
        this.showMonitor = true;
      }
      resolve();
    });
  }

  clearLipsing() {
    // var list = document.getElementsByClassName("listDoctoraPresentation");
    // while (list.length > 0){
    //   list[0].remove();
    // }

    var appendingTo = document.getElementById("preloadFrame");

    for (let index = 1; index < 47; index++) {
      var elem = document.createElement('img');
      elem.setAttribute('src', 'assets/presentation/saludo/saludo_' + index + '.png');
      elem.style.position = 'absolute';
      elem.style.top = '138px';
      elem.style.left = '828px';
      elem.classList.add("listDoctoraSaludo");
      appendingTo.appendChild(elem);
    }

    for (let index = 1; index < 45; index++) {
      var elem = document.createElement('img');
      elem.setAttribute('src', 'assets/presentation/palanca/palanca_' + index + '.png');
      elem.style.position = 'absolute';
      elem.style.top = '138px';
      elem.style.left = '828px';
      elem.classList.add("listDoctoraPalanca");
      appendingTo.appendChild(elem);
    }

    this.isToggleReady = true;
    this.playSequenceGreeting();
  }

  GetTitle(): string {
    return this.title;
  }

  OpenCurtains() {
    this.styleLeftForDoors = kStyleLeftForOpenDoor;
  }

  CloseCurtains() {
    this.styleLeftForDoors = kStyleLeftForClosedDoor;
  }

  public GetStyleLeftForDoor(): string {

    return this.styleLeftForDoors.toString() + "%";
  }

  public GetSaltarTranslation(): string {
    return this.translatorService.get("skip").toUpperCase();
  }

  public GetVerTranslation(): string {
    return this.translatorService.get("showIntro").toUpperCase();
  }

  getVisibilityForVideoContainer():string{
    return "visible";
  }

  getPathToVideo():string{
    switch(this.translatorService.currentLanguage){
      case tLanguage.ES:
        return kPathToAssets + kVideoFileName_ES;
      case tLanguage.CA:
        return kPathToAssets + kVideoFileName_CA;
      default:
        return "";
    }
  }
}
