<!-- INFO -->
<div *ngIf = "_showingInfo" class="container" (click)="ClickInfoScreen()">
    <h1 class="titulo">{{_title}}</h1>
    <h1 class="orden">{{_subtitle}}</h1>
    <div class="comenzar noSelectable">{{getStartText()}}</div>
</div>

<!-- RETRY -->
<div *ngIf="_showingRetry" class="container" (click)="ClickRetryScreen()">
    <h1 class="titulo">{{getRetryText()}}</h1>
    <div class="comenzar noSelectable">{{getStartText()}}</div>
</div>
