import { Component, OnInit, AfterViewInit, Injector } from '@angular/core';

import { BaseGameViewComponent } from '@/modules/base/views/base-game-view/base-game-view.component';
import { SuccessViewComponent } from '@/modules/base/components/menus/success-view/success-view.component';
import { SoundDefinitions } from '@/modules/base/services/sound/SoundDefinitions';
import { NumberService } from '@/modules/base/services/number/number.service';

const MIN_SPEED = 50;
const MAX_SPEED = 100;
const RETURN_SPEED = 100;
const LEFT = 'left';
const RIGHT = 'right';
const TIME = [180, 180, 180];
const MAX_ENEMIES = [4, 6, 10];
const kPathImageDoctoraStanding = "./assets/games/05/chica.png";
const kPathImageDoctoraRunning = "./assets/games/05/chica_correr.gif";
const kTransformDoctoraFacingLeft = "scale(1 ,1)";
const kTransformDoctoraFacingRight = "scale(-1 ,1)";

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-how-many-view',
  templateUrl: './how-many-view.component.html',
  styleUrls: ['./how-many-view.component.css']
})
export class HowManyViewComponent extends BaseGameViewComponent implements OnInit, AfterViewInit {
  rowOne = [];
  rowTwo = [];
  rowThree = [];
  totalDisplacement = 0;
  speed = 0;
  stopMove = true;
  lastMove = RIGHT;
  lose = false;
  showButtons = false;
  showRecompensa: boolean = false;  //controla cuando mostrar la recompensa
  doctoraRightStyleCurrentValue: string = "-200px";
  doctoraTopStyleCurrentValue: string = "350px";
  doctoraImagePath = kPathImageDoctoraStanding;
  doctoraTransformCurrentValue = kTransformDoctoraFacingLeft;
  doctoraOutOfScene = true;

  constructor(injector: Injector, private numberService: NumberService) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setGameInfo(this.gamesService.getGameInfo("05"));
  }

  ngAfterViewInit() {
    super.ngAfterViewInit()
  }

  afterCloseInfo() {
    this.topBarController.StartTopBarTimer(TIME[this.gamesService.currentLevel - 1]);

    document.getElementById('enemies-container').addEventListener('webkitTransitionEnd', () => { this.endMoveEnemies() }, false);
    document.getElementById('enemies-container').addEventListener('transitionend', () => { this.endMoveEnemies() }, false);
    document.getElementById('enemies-container').addEventListener('OTransitionEnd', () => { this.endMoveEnemies() }, false);

    this.setupRound();
  }

  setupRound() {
    let row: number;

    this.rowOne = [];
    this.rowTwo = [];
    this.rowThree = [];
    this.stopMove = false;
    this.showButtons = false;
    this.lose = false;
    this.lastMove = RIGHT;
    this.totalDisplacement = 0;
    this.speed = MIN_SPEED;

    document.getElementById('enemies-container').style.transition = 'none';
    
    row = this.numberService.getRandomInt(2, MAX_ENEMIES[this.gamesService.currentLevel - 1]);

    var quotient = Math.floor(row / 3);
    var remainder = row % 3;

    for (let i = 0; i < quotient; i++) {
      this.rowOne.push({ show: true });
      this.rowTwo.push({ show: true });
      this.rowThree.push({ show: true });
    }

    switch (remainder) {
      case 1: this.rowTwo.push({ show: true });
        break;
      case 2: this.rowTwo.push({ show: true });
        this.rowOne.push({ show: true });
        break;
      default:
        break;
    }

    document.getElementById('enemies-container').style.right = '1416px';
    setTimeout(() => {
      document.getElementById('enemies-container').style.transition = '0.5s linear';
      this.endMoveEnemies();
    }, 500);

    if (this.doctoraOutOfScene) {
      this.DoctoraEntersScene();
    }
  };

  DoctoraEntersScene() {
    this.DoctoraRunToLeft();

    setTimeout(() => {
      this.DoctoraFacingLeft();
    }, 2000);

    this.doctoraOutOfScene = false;
  }

  DoctoraExitsScene() {
    this.DoctoraRunToRight();

    this.doctoraOutOfScene = true;
  }

  DoctoraRunToLeft() {
    this.doctoraRightStyleCurrentValue = "100px";
    this.doctoraTopStyleCurrentValue = "350px";
    this.doctoraTransformCurrentValue = kTransformDoctoraFacingLeft;
    this.doctoraImagePath = kPathImageDoctoraRunning;
  }

  DoctoraRunToRight() {
    this.doctoraRightStyleCurrentValue = "-200px";
    this.doctoraTopStyleCurrentValue = "350px";
    this.doctoraTransformCurrentValue = kTransformDoctoraFacingRight;
    this.doctoraImagePath = kPathImageDoctoraRunning;
  }

  DoctoraFacingLeft() {
    this.doctoraImagePath = kPathImageDoctoraStanding;
    this.doctoraTransformCurrentValue = kTransformDoctoraFacingLeft;
  }

  get visibilityButtonsSecondLevel() {
    return this.gamesService.currentLevel == 2 || this.gamesService.currentLevel == 3;
  }

  get visibilityButtonsThirdLevel() {
    return this.gamesService.currentLevel == 3;
  }

  endMoveEnemies() {
    if (this.stopMove) {
      return;
    }

    switch (this.lastMove) {
      case LEFT:
        this.moveLeft();
        break;
      default:
        this.moveRight();
        break;
    }
  }

  moveRight() {
    if (this.totalDisplacement > (120 * this.rowTwo.length) && !this.lose) {
      this.showButtons = true;
    }

    if ((this.totalDisplacement + this.speed) > 1200 && !this.lose) {
      this.soundService.playSoundFromDefinition(SoundDefinitions.MAGIA);

      this.lose = true;
      this.showButtons = false;

      this.DoctoraExitsScene();
    }

    if (this.totalDisplacement > 1750) {
      this.stopMove = true;
      this.showRecompensa = false;
      setTimeout(() => {
        this.setupRound();
      }, 1000);
    }

    this.totalDisplacement += this.speed;
    document.getElementById('enemies-container').style.right = (+document.getElementById("enemies-container").style.right.replace("px", "") - this.speed) + "px";
  }

  moveLeft() {
    if ((+document.getElementById("enemies-container").style.right.replace("px", "")) < 1366) {
      document.getElementById('enemies-container').style.right = (+document.getElementById("enemies-container").style.right.replace("px", "") + RETURN_SPEED) + "px";
    } else {

      this.nextRound();
    }
  }

  clickButton(indexButton: number) {
    this.soundService.playSoundClick();

    if (indexButton == (this.rowOne.length + this.rowTwo.length + this.rowThree.length)) {
      this.soundService.playSoundAcierto();

      this.showButtons = false;
      this.stopMove = true;

      const miniDinos = document.getElementsByClassName('enemy-item');
      for (let i = 0; i < miniDinos.length; i++) {
        (miniDinos[i] as HTMLImageElement).style.transform = 'scaleX(-1)';
      }

      this.lastMove = LEFT;
      this.stopMove = false;
      this.endMoveEnemies();
    } else {
      this.soundService.playSoundError();

      document.getElementById('but' + indexButton).style.color = 'white';
      document.getElementById('but' + indexButton).style.backgroundColor = '#f24725';
      if (this.speed < MAX_SPEED) {
        this.speed += 10;
      }
      setTimeout(() => {
        document.getElementById('but' + indexButton).style.color = '#00d7e5';
        document.getElementById('but' + indexButton).style.backgroundColor = 'white';
      }, 500);
    }
  }
}