import { Injectable } from '@angular/core';

import { GamesService } from '@/modules/base/services/games/games.service';

@Injectable({
  providedIn: 'root'
})
export class GameDataService {

  constructor(
    private gamesService: GamesService
  ) { }

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  reset() {
    this.gamesService.arrayGameInfo.forEach(gameInfo => {
      localStorage.setItem(gameInfo.id + "_" + this.gamesService.currentLevel, "0");
    })
  }
}
