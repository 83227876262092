<div class="game-view">
  <!-- BACKGROUND  -->
  <img style="position: absolute; top: 0px; left:0px; pointer-events: none"
    src="./assets/games/03/{{currentBackgroundImageName}}">

  <!-- CURTAINS -->
  <div class="puerta1" id="puerta1" [ngStyle]="{'left':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>
  <div class="puerta2" id="puerta2" [ngStyle]="{'right':GetStyleLeftForDoor()}">
    <img src="./assets/presentation/barra_puerta.png">
  </div>

  <!-- CELDAS CLICKABLES -->
  <div *ngFor="let clickableCell of arrayLaberintoClickableCellsForTemplate; index as i">
    <app-walkable-cell-clickable #clickableCells [id]="'walkablecell_' + i" [pathToAssets]="" [imgName]=""
      [walkableMatrix]="walkableMatrix" [walkableCell]="clickableCell" (clicked)="OnWalkableCellClicked($event)">
    </app-walkable-cell-clickable>
  </div>

  <!-- COLLECTABLES -->
  <div *ngFor="let collectable of arrayLaberintoCollectablesForTemplate; index as i" style="pointer-events: none;">
    <app-walkable-cell-disapearing-item #collectables [id]="collectable.id + i"
      [pathToAssets]="collectable.pathToAssets" [imgName]="collectable.imgName" [walkableMatrix]="walkableMatrix"
      [walkableCell]="collectable.walkableCell" [cellType]="collectable.cellType"
      [showFromStart]="collectable.showFromStart" [timeShowingInterval]="collectable.timeShowingInterval"
      [timeWatingToShowInterval]="collectable.timeWaitingToShowInterval">
    </app-walkable-cell-disapearing-item>
  </div>

  <!-- ENEMY -->
  <div *ngFor="let enemy of arrayLaberintoEnemiesForTemplate; index as i" style="pointer-events: none;">
    <app-enemy #enemies [id]="enemy.id + i" [pathToAssets]="enemy.pathToAssets" [imgName]="enemy.imgName"
      [walkableMatrix]="walkableMatrix" [walkableCell]="enemy.walkableCell" [cellType]="enemy.cellType"
      [showFromStart]="enemy.showFromStart" [timeShowingInterval]="enemy.timeShowingInterval"
      [timeWatingToShowInterval]="enemy.timeWaitingToShowInterval">
    </app-enemy>
  </div>

  <!-- EXIT DOOR -->
  <div style="pointer-events: none">
    <app-walkable-cell-item #door [id]="doorItemForTemplate.id" [pathToAssets]="doorItemForTemplate.pathToAssets"
      [imgName]="doorItemForTemplate.imgName" [walkableMatrix]="walkableMatrix"
      [walkableCell]="doorItemForTemplate.cell">
    </app-walkable-cell-item>
  </div>

  <!-- EXIT -->
  <div style="pointer-events: none">
    <app-walkable-cell-item #exit [id]="exitItemForTemplate.id" [pathToAssets]="exitItemForTemplate.pathToAssets"
      [imgName]="exitItemForTemplate.imgName" [walkableMatrix]="walkableMatrix"
      [walkableCell]="exitItemForTemplate.cell">
    </app-walkable-cell-item>
  </div>

  <!-- PLAYER -->
  <div style="pointer-events: none;">
    <app-player #player [id]="playerInfo.id" [pathToAssets]="playerInfo.pathToAssets" [characterId]="playerInfo.characterId" [timeMoving]="timeMovingPlayer" [initPoint]="playerInfo.initPoint"
      [offsetX]="playerInfo.offsetX" [offsetY]="playerInfo.offsetY" [walkableMatrix]="walkableMatrix"
      (moved)="OnPlayerMoved($event)" (reachedGoal)="OnPlayerReachedGoal()"
      (reachedCollectable)="OnPlayerReachedCollectable($event)" (reachedEnemy)="OnPlayerReachedEnemy($event)"
      (reachedDestination)="OnPlayerReachedDestination($event)">
    </app-player>
  </div>

  <!-- UPPER BAR -->
  <div>
    <app-top-bar #topBar (clickBackButton)="OnClickedBackButton()" (clickInfoButton)="OnClickedInfoButton()"
      (timerOver)="OnTimerOver()">
    </app-top-bar>
  </div>

  <!-- ITEMS EN TOP BAR-->
  <div class="containerTopBarCollectables">
    <div class="containerCollectable" *ngFor="let topbarCollectable of arrayTopBarCollectableIdsForTemplate;index as i">
      <app-item-html #topbarCollectables [id]="topbarCollectable" [pathToAssets]="'./assets/games/03'"
        [imgName]="'baya_contador.png'">
      </app-item-html>
    </div>
  </div>
</div>

<div class="fadeContainer" [ngStyle]="{'opacity': styleOpacityForFadeContainer}"></div>

<!-- SUCCESS SCREEN -->
<app-success-view [gameInfo]="gameInfo"></app-success-view>

<!-- INFO SCREEN -->
<app-info-screen #infoScreen (infoScreenClosed)="OnInfoScreenClosed()" (retryScreenClosed)="OnRetryScreenClosed()">
</app-info-screen>