import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PuzzlesViewComponent } from './views/puzzles-view/puzzles-view.component';
import { BaseModule } from '../base/base.module';

@NgModule({
  declarations: [PuzzlesViewComponent],
  imports: [
    CommonModule, 
    DragDropModule,
    BaseModule
  ],
  exports: [PuzzlesViewComponent]
})
export class PuzzlesModule { }
