import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ShapesViewComponent } from './views/shapes-view/shapes-view.component';
import { BaseModule } from '../base/base.module';

@NgModule({
  declarations: [ShapesViewComponent],
  imports: [
    CommonModule, 
    DragDropModule,
    BaseModule
  ],
  exports: [ShapesViewComponent]
})
export class ShapesModule { }
