import { Injectable } from '@angular/core';
import { CellPosition } from '../models/interfaces';

@Injectable()
export class HorizontalTravelCameraService {
    private horizontalOffset: number;
    private withInterpolation: boolean;

    public reset(): void {
        this.horizontalOffset = 0;
    }

    public update(playerPosition: CellPosition, currentScenaryMatrix: string[][], cellSize: number, withInterpolation: boolean = true): void {
        const OFFSET_MIN_X: number = 7;
        const cameraCellsOffset: number = playerPosition.column - OFFSET_MIN_X;

        this.withInterpolation = withInterpolation;

        if (cameraCellsOffset <= currentScenaryMatrix[0].length - (OFFSET_MIN_X * 2 + 1)) {
            this.horizontalOffset = cameraCellsOffset > 0 ?
                (playerPosition.column - OFFSET_MIN_X) * -cellSize :
                0
        }
    }

    public getTransitionStyle(): string {
        return this.withInterpolation ? '0.75s' : 'none';
    }

    public getHorizontalOffsetStyle(): string {
        return `${this.horizontalOffset}px`;
    }
}