import { Component, OnInit, ViewChild, ViewChildren, QueryList, HostListener, OnDestroy, Injector } from '@angular/core';

import { WalkableCellMatrix, WalkableCell, tWalkableCellType, WalkableCellPosition } from '../../walkable-cell-matrix';
import { MovableComponent, tMovingDirection } from '@/modules/base/components/movable/movable.component';
import { CustomPoint } from '@/modules/base/models/customPoint';
import { PlayerComponent } from '../../components/player/player.component';
import { PlayerInfo } from '../../player-info';
import { WalkableCellItem } from '../../walkable-cell-item';
import { WalkableCellItemComponent } from '../../components/walkable-cell-item/walkable-cell-item.component';
import { ItemHTMLComponent } from '@/modules/base/components/item-html/item-html.component';
import { WalkableCellDisapearingItemComponent } from '../../components/walkable-cell-disapearing-item/walkable-cell-disapearing-item.component';
import { WalkableCellDisapearingItemInfo } from '../../walkable-cell-disapearing-item-info';
import { EnemyComponent } from '../../components/enemy/enemy.component';
import { WalkableCellClickableComponent } from '../../components/walkable-cell-clickable/walkable-cell-clickable.component';
import { SoundDefinitions } from '@/modules/base/services/sound/SoundDefinitions';
import { BaseGameViewComponent } from '@/modules/base/views/base-game-view/base-game-view.component';
import { SuccessViewComponent } from '@/modules/base/components/menus/success-view/success-view.component';
import { NumberService } from '@/modules/base/services/number/number.service';
import { LabyrinthService } from '../../services/labyrinth.service';

const kTimeMovingPlayer = 0.5;
const kOffsetPlayerWhenMovingVertical = 91;
const kOffsetPlayerWhenMovingHorizontal = 91;
const kCellHeight = 91;
const kCellWidth = 91;
const kMatrixRows = 8;
const kMatrixColumns = 14;
const kScreenWidth = 1366;
const kScreenHeight = 768;
const kImgNameTopbarItem = "baya_contador.png";
const kImgNameTopbarItemActivated = "baya_contador_color.png";
const kImgNameCollectableReloj = "baya.png";
const kIdExitDoor = "exitDoor";
const kIdExit = "exit";
const kNumCollectablesByLevel: number[][] = [null, [3, 3, 4], [4, 5, 6], [6, 7, 8]];
const kNumEnemiesBylevel: number[] = [null, 1, 1, 1];
const kPathToAssets: string = "assets/games/03";
const kImgNameDoor = "puerta";
const kTimerByLevel: number[] = [null, 500, 500, 500];

@Component({
  providers: [SuccessViewComponent],
  selector: 'app-labyrinth-view',
  templateUrl: './labyrinth-view.component.html',
  styleUrls: ['./labyrinth-view.component.css']
})
export class LabyrinthViewComponent extends BaseGameViewComponent implements OnInit {
  @ViewChild(PlayerComponent) private playerController: PlayerComponent;
  @ViewChildren('collectables') arrayCollectablesControllers: QueryList<WalkableCellDisapearingItemComponent>;
  @ViewChildren('clickableCells') arrayClickableCellsController: QueryList<WalkableCellClickableComponent>;
  @ViewChildren('enemies') arrayEnemiesControllers: QueryList<EnemyComponent>;
  @ViewChild('exit') private exitController: WalkableCellItemComponent;
  @ViewChild('door') private doorController: WalkableCellItemComponent;
  @ViewChildren('topbarCollectables') arrayTopBarCollectablesControllers: QueryList<ItemHTMLComponent>;

  public walkableMatrix: WalkableCellMatrix;
  public playerInfo: PlayerInfo;
  public timeMovingPlayer: number = kTimeMovingPlayer;
  public currentBackgroundImageName: string = "";
  public arrayLaberintoCollectablesForTemplate: WalkableCellDisapearingItemInfo[] = [];
  public arrayLaberintoEnemiesForTemplate: WalkableCellDisapearingItemInfo[] = [];
  public arrayLaberintoItemsForTemplate: WalkableCellItem[] = [];
  public exitItemForTemplate: WalkableCellItem;
  public doorItemForTemplate: WalkableCellItem;
  public arrayLaberintoClickableCellsForTemplate: WalkableCell[] = [];
  public arrayTopBarCollectableIdsForTemplate: string[] = [];
  public initPositionForCamara: CustomPoint;
  private counterRelojes: number = 0;
  private numCollectablesToGet: number = 0;
  private numEnemiesForRound: number = 0;
  private walkableMatrixCellTypes: tWalkableCellType[][] = [];
  private player: PlayerComponent;
  private lastCellClicked: WalkableCellClickableComponent;

  constructor(
    injector: Injector,
    private numberService: NumberService,
    private labyrinthService: LabyrinthService
  ) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();

    this.setGameInfo(this.gamesService.getGameInfo("03"));

    this.setupRound();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  afterCloseInfo() {
    this.EnableLaberintoDisapearingItems();

    //Ponemos en marcha el timer
    this.topBarController.StartTopBarTimer(kTimerByLevel[this.gamesService.currentLevel]);
  }

  EnableLaberintoDisapearingItems() {
    //Activamos todos los collectables y enemigos del laberinto
    this.arrayEnemiesControllers.forEach(iteratingEnemy => {
      iteratingEnemy.Enable();
    });

    this.arrayCollectablesControllers.forEach(iteratingCollectable => {

      iteratingCollectable.Enable();
    });

  }

  setupRound() {
    this.SetBackgroundImage();

    //Generamos la matriz por donde ha de moverse el personaje
    this.GenerateWalkableMatrix();

    //collectables para esta ronda
    this.numCollectablesToGet = kNumCollectablesByLevel[this.gamesService.currentLevel][this.exito];

    //Items en el laberinto
    this.generateElements();

    //Collectables top bar
    this.SetTopBarItems();
  }

  /** Borra todos los items de esta ronda */
  ClearRoundItems() {
    //Desactivamos todos los collectables
    this.arrayCollectablesControllers.forEach(collectableController => {

      collectableController.Disable();
    });

    //Desactivamos todos los enemigos
    this.arrayEnemiesControllers.forEach(enemyController => {
      enemyController.Disable();
    });

    //Puerta visible
    this.CloseDoor();

    //lienzo en blanco
    //this.lienzoController.ShowAsUnsolved();

    //desactivamos top bar items
    this.counterRelojes = 0;
    this.arrayTopBarCollectablesControllers.forEach(iteratingItem => {
      iteratingItem.SetImageName(kImgNameTopbarItem);
    });
  }

  SetBackgroundImage() {
    this.currentBackgroundImageName = this.labyrinthService.arrayImgNameBackgroundsForRound[this.exito];
  }

  //#region  llaves barra superior
  /** llaves barra superior */
  SetTopBarItems() {
    //vaciamos array
    this.arrayTopBarCollectableIdsForTemplate.splice(0, this.arrayTopBarCollectableIdsForTemplate.length);

    for (let index = 0; index < this.numCollectablesToGet; index++) {
      this.arrayTopBarCollectableIdsForTemplate.push("collectableTopBar_" + index.toString());
    }
  }

  /** Activa la siguiente llave de la barra superior */
  ActivateTopbarItem(index: number) {
    if (index >= this.arrayTopBarCollectablesControllers.length) {
      return;
    }

    let arrayKeys: ItemHTMLComponent[] = this.arrayTopBarCollectablesControllers.toArray();
    //arrayKeys[index].SetImageName(kImgNameTopbarKeyActivated);
    arrayKeys[index].ShowImageWithScaleEffect(kImgNameTopbarItemActivated);
  }

  /** Desactiva el collectable */
  DeactivateTopBarItem(index: number) {
    if (index >= this.arrayTopBarCollectablesControllers.length) {
      return;
    }

    let arrayKeys: ItemHTMLComponent[] = this.arrayTopBarCollectablesControllers.toArray();
    //arrayKeys[index].SetImageName(kImgNameTopbarKeyActivated);
    arrayKeys[index].ShowImageWithScaleEffect(kImgNameTopbarItem);
  }

  getDoorImagePath(): string {
    return `${kImgNameDoor}_${this.exito + 1}.png`;
  }

  getCurrentPlayerName(): string {
    return this.gameInfo.parameters.rounds[this.exito].player;
  }

  GenerateWalkableMatrix() {
    let walkableMatrixInitValues = this.labyrinthService.getWalkableMatrixForRound(this.exito);

    //Generamos matrix de tipos de celda a partir de la matriz inicial hardcodeada en LaberintoUtils
    for (let x = 0; x < walkableMatrixInitValues.length; x++) {
      this.walkableMatrixCellTypes[x] = [];
      for (let y = 0; y < walkableMatrixInitValues[x].length; y++) {

        //Asigamos la celda a la matriz de celdas
        this.walkableMatrixCellTypes[x][y] = walkableMatrixInitValues[x][y];
      }
    }

    //Creamos una matrix a partir de la matrix con los tipos de celda
    this.walkableMatrix = new WalkableCellMatrix(this.walkableMatrixCellTypes);

    //Seteamos dimensiones de las celdas
    this.walkableMatrix.SetCellHeight(kCellHeight);
    this.walkableMatrix.SetCellWidth(kCellWidth);
  }

  /** Genera los distintos items que encontramos en el laberinto. Llaves, puertas, enemigos... */
  generateElements() {
    if (this.arrayClickableCellsController != null) {
      this.arrayLaberintoClickableCellsForTemplate.splice(0, this.arrayLaberintoClickableCellsForTemplate.length);
    }
    //Referencia a las celdas del laberinto
    let cellMatrix = this.walkableMatrix.GetCellsMatrix();

    //referencia a la celda actual por la que iteramos
    let iteratingCell: WalkableCell;

    //Recorremos celdas del laberinto y vamos comprobando si hay que generar algun item en la celda
    for (let row = 0; row < cellMatrix.length; row++) {
      for (let column = 0; column < cellMatrix[row].length; column++) {
        iteratingCell = cellMatrix[row][column];

        //player
        if (iteratingCell.GetType() == tWalkableCellType.PLAYER) {
          this.generatePlayer(iteratingCell);
        }

        //celdas clickables hacia donde podemos movernos
        if (iteratingCell.IsWalkable()) {
          this.arrayLaberintoClickableCellsForTemplate.push(iteratingCell);
        }

        // salida
        if (iteratingCell.GetType() == tWalkableCellType.EXIT_NON_WALKABLE) {
          //console.log("He encontrado una salida: (" + row + ", " + column + ")");
          this.GenerateExitItem(iteratingCell);
        }

        // door
        if (iteratingCell.GetType() == tWalkableCellType.OTHERS_NON_WALKABLE) {
          //console.log("He encontrado una salida: (" + row + ", " + column + ")");
          this.GenerateExitDoorItem(iteratingCell);
        }
      }
    }

    //Enemigos
    this.GenerateEnemyItemsForRound(kNumEnemiesBylevel[this.gamesService.currentLevel]);

    //Collectables
    this.GenerateCollectableItemsForRound(this.numCollectablesToGet);
  }

  GenerateExitItem(walkableCell: WalkableCell) {
    this.exitItemForTemplate = new WalkableCellItem(kIdExit, kPathToAssets, "pixel_alfa.png", walkableCell);
  }


  GenerateExitDoorItem(walkableCell: WalkableCell) {
    this.doorItemForTemplate = new WalkableCellItem(kIdExitDoor, kPathToAssets, this.getDoorImagePath(), walkableCell);
  }

  GenerateEnemyItemsForRound(numEnemies: number) {
    const numEnemiesToAdd = numEnemies - this.arrayLaberintoEnemiesForTemplate.length;

    for (let i = 0; i < numEnemiesToAdd; i++) {
      const enemy: WalkableCellDisapearingItemInfo = new WalkableCellDisapearingItemInfo();

      enemy.id = "enemy";
      enemy.pathToAssets = kPathToAssets;
      enemy.imgName = "oso.gif";
      enemy.walkableCell = null;
      enemy.cellType = tWalkableCellType.ENEMY;
      enemy.showFromStart = false;
      enemy.timeShowingInterval = [5, 10];
      enemy.timeWaitingToShowInterval = [3, 6];

      this.arrayLaberintoEnemiesForTemplate.push(enemy);
    }
  }

  GenerateCollectableItemsForRound(numCollectables: number) {
    let numCollectablesToAdd = numCollectables;

    if (this.arrayCollectablesControllers != null) //Ya tenemos collectables de una ronda anterior
    {
      //vemos si hemos de añadir mas collectables
      numCollectablesToAdd = numCollectables - this.arrayCollectablesControllers.length;
    }

    for (let i = 0; i < numCollectablesToAdd; i++) {
      const collectable: WalkableCellDisapearingItemInfo = new WalkableCellDisapearingItemInfo();
      collectable.id = "collectable";
      collectable.pathToAssets = kPathToAssets;
      collectable.imgName = kImgNameCollectableReloj;
      collectable.walkableCell = null;
      collectable.cellType = tWalkableCellType.COLLECTABLE;
      collectable.showFromStart = false;
      collectable.timeShowingInterval = [8, 10];
      collectable.timeWaitingToShowInterval = [1, 4];

      this.arrayLaberintoCollectablesForTemplate.push(collectable);
    }
  }

  generatePlayer(walkableCell: WalkableCell) {
    const initCellPosition: WalkableCellPosition = walkableCell.GetPositionInMatrix();
    const initPoint: CustomPoint = new CustomPoint(kCellWidth * initCellPosition.column, kCellHeight * (kMatrixRows - 1 - initCellPosition.row));

    //Creamos la info para el player
    this.playerInfo = new PlayerInfo();
    this.playerInfo.id = "player";
    this.playerInfo.pathToAssets = kPathToAssets;
    this.playerInfo.characterId = this.getCurrentPlayerName();
    this.playerInfo.initPoint = initPoint;
    this.playerInfo.offsetX = kOffsetPlayerWhenMovingHorizontal;
    this.playerInfo.offsetY = kOffsetPlayerWhenMovingVertical;

    if (this.playerController) {
      this.playerController.SetInitPosition(initPoint);
      this.playerController.Enable();
      this.playerController.RegenerateMatrixForPathFinding(this.walkableMatrix);
    }
  }

  MovePlayerToDirection(direction: tMovingDirection) {
    //Si estamos mostrando la pantalla de info no dejamos mover
    if (this.infoScreenController.IsShowingInfoScreen()) {
      return;
    }

    this.playerController.MoveToDirection(direction);
  }

  /** Devuelve el controler del array de items cuya celda coincide con la celda pasada */
  GetCollectableItemAtCell(cell: WalkableCell): WalkableCellDisapearingItemComponent {
    let collectableController: WalkableCellDisapearingItemComponent = null;

    //Buscamos el item que esta en la celda
    this.arrayCollectablesControllers.forEach(itemController => {

      if (itemController.walkableCell == cell) {
        collectableController = itemController;
      }
    });

    return collectableController;
  }

  /** Recarga la escena */
  ReloadRound() {
    setTimeout(() => {
      this.FadeInScreenContainer();
    }, 500);

    //Limpiamos laberinto de items
    setTimeout(() => {
      this.ClearRoundItems();
    }, 1500);

    //Volvemos a recrear laberinto
    setTimeout(() => {
      this.setupRound();
    }, 1600);

    //FadeOut
    setTimeout(() => {
      this.FadeOutScreenContainer();
    }, 2000);

    //Hacemos visibles los items
    setTimeout(() => {
      this.EnableLaberintoDisapearingItems();
    }, 2500);

  }

  /** Comprueba si hemos superado la ronda */
  HavePlayerAllCollectables(): boolean {
    return (this.counterRelojes >= this.numCollectablesToGet);
  }

  OpenDoor() {
    this.doorController.SetOpacity(0, true, 0.3);
  }

  CloseDoor() {
    this.doorController.SetOpacity(1, false, 0);
  }

  /** Termina la ronda actual */
  finishRound() {
    if (this.exito < 2) {
      this.nextRound(500, 150, 2000);

      setTimeout(() => {
        this.FadeInScreenContainer();
      }, 1500);

      setTimeout(() => {
        this.ClearRoundItems();
      }, 2000);

      setTimeout(() => {
        this.FadeOutScreenContainer();
      }, 2500);

      //Hacemos visibles los items
      setTimeout(() => {
        this.EnableLaberintoDisapearingItems();
      }, 2800);
    }
    else {
      //paramos timers de los enemigos
      this.arrayEnemiesControllers.forEach(enemyController => {
        enemyController.ClearTimeOuts();
      });

      //Finalizamos
      this.nextRound();
    }
  }

  /** Acciones cuando la salida es accesible */
  SetExitAsAvailable() {
    this.soundService.playSoundFromDefinition(SoundDefinitions.ARPEGIO);

    //abrimos la puerta
    this.OpenDoor();

    //Hacemos su celda walkable
    this.doorController.walkableCell.SetType(tWalkableCellType.WALKABLE);

    //La añadimos a las celdas clickables
    this.arrayLaberintoClickableCellsForTemplate.push(this.doorController.walkableCell);

    //Activamos la casilla de salida para que se pueda llegar a ella
    this.exitController.walkableCell.SetType(tWalkableCellType.EXIT);

    //Añadimos casilla de salida a la matrix de celdas clickables
    this.arrayLaberintoClickableCellsForTemplate.push(this.exitController.walkableCell);

    //regeneramos matrix del pathfinding del player para que el player pueda ir hasta la salida
    this.playerController.RegenerateMatrixForPathFinding(this.walkableMatrix);
  }

  /** Acciones cuando la salida no es accesible */
  SetExitAsUnavailable() {
    this.CloseDoor();

    //Hacemos su celda no walkable
    this.doorController.walkableCell.SetType(tWalkableCellType.NON_WALKABLE);

    //La quitamos de la matriz de celdas clickables
    let indexDoorCell = this.arrayLaberintoClickableCellsForTemplate.indexOf(this.doorController.walkableCell);
    if (indexDoorCell > -1) {
      this.arrayLaberintoClickableCellsForTemplate.splice(indexDoorCell, 1);
    }

    //Desactivamos la casilla de salida
    this.exitController.walkableCell.SetType(tWalkableCellType.NON_WALKABLE);

    //La quitamos de la matriz de celdas clickables
    let indexExitCell = this.arrayLaberintoClickableCellsForTemplate.indexOf(this.exitController.walkableCell);
    if (indexExitCell > -1) {
      this.arrayLaberintoClickableCellsForTemplate.splice(indexExitCell, 1);
    }

    //regeneramos matrix del pathfinding del player para que el player no pueda llegar a la salida
    this.playerController.RegenerateMatrixForPathFinding(this.walkableMatrix);
  }

  /* Elimina el ultimo collectable recogido. Se llama cuando caemos en la casilla de un enemigo */
  EliminateLastCollectable() {
    this.soundService.playSoundFromDefinition(SoundDefinitions.ERROR);

    //desactivamos collectable en la barra superior
    this.DeactivateTopBarItem(this.counterRelojes - 1);

    //Volvemos a activar el collectable en el laberinto
    let arrayCollectables = this.arrayCollectablesControllers.toArray();
    for (let i = 0; i < arrayCollectables.length; i++) {

      if (!arrayCollectables[i].IsEnabled()) {

        //Activamos imagen de no conseguido para el collectable
        arrayCollectables[i].EnableAfterDelay(this.numberService.getRandomFloat(2));

        break;
      }
    }

    //Desasignamos array temporal porque ya no lo necesitamos
    arrayCollectables = null;
  }

  /* Devuelve el controller de la celda clickable que asociada con la walkableCell pasada */
  private GetClickableCellControllerAtCell(cell: WalkableCell): WalkableCellClickableComponent {

    let arrayClickableCells = this.arrayClickableCellsController.toArray();
    for (let i = 0; i < arrayClickableCells.length; i++) {
      if (arrayClickableCells[i].walkableCell == cell) {
        return arrayClickableCells[i];
      }

    }
    return null;
  }

  /* Resalta la casilla pasada como una colision con un enemigo */
  private ShowEnemyCollisionAtCell(cell: WalkableCell): WalkableCellClickableComponent {
    let clickableCell = this.GetClickableCellControllerAtCell(cell);

    if (clickableCell) {
      clickableCell.ShowAsError();
    }

    return clickableCell;
  }

  /** Evento que se dispara desde el component MovableComponent cuando hemos movido un movable */
  OnPlayerMoved(player: PlayerComponent) {


  }

  /** Evento que se dispara desde el Player cuando hemos llegado a la celda de destino dentro de un camino */
  OnPlayerReachedDestination(cell: WalkableCell) {
    if (this.lastCellClicked) {
      this.lastCellClicked.ShowAsDisabled();
    }
  }

  /** Evento que se dispara desde el component laberintoExploradoraComponent cuando hemos llegado a la salida */
  OnPlayerReachedGoal() {
    if (this.HavePlayerAllCollectables()) {

      this.playerController.Disable();

      //mostramos pintura en lienzo
      //this.lienzoController.ShowAsSolved();
      this.soundService.playSoundFromDefinition(SoundDefinitions.BRILLO);

      //terminamos la ronda
      setTimeout(() => {
        this.finishRound();
      }, 200);
    }
    else {
      this.soundService.playSoundFromDefinition(SoundDefinitions.ERROR);
    }
  }

  OnPlayerReachedCollectable(cell: WalkableCell) {

    //Buscamos el item que esta en la celda
    let laberintoItem = this.GetCollectableItemAtCell(cell);
    // if(laberintoItem == null)
    // {
    //   //console.log("Algo ha ido mal y no he encontrado el collectable en la celda");
    // }

    // if(!laberintoItem.IsEnabled())
    // {
    //   //console.log("Algo ha ido mal y el collectable esta disabled");
    // }

    if (laberintoItem != null && laberintoItem.IsEnabled()) {
      this.counterRelojes++;

      //Sonido de acierto
      this.soundService.playSoundFromDefinition(SoundDefinitions.ACIERTO);

      //desactivamos el item para evitar recogerlo varias veces
      laberintoItem.Disable();

      //activamos la llave en la barra superior
      setTimeout(() => {

        this.ActivateTopbarItem(this.counterRelojes - 1);
        this.soundService.playSoundFromDefinition(SoundDefinitions.BOING);
      }, 300);

      //Hacemos desaparecer la puerta si ya tenemos todas las llaves
      if (this.HavePlayerAllCollectables()) {

        setTimeout(() => {

          this.SetExitAsAvailable();
        }, 500);
      }
    }
  }

  OnPlayerReachedEnemy(cell: WalkableCell) {
    this.soundService.playSoundFromDefinition(SoundDefinitions.ERROR);

    //Resaltamos la casilla donde hemos chocado con el enemigo
    //let clickableCellEnemy = this.ShowEnemyCollisionAtCell(cell);

    //desmarcamos celda de destino si era distinta a la de la colision
    // if(this.lastCellClicked != clickableCellEnemy) 
    // {
    //   this.lastCellClicked.ShowAsDisabled();
    // }

    //Paramos enemigo
    // this.arrayEnemiesControllers.forEach(enemyController => {
    //   enemyController.ClearTimeOuts();
    // });

    setTimeout(() => {
      this.playerController.ResetPosition();

      //Quitamos un collectable si hubiera alguno que quitar
      if (this.counterRelojes > 0) {

        //Quitamos el ultimo collectable adquirido
        this.EliminateLastCollectable();

        //Si teniamos la salida habilitada la desactivamos
        if (this.HavePlayerAllCollectables()) {
          setTimeout(() => {
            this.SetExitAsUnavailable();
          }, 500);
        }

        //decrementamos contador de collectables
        this.counterRelojes--;
      }

      //activamos el player que se ha desactivado al chocar con un enemy
      this.playerController.Enable();

      //desmarcamos la celda donde hemos colisionado con el enemy
      // if(clickableCellEnemy)
      // {
      //   setTimeout(() => {
      //     clickableCellEnemy.ShowAsDisabled();
      //   }, 500);
      // }      

    }, 500);


    //Recargamos la escena
    //this.ReloadRound();
  }

  //OnWalkableCellClicked(cell:WalkableCell)
  OnWalkableCellClicked(clickableCell: WalkableCellClickableComponent) {
    if (this.playerController.IsDisabled()) {
      return;
    }

    //movemos el player a la celda
    this.playerController.MoveToCell(clickableCell.walkableCell);
  }
}