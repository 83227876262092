import { Injectable } from '@angular/core';
import { ScenaryCellType } from '../models/enums';

import { CellPosition, ScenaryData } from '../models/interfaces';

@Injectable()
export class HorizontalTravelScenaryService {
  private scenaries: ScenaryData[][] = [
    [
      {
        initialPosition: {
          row: 5,
          column: 1
        },
        goalColumn: 19,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "10", "02", "02", "02", "02", "02"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "05", "00", "00", "00", "00", "00"],
            ["10", "02", "02", "02", "02", "02", "02", "12", "01", "05", "00", "00", "00", "00", "00"],
            ["05", "00", "00", "00", "00", "00", "00", "08", "02", "07", "19", "09", "04", "04", "06"],
            ["05", "00", "00", "00", "00", "00", "00", "00", "00", "00", "19", "03", "01", "01", "05"],
            ["11", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "13", "01", "01", "11"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "01", "01", "01", "01"],
            ["02", "12", "01", "01", "01", "01", "01", "01"],
            ["00", "16", "02", "02", "02", "02", "02", "12"],
            ["00", "14", "00", "00", "90", "91", "92", "03"],
            ["18", "00", "00", "00", "93", "94", "95", "03"],
            ["18", "00", "00", "00", "96", "97", "98", "03"],
            ["04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01"]
          ],
        ]
      },
      {
        initialPosition: {
          row: 5,
          column: 1
        },
        goalColumn: 19,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "10", "02", "02", "02", "02", "02", "02", "02", "02", "02", "02", "12"],
            ["01", "01", "01", "05", "00", "00", "00", "20", "20", "20", "20", "00", "00", "00", "08"],
            ["10", "02", "02", "07", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00"],
            ["05", "00", "00", "00", "19", "09", "04", "04", "04", "04", "04", "04", "04", "04", "04"],
            ["05", "00", "00", "00", "19", "03", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["11", "04", "04", "04", "04", "13", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "10", "02", "02", "02", "02", "02", "12"],
            ["01", "05", "00", "00", "90", "91", "92", "03"],
            ["02", "07", "00", "00", "93", "94", "95", "03"],
            ["00", "00", "00", "00", "96", "97", "98", "03"],
            ["04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01"]
          ],
        ]
      },
      {
        initialPosition: {
          row: 5,
          column: 1
        },
        goalColumn: 19,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "10", "02", "02", "02", "02", "02", "12", "10", "02", "02", "12"],
            ["01", "01", "01", "10", "07", "00", "00", "20", "20", "00", "08", "07", "00", "00", "03"],
            ["10", "02", "02", "07", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "08"],
            ["05", "00", "00", "00", "00", "00", "09", "04", "04", "04", "04", "04", "06", "18", "00"],
            ["05", "00", "00", "00", "00", "09", "13", "01", "01", "01", "01", "01", "05", "18", "00"],
            ["11", "04", "04", "04", "04", "13", "01", "01", "01", "01", "01", "13", "11", "04", "04"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "12", "01", "01", "01", "01", "01", "01"],
            ["01", "10", "02", "02", "02", "02", "02", "12"],
            ["02", "07", "00", "00", "90", "91", "92", "03"],
            ["00", "00", "00", "00", "93", "94", "95", "03"],
            ["00", "00", "00", "00", "96", "97", "98", "03"],
            ["04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01"]
          ],
        ]
      }
    ],
    [
      {
        initialPosition: {
          row: 2,
          column: 1
        },
        goalColumn: 26,
        scenaryParts: [
          [
            ["10", "02", "02", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["05", "00", "00", "00", "00", "03", "01", "01", "01", "01", "01", "01", "01", "10", "02"],
            ["05", "00", "00", "00", "00", "03", "01", "01", "01", "01", "01", "01", "10", "07", "00"],
            ["11", "04", "04", "06", "18", "08", "02", "02", "12", "01", "01", "01", "05", "00", "00"],
            ["01", "01", "01", "05", "18", "00", "20", "20", "08", "02", "02", "02", "07", "00", "00"],
            ["01", "01", "01", "05", "18", "00", "00", "00", "00", "00", "00", "00", "00", "00", "09"],
            ["01", "01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["02", "02", "02", "02", "02", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01"],
            ["00", "00", "20", "20", "20", "00", "00", "03", "10", "02", "02", "02", "02", "02", "12"],
            ["00", "00", "00", "00", "00", "00", "00", "03", "05", "00", "00", "90", "91", "92", "03"],
            ["09", "04", "04", "04", "04", "06", "18", "08", "07", "00", "00", "93", "94", "95", "03"],
            ["13", "01", "01", "01", "01", "05", "18", "00", "00", "00", "00", "96", "97", "98", "03"],
            ["01", "01", "01", "01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ]
        ]
      },
      {
        initialPosition: {
          row: 3,
          column: 1
        },
        goalColumn: 26,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["10", "02", "02", "02", "12", "01", "01", "01", "01", "01", "10", "02", "02", "02", "02"],
            ["05", "00", "00", "00", "03", "01", "01", "01", "01", "10", "07", "00", "00", "20", "20"],
            ["05", "00", "00", "00", "08", "02", "12", "01", "10", "07", "00", "00", "00", "00", "00"],
            ["11", "04", "06", "18", "00", "00", "08", "02", "07", "00", "00", "00", "09", "04", "04"],
            ["01", "01", "05", "18", "00", "00", "00", "00", "00", "00", "00", "09", "13", "01", "01"],
            ["01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "04", "13", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "10", "02", "02", "12", "10", "02", "02", "12", "01", "01", "01", "01", "01", "01"],
            ["02", "07", "00", "00", "08", "07", "00", "00", "03", "01", "01", "01", "01", "01", "01"],
            ["20", "20", "00", "00", "00", "00", "00", "00", "16", "02", "02", "02", "02", "02", "12"],
            ["00", "00", "00", "09", "04", "04", "06", "18", "14", "00", "00", "90", "91", "92", "03"],
            ["04", "04", "04", "13", "01", "01", "05", "18", "00", "00", "00", "93", "94", "95", "03"],
            ["13", "01", "01", "01", "01", "01", "05", "18", "00", "00", "00", "96", "97", "98", "03"],
            ["01", "01", "01", "01", "01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ]
        ]
      },
      {
        initialPosition: {
          row: 5,
          column: 1
        },
        goalColumn: 26,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "10", "02", "02", "02", "02", "02", "02", "12", "01", "10", "02"],
            ["01", "01", "01", "01", "05", "00", "00", "00", "20", "20", "00", "08", "02", "07", "00"],
            ["01", "01", "01", "01", "05", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00"],
            ["10", "02", "02", "02", "07", "19", "09", "04", "04", "04", "04", "04", "04", "04", "04"],
            ["05", "00", "00", "00", "00", "19", "03", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["05", "00", "00", "00", "00", "19", "03", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["11", "04", "04", "04", "04", "04", "13", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["02", "02", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["20", "20", "20", "00", "03", "01", "01", "01", "10", "02", "02", "02", "02", "02", "12"],
            ["00", "00", "00", "00", "03", "01", "01", "10", "07", "00", "00", "90", "91", "92", "03"],
            ["04", "04", "06", "18", "08", "02", "02", "07", "00", "00", "00", "93", "94", "95", "03"],
            ["01", "01", "05", "18", "00", "00", "00", "00", "00", "00", "00", "96", "97", "98", "03"],
            ["01", "01", "05", "18", "00", "00", "00", "00", "00", "09", "04", "04", "04", "04", "13"],
            ["01", "01", "11", "04", "04", "04", "04", "04", "04", "13", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ]
        ]
      }
    ],
    [
      {
        initialPosition: {
          row: 4,
          column: 1
        },
        goalColumn: 41,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "10", "02"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "05", "00"],
            ["10", "02", "02", "02", "02", "12", "01", "10", "02", "02", "02", "02", "02", "07", "00"],
            ["05", "00", "00", "00", "00", "08", "02", "07", "00", "00", "00", "00", "00", "00", "19"],
            ["05", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "19"],
            ["11", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["02", "02", "12", "01", "10", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01"],
            ["00", "00", "08", "02", "07", "00", "00", "03", "01", "01", "10", "02", "02", "02", "02"],
            ["00", "00", "00", "00", "00", "00", "00", "03", "01", "10", "07", "00", "00", "20", "20"],
            ["09", "04", "04", "04", "04", "06", "18", "03", "10", "07", "00", "00", "00", "00", "00"],
            ["03", "01", "01", "01", "01", "05", "18", "08", "07", "00", "00", "00", "09", "04", "04"],
            ["13", "01", "01", "01", "01", "05", "18", "00", "00", "00", "00", "09", "13", "01", "01"],
            ["01", "01", "01", "01", "01", "11", "04", "04", "04", "04", "04", "13", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["02", "02", "12", "10", "02", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01"],
            ["20", "00", "08", "07", "00", "00", "00", "03", "10", "02", "02", "02", "02", "02", "12"],
            ["00", "00", "00", "00", "00", "00", "00", "08", "07", "00", "00", "90", "91", "92", "03"],
            ["04", "04", "04", "04", "04", "06", "18", "00", "00", "00", "00", "93", "94", "95", "03"],
            ["01", "01", "01", "01", "01", "05", "18", "00", "00", "00", "00", "96", "97", "98", "03"],
            ["01", "01", "01", "01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ]
        ]
      },
      {
        initialPosition: {
          row: 2,
          column: 1
        },
        goalColumn: 41,
        scenaryParts: [
          [
            ["10", "02", "02", "02", "02", "02", "02", "12", "01", "01", "01", "10", "02", "02", "12"],
            ["05", "00", "00", "00", "00", "20", "20", "08", "02", "02", "02", "07", "20", "00", "03"],
            ["05", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "03"],
            ["11", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "06", "18", "08"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "05", "18", "00"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "05", "18", "00"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "11", "04", "04"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "10", "01", "01", "01", "01", "10", "02", "02", "02", "02", "12"],
            ["01", "01", "01", "01", "01", "01", "01", "10", "02", "07", "00", "00", "00", "00", "03"],
            ["01", "01", "01", "01", "01", "01", "01", "05", "00", "00", "00", "00", "00", "00", "03"],
            ["02", "12", "01", "01", "10", "02", "02", "07", "00", "00", "00", "09", "06", "18", "08"],
            ["00", "08", "02", "02", "07", "20", "20", "00", "19", "09", "04", "13", "05", "18", "00"],
            ["00", "00", "00", "00", "00", "00", "00", "00", "19", "03", "01", "01", "05", "18", "00"],
            ["04", "04", "04", "04", "04", "04", "04", "04", "04", "13", "01", "01", "11", "04", "04"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "10", "02", "02", "02", "02", "02", "12"],
            ["01", "01", "01", "01", "10", "02", "02", "02", "07", "00", "00", "90", "91", "92", "03"],
            ["01", "01", "01", "10", "07", "00", "00", "20", "20", "00", "00", "93", "94", "95", "03"],
            ["02", "02", "02", "07", "00", "00", "00", "00", "00", "00", "00", "96", "97", "98", "03"],
            ["00", "00", "00", "00", "00", "00", "09", "04", "04", "04", "04", "04", "04", "04", "13"],
            ["00", "00", "00", "00", "00", "09", "13", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["04", "04", "04", "04", "04", "13", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ]
        ]
      },
      {
        initialPosition: {
          row: 3,
          column: 1
        },
        goalColumn: 41,
        scenaryParts: [
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["10", "02", "02", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["05", "00", "00", "00", "00", "03", "01", "01", "01", "01", "01", "10", "02", "02", "02"],
            ["05", "00", "00", "00", "00", "08", "12", "01", "01", "01", "10", "07", "00", "00", "20"],
            ["11", "04", "04", "06", "18", "00", "08", "02", "02", "02", "07", "00", "00", "00", "00"],
            ["01", "01", "01", "05", "18", "00", "00", "00", "00", "00", "00", "00", "00", "09", "04"],
            ["01", "01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "04", "04", "13", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "10", "02", "02"],
            ["01", "01", "01", "01", "01", "10", "02", "02", "02", "12", "01", "10", "07", "00", "00"],
            ["02", "02", "02", "02", "02", "07", "00", "00", "00", "08", "02", "07", "00", "00", "00"],
            ["20", "20", "20", "20", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "09"],
            ["00", "00", "00", "00", "00", "00", "00", "09", "04", "04", "04", "04", "04", "04", "13"],
            ["04", "04", "04", "04", "04", "04", "04", "13", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ],
          [
            ["02", "02", "02", "02", "12", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["00", "00", "00", "00", "03", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"],
            ["00", "00", "00", "00", "03", "01", "01", "01", "10", "02", "02", "02", "02", "02", "12"],
            ["04", "04", "06", "18", "08", "02", "02", "02", "07", "00", "00", "90", "91", "92", "03"],
            ["01", "01", "05", "18", "00", "20", "20", "20", "00", "00", "00", "93", "94", "95", "03"],
            ["01", "01", "05", "18", "00", "00", "00", "00", "00", "00", "00", "96", "97", "98", "03"],
            ["01", "01", "11", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "04", "13"],
            ["01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01", "01"]
          ]
        ]
      }
    ]
  ];

  public playerCanBeStand(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    return !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column]) &&
    this.isFree(scenaryMatrix[playerPosition.row - 1][playerPosition.column]);
  }

  public playerCanWalk(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    const canWalkInCurrentPosition: boolean = !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column]) &&
      this.isFree(scenaryMatrix[playerPosition.row - 1][playerPosition.column]);
    const canWalkInNextPosition: boolean = !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column + 1]) &&
      this.isFree(scenaryMatrix[playerPosition.row - 1][playerPosition.column + 1]);

    return canWalkInCurrentPosition && canWalkInNextPosition;
  }

  public playerCanGoDownStairs(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    return scenaryMatrix[playerPosition.row + 1][playerPosition.column] === ScenaryCellType.StairsDown;
  }

  public playerCanGoUpStairs(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    return scenaryMatrix[playerPosition.row - 1][playerPosition.column] === ScenaryCellType.StairsUp ||
      scenaryMatrix[playerPosition.row][playerPosition.column] === ScenaryCellType.StairsUp;
  }

  public playerCanCrouch(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    const canCrouchInCurrentPosition: boolean = !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column]) &&
      scenaryMatrix[playerPosition.row - 1][playerPosition.column] === ScenaryCellType.Spikes;
    const canCrouchInNextPosition: boolean = !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column + 1]) &&
      scenaryMatrix[playerPosition.row - 1][playerPosition.column + 1] === ScenaryCellType.Spikes;

    return (canCrouchInCurrentPosition || canCrouchInNextPosition) && !this.playerCanCreep(playerPosition, scenaryMatrix);
  }

  public playerCanCreep(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    const canCreepInCurrentPosition: boolean = !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column]) &&
      this.isWall(scenaryMatrix[playerPosition.row - 1][playerPosition.column]);
    const canCreepInNextPosition: boolean = !this.isWall(scenaryMatrix[playerPosition.row][playerPosition.column + 1]) &&
      this.isWall(scenaryMatrix[playerPosition.row - 1][playerPosition.column + 1]);

    return canCreepInCurrentPosition || canCreepInNextPosition;
  }

  public playerCanJump(playerPosition: CellPosition, scenaryMatrix: string[][]): boolean {
    const playerCanWalk: boolean = this.playerCanWalk(playerPosition, scenaryMatrix);
    return !playerCanWalk && scenaryMatrix[playerPosition.row - 1][playerPosition.column + 1] === ScenaryCellType.Free;
  }

  public getPlayerInitialPosition(level: number, round: number): CellPosition {
    return this.scenaries[level - 1][round].initialPosition;
  }

  public getScenaryGoalColumn(level: number, round: number): number {
    return this.scenaries[level][round].goalColumn;
  }

  public getScenaryMatrix(level: number, round: number): string[][] {
    return this.scenaries[level][round].scenaryParts.reduce((t, scenaryPart) => {
      const partRows: string[][] = scenaryPart;

      for (let i = 0; i < partRows.length; i++) {
        const row: string[] = partRows[i];

        t[i] = t[i] ?
          t[i].concat(...row) :
          [...row];
      }

      return t;
    }, []);
  }

  private isFree(cellType: string): boolean {
    return cellType === ScenaryCellType.Free ||
      cellType === ScenaryCellType.StairsDown ||
      cellType === ScenaryCellType.StairsUp ||
      cellType === ScenaryCellType.Goal1 ||
      cellType === ScenaryCellType.Goal2 ||
      cellType === ScenaryCellType.Goal3 ||
      cellType === ScenaryCellType.Goal4 ||
      cellType === ScenaryCellType.Goal5 ||
      cellType === ScenaryCellType.Goal6 ||
      cellType === ScenaryCellType.Goal7 ||
      cellType === ScenaryCellType.Goal8 ||
      cellType === ScenaryCellType.Goal9;
  }

  private isWall(cellType: string): boolean {
    return cellType === ScenaryCellType.Wall ||
      cellType === ScenaryCellType.WallTop ||
      cellType === ScenaryCellType.WallRight ||
      cellType === ScenaryCellType.WallBottom ||
      cellType === ScenaryCellType.WallLeft ||
      cellType === ScenaryCellType.WallOuterCornerLeftTop ||
      cellType === ScenaryCellType.WallOuterCornerLeftBottom ||
      cellType === ScenaryCellType.WallOuterCornerRightTop ||
      cellType === ScenaryCellType.WallOuterCornerRightBottom ||
      cellType === ScenaryCellType.WallInnerCornerLeftTop ||
      cellType === ScenaryCellType.WallInnerCornerLeftBottom ||
      cellType === ScenaryCellType.WallInnerCornerRightTop ||
      cellType === ScenaryCellType.WallInnerCornerRightBottom;
  }
}